import {EnumAgencyType} from '../../enums/enum-agency-type.model';
import {ResponseDataWithModifiedBy} from '../../response-data-with-modified-by.model';


export class ResponseAgency extends ResponseDataWithModifiedBy {
	public Id: string;
	public AgencyType: EnumAgencyType;
	public Name: string;
	public BusinessNo: string;
	public TelNo: string;
	public PostCode: string;
	public RoadAddress: string;
	public ExtraAddress: string;
	public DetailAddress: string;
	public DisplayNo: string;
	public AdminId: string;
	public AdminName: string;
	public OldId: string;
	public UiBackgroundColor: string = '';
	public UiLogoFileName: string = '';
	
}


