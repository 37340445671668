import {RequestUserCommonData} from './request-user-common-data.model';
import {EnumDataInputMethod} from '../../enums/enum-data-input-method.model';

/**
 * 사용자 혈압 데이터 요청 클래스
 */
export class RequestBodyScaleData extends RequestUserCommonData {
	/**
	 * 체질량지수
	 */
	public Bmi: number;
	/**
	 * 기초대사량
	 */
	public Bmr: number;
	/**
	 * 골량
	 */
	public Bone: number;
	/**
	 * 체지방률
	 */
	public Fat: number;
	/**
	 * 근육량
	 */
	public Muscle: number;
	/**
	 * 내장지방
	 */
	public Visceral: number;
	/**
	 * 체수분
	 */
	public Water: number;
	/**
	 * 몸무게
	 */
	public Weight: number;
	/**
	 * 데이터 입력 방법
	 */
	public InputMethod: EnumDataInputMethod = EnumDataInputMethod.Device;
}


