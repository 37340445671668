import {Injectable} from '@angular/core';
import {config_firebase} from '../../../../environments/config_firebase';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import {RequestUserRegist} from '../../../_model/request/account/request-user-regist.model';
import {EnumUserStatus} from '../../../_model/enums/enum-user-status.model';
import {AgencyProvider} from './agency-provider.service';
import {EnumResponseResult} from '../../../_model/enums/enum-response-result.model';
import {RequestUpdateProfile} from '../../../_model/request/account/request-update-profile.model';
import {EnumUserGender} from '../../../_model/enums/enum-user-gender.model';
import {ResponseAgency} from '../../../_model/response/agency/response-agency.model';
import {UserProvider} from './user-provider.service';
import {RequestUserHealthGoal} from '../../../_model/request/config/request-user-health-goal.model';
import {UserHealthGoalsProvider} from './user-health-goals-provider.service';
import {ResponseUserWithRoles} from '../../../_model/response/account/response-user-with-roles.model';
import {UserDeviceDataProvider} from './user-device-data-provider.service';
import {RequestBloodPressureData} from '../../../_model/request/data/request-blood-pressure-data.model';
import {RequestBodyScaleData} from '../../../_model/request/data/request-body-scale-data.model';
import {RequestFoodLensData} from '../../../_model/request/data/request-food-lens-data.model';
import {EnumMealType} from '../../../_model/enums/enum-meal-type.model';
import {RequestFoodLensDetailData} from '../../../_model/request/data/request-food-lens-detail-data.model';
import {RequestGlucosemeterData} from '../../../_model/request/data/request-glucosemeter-data.model';
import {EnumTimeOfDay} from '../../../_model/enums/enum-time-of-day.model';
import {EnumBeforeAndAfter} from '../../../_model/enums/enum-before-and-after.model';
import {RequestPedometerActivityData} from '../../../_model/request/data/request-pedometer-activity-data.model';
import {RequestPedometerSleepData} from '../../../_model/request/data/request-pedometer-sleep-data.model';
import {RequestPedometerHeartRateData} from '../../../_model/request/data/request-pedometer-heartrate-data.model';
import {EnumDiabeteDiseasePeriod} from '../../../_model/enums/enum-diabete-disease-period.model';
import {EnumDiabeteType} from '../../../_model/enums/enum-diabete-type.model';
import {EnumDiabeteRemedy} from '../../../_model/enums/enum-diabete-remedy.model';
import {EnumSmokeType} from '../../../_model/enums/enum-smoke-type.model';
import {UserHealthProvider} from './user-health-provider.service';
import {RequestPatientHealth} from '../../../_model/request/patient/request-patient-health.model';
import {PatientProvider} from './patient-provider.service';
import {RequestPatientInspect} from '../../../_model/request/patient/request-patient-inspect.model';
import {RequestFcmToken} from '../../../_model/request/common/request-fcm-token.model';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {RequestAgencyPushMessage} from '../../../_model/request/agency/request-agency-push-message.model';
import {ResponseAgencyPushMessage} from '../../../_model/response/agency/response-agency-push-message.model';
import {ResponseData} from '../../../_model/response-data.model';
import {ResponseAgencyConsulting} from '../../../_model/response/agency/response-agency-consulting.model';
import {RequestAgencyConsulting} from '../../../_model/request/agency/request-agency-consulting.model';
import {EnumConsultingBoundType} from '../../../_model/enums/enum-consulting-bound-type.model';
import {EnumConsultingEventType} from '../../../_model/enums/enum-consulting-event-type.model';
import {ResponseMigrationProgress} from '../../../_model/response/ResponseMigrationProgress';
import {ResponseUserHealth} from '../../../_model/response/data/response-user-health.model';
import {ResponseUserHealthGoal} from '../../../_model/response/config/response-user-health-goal.model';
import * as moment from 'moment';
import {EnumPatientCategory} from '../../../_model/enums/enum-patient-category.model';
import {RequestPatientCategoryAndNo} from '../../../_model/request/patient/request-patient-category-and-no.model';
import {List} from 'linq-collections';

/**
 * 데이터 마이그레이션 프로바이더
 */
@Injectable()
export class MigrationService {

	// Firestore 객체
	private db: firebase.firestore.Firestore = null;

	// Firestorage 객체
	private storage: firebase.storage.Storage = null;

	// 페이지당 레코드 수
	private countPerPage: number = 1000;

	// 마이그레이션 진행 알람 소스
	private _progressSource: BehaviorSubject<ResponseMigrationProgress> = null;
	// 마이그레이션 진행 알람 소스 변경 감시
	progressSource$: Observable<ResponseMigrationProgress> = null;

	/**
	 * 생성자
	 */
	constructor(
		private agencyProvider: AgencyProvider,
		private userProvider: UserProvider,
		private userHealthGoalsProvider: UserHealthGoalsProvider,
		private userDeviceDataProvider: UserDeviceDataProvider,
		private userHealthProvider: UserHealthProvider,
		private patientProvider: PatientProvider
	) {
		firebase.initializeApp(config_firebase);

		this.db = firebase.firestore();

		this.storage = firebase.storage();

		this._progressSource = new BehaviorSubject<ResponseMigrationProgress>(null);
		this.progressSource$ = this._progressSource.asObservable();
	}

	/**
	 * 환자 정보를 수정한다.
	 */
	public async migratePatients(): Promise<any> {
		return new Promise<boolean>(async (resolve) => {
			this._progressSource.next(new ResponseMigrationProgress('[Patient migration] Begin'));

			// 모든 기관 목록을 가져온다.
			this.agencyProvider.getList()
				.subscribe(async (response) => {
					// 가져오는데 성공한 경우
					if(response.Result === EnumResponseResult.Success) {

						this._progressSource.next(new ResponseMigrationProgress('[Patient migration] success to get agencies'));

						// 기관 목록 저장
						const agencies: ResponseAgency[] = response.Data.Items;

						this._progressSource.next(new ResponseMigrationProgress('[Patient migration] begin to get patients'));

						let totalCount: number = 0;
						let processedCount: number = 0;

						// 모든 기관에 대해서 처리
						for (let index = 0; index < agencies.length; index++) {

							const agency: ResponseAgency = agencies[index];

							// 해당 기관 내의 사용자 목록을 가져온다.
							const patients = await this.db.collection('group')
								.doc(agency.OldId)
								.collection('uuid')
								.get();

							totalCount = totalCount + patients.docs.length;
						}

						// 모든 기관에 대해서 처리
						for (let index = 0; index < agencies.length; index++) {

							const agency: ResponseAgency = agencies[index];

							// 해당 기관 내의 사용자 목록을 가져온다.
							const patients = await this.db.collection('group')
								.doc(agency.OldId)
								.collection('uuid')
								.get();

							// 모든 환자에 대해서 처리
							for (let patientIndex = 0; patientIndex < patients.docs.length; patientIndex++) {
								const patient = patients.docs[patientIndex].data();

								let category: EnumPatientCategory;
								let no: string = '';

								switch(patient.patientCategory) {
									case 'A군':
										category = EnumPatientCategory.A;
										break;
									case 'B군':
										category = EnumPatientCategory.B;
										break;
									case 'C군':
										category = EnumPatientCategory.C;
										break;
								}

								if(patient.patientNo)
									no = patient.patientNo;

								processedCount++;

								const updateResponse: ResponseData = await this.patientProvider.updateCategoryAndNo(agency.Id, patient.uid, new RequestPatientCategoryAndNo(category, no)).toPromise();
								if(updateResponse.Result === EnumResponseResult.Success)
									this._progressSource.next(new ResponseMigrationProgress('[Patient migration] update success for patient : ' + patient.uid + ', ' + category + ', ' + no, processedCount, totalCount));
								else
									this._progressSource.next(new ResponseMigrationProgress('[Patient migration] update failure for patient : ' + patient.uid + ', ' + category + ', ' + no, processedCount, totalCount));
							}
						}

						resolve(true);
					}
					else {
						this._progressSource.next(new ResponseMigrationProgress('[Patient migration] error to get agencies'));

						resolve(false);
					}
				});
		});
	}

	/**
	 * 사용자 데이터 마이그레이션
	 */
	public async migrateUser(): Promise<boolean> {

		return new Promise<boolean>(async (resolve) => {

			this._progressSource.next(new ResponseMigrationProgress('[User migration] Begin'));

			// 모든 기관 목록을 가져온다.
			this.agencyProvider.getList()
				.subscribe((response) => {

					// 가져오는데 성공한 경우
					if(response.Result === EnumResponseResult.Success) {

						this._progressSource.next(new ResponseMigrationProgress('[User migration] success to get agencies'));

						// 기관 목록 저장
						const agencies: ResponseAgency[] = response.Data.Items;

						this._progressSource.next(new ResponseMigrationProgress('[User migration] begin to get users'));

						// 사용자 목록을 가져온다.
						this.db.collection('user_info')
							.get()
							.then(async (result: any) => {

								this._progressSource.next(new ResponseMigrationProgress('[User migration] success to get users : count = ' + result.size));

								const docs = result.docs;
								// const docs = result.docs.splice(0, 1);

								// 모든 사용자에 대해서 처리
								for(let index = 0; index < docs.length; index++) {

									const doc = docs[index];
									const row: any = doc.data();

									let phone: string = null;

									// 핸드폰 번호를 추출
									if(row.phoneNumbers && row.phoneNumbers.length > 0) {
										row.phoneNumbers.map((phoneNumber: string) => {
											if(phoneNumber && phoneNumber.length > 0) {
												phone = phoneNumber;
											}
										});
									}

									this._progressSource.next(new ResponseMigrationProgress('[User migration] process user for ' + doc.id, index + 1, result.size));

									// 사용자 등록 요청 객체
									let request: RequestUserRegist = null;

									// 핸드폰번호 및 이메일주소가 존재하지 않는 경우
									if(!phone && !row.email) {

										if(doc.id && doc.id.startsWith('kakao') || doc.id.startsWith('naver')) {

											const socialItems = doc.id.split(':');
											if(socialItems && socialItems.length === 2) {
												request = new RequestUserRegist();
												request.PhoneNumber = phone;
												request.Email = socialItems[1] + '@' + socialItems[0] + '.com';
												request.Name = row.name;
												request.Roles = ['User'];
												request.Status = EnumUserStatus.Activated;
												request.OldId = doc.id;
											}
										}
									}
									// 핸드폰번호 혹은 이메일주소가 존재하는 경우
									else {
										request = new RequestUserRegist();
										request.PhoneNumber = phone;
										if(row.email)
											request.Email = row.email;
										else
											request.Email = null;
										request.Name = row.name;
										request.Roles = ['User'];
										request.Status = EnumUserStatus.Activated;
										request.OldId = doc.id;
									}

									// 사용자 등록 요청 객체가 유효한 경우
									if(request) {

										this._progressSource.next(new ResponseMigrationProgress('[User migration] add user to server'));

										// 사용자 등록
										const response: ResponseData<ResponseUserWithRoles> = await this.userProvider.add(request).toPromise();

										if(response.Result === EnumResponseResult.Success) {

											// 사용자가 속한 기관 목록
											const agencyIdsOfUser: string[] = [];

											// 모든 기관에 대해서 처리
											for (const agency of agencies) {
												// 해당 기관 내의 사용자 아이디를 조회
												const user = await this.db.collection('user_group')		// 'group'
													.doc(agency.OldId)
													.collection('uuid')
													.doc(request.OldId)
													.get();

												// 사용자가 존재하는 경우
												if (user.exists)
													agencyIdsOfUser.push(agency.Id);
											}

											if(row.height && row.weight) {
												// 프로필 요청 객체
												const profile: RequestUpdateProfile = new RequestUpdateProfile();
												profile.Gender = row.gender ? (row.gender === 'male' ? EnumUserGender.Male : EnumUserGender.Female) : null;
												if(row.birth)
													profile.BirthDate = row.birth.toDate();
												else
													profile.BirthDate = null;
												if(row.height)
													profile.Height = row.height;
												else
													profile.Height = null;
												if(row.weight)
													profile.Weight = row.weight;
												else
													profile.Weight = null;
												if(row.photoURL && !row.photoURL.startsWith('firestore://'))
													profile.PhotoUrl = row.photoURL;
												else
													profile.PhotoUrl = null;
												profile.AgencyIds = agencyIdsOfUser;

												// 프로필 수정
												this.userProvider.updateProfile(response.Data.Id, profile)
													.subscribe((response) => {
														if(response.Result !== EnumResponseResult.Success) {
															console.error(profile);
															this._progressSource.next(new ResponseMigrationProgress('[User migration] error to update profile : ' + response.Message));
														}
													});
											}
										}
										else {
											console.error(request);
											this._progressSource.next(new ResponseMigrationProgress('[User migration] error to add user : ' + response.Message));
										}

										// setTimeout(() => {
										// }, 1000);
									}
								}

								resolve(true);
							})
							.catch((err: any) => {
								this._progressSource.next(new ResponseMigrationProgress('[User migration] error to get user informations : ' + err));

								resolve(false);
							});
					}
					else {
						this._progressSource.next(new ResponseMigrationProgress('[User migration] error to get agencies'));

						resolve(false);
					}
				});
		});
	}

	/**
	 * 모든 사용자 목표 데이터 마이그레이션
	 */
	public migrateUserGoals(): Promise<boolean> {
		return new Promise<boolean>(async (resolve) => {
			this.userProvider.getList({ countPerPage: 99999999 })
				.subscribe(async (response) => {
					// 가져오는데 성공한 경우
					if(response.Result === EnumResponseResult.Success) {

						const users = response.Data.Items;
						// const users = response.Data.Items.splice(0, 10);

						// 모든 사용자에 대해서 처리
						for(let index  = 0; index < users.length; index++) {

							const user = users[index];

							await this.migrateUserGoal(user.Id, user.OldId);
							this._progressSource.next(new ResponseMigrationProgress('[User goal migration] process user for ' + user.Id, (index + 1), users.length));
						}
						resolve(true);
					}
					else {
						this._progressSource.next(new ResponseMigrationProgress('[User goal migration] error to get users : ' + response.Message));
						resolve(false);
					}
				});

		});
	}

	/**
	 * 사용자 목표 데이터 마이그레이션
	 */
	public async migrateUserGoal(id: string, uid: string): Promise<boolean> {

		return new Promise<boolean>(async (resolve) => {
			if(!id) {
				this._progressSource.next(new ResponseMigrationProgress('[User goal migration] invalid id : ' + uid + ', ' + id));
				resolve(false);
				return;
			}

			if(!uid) {
				this._progressSource.next(new ResponseMigrationProgress('[User goal migration] invalid uid : ' + uid + ', ' + id));
				resolve(false);
				return;
			}

			const userHealthGoal = await this.db.collection('user_health')
				.doc(uid)
				.collection('goal');

			const userHealthGoalActivity = await userHealthGoal
				.doc('activity_goal')
				.get();
			const userHealthGoalBloodPressure = await userHealthGoal
				.doc('bloodpressure_goal')
				.get();
			const userHealthGoalBodyScale = await userHealthGoal
				.doc('bodyscale_goal')
				.get();
			const userHealthGoalDiet = await userHealthGoal
				.doc('diet_goal')
				.get();
			const userHealthGoalGlucose = await userHealthGoal
				.doc('glucose_goal')
				.get();

			if(userHealthGoalActivity.exists || userHealthGoalBloodPressure.exists || userHealthGoalBodyScale.exists || userHealthGoalDiet.exists || userHealthGoalGlucose.exists) {
				const request: RequestUserHealthGoal = new RequestUserHealthGoal();

				if(userHealthGoalActivity.exists) {
					const data = userHealthGoalActivity.data();
					request.ActivityStep = this.parseNumber(data.step);
				}
				if(userHealthGoalBloodPressure.exists) {
					const data = userHealthGoalBloodPressure.data();
					request.BloodPressureMin = this.parseNumber(data.pressureSmall);
					request.BloodPressureMax = this.parseNumber(data.pressureBig);
				}
				if(userHealthGoalBodyScale.exists) {
					const data = userHealthGoalBodyScale.data();
					request.BodyScaleBmiMin = this.parseNumber(data.bmiMin);
					request.BodyScaleBmiMax = this.parseNumber(data.bmiMax);
					request.BodyScaleWeightMin = this.parseNumber(data.weightMin);
					request.BodyScaleWeightMax = this.parseNumber(data.weightMax);
				}
				if(userHealthGoalDiet.exists) {
					const data = userHealthGoalDiet.data();
					request.ActivityCalorieMin = this.parseNumber(data.calConsumptionMin);
					request.ActivityCalorieMax = this.parseNumber(data.calConsumptionMax);
				}
				if(userHealthGoalGlucose.exists) {
					const data = userHealthGoalGlucose.data();
					request.GlucoseBeforeMealMin = this.parseNumber(data.bMealMin);
					request.GlucoseBeforeMealMax = this.parseNumber(data.bMeal);
					request.GlucoseAfterMealMin = this.parseNumber(data.aMealMin);
					request.GlucoseAfterMealMax = this.parseNumber(data.aMeal);
					request.GlucoseBeforeSleepMin = this.parseNumber(data.sleepMin);
					request.GlucoseBeforeSleepMax = this.parseNumber(data.sleep);
				}

				const response: ResponseData<ResponseUserHealthGoal> = await this.userHealthGoalsProvider.update(id, request).toPromise();
				if(response.Result === EnumResponseResult.Success) {
					// console.log('[User goal migration] success to update user goal : ', id, request);
					resolve(true);
				}
				else {
					console.error(request);
					this._progressSource.next(new ResponseMigrationProgress('[User goal migration] error to update user goal : ' + uid + ', ' + id + ', ' + response.Message));
					resolve(false);
				}
			}
			else {
				// console.error('[User goal migration] there is no data for ', uid);
				resolve(false);
			}
		});
	}

	/**
	 * 사용자 FCM 토큰 데이터 마이그레이션
	 */
	public async migrateUserFcmToken(id: string, uid: string): Promise<boolean> {

		return new Promise<boolean>(async (resolve) => {

			if(!id) {
				this._progressSource.next(new ResponseMigrationProgress('[User fcmtoken migration] invalid id : ' + uid + ', ' + id));
				resolve(false);
				return;
			}

			if(!uid) {
				this._progressSource.next(new ResponseMigrationProgress('[User fcmtoken migration] invalid uid : ' + uid + ', ' + id));
				resolve(false);
				return;
			}

			const userFcmToken = await this.db.collection('fcm_token')
				.doc(uid)
				.get();

			if(userFcmToken.exists) {
				const data = userFcmToken.data();

				const request: RequestFcmToken = new RequestFcmToken();
				request.Token = data.token;

				this.userProvider.removeAllTokens(id)
					.subscribe((response) => {

						if(response.Result === EnumResponseResult.Success) {
							this.userProvider.addToken(id, request)
								.subscribe((response) => {

									if(response.Result === EnumResponseResult.Success) {
										// console.log('[User fcmtoken migration] success to add fcmtokens : ', uid, id, request);
										resolve(true);
									}
									else {
										this._progressSource.next(new ResponseMigrationProgress('[User fcmtoken migration] error to add fcmtokens : ' + uid + ', ' + id + ', ' + response.Message));
										resolve(false);
									}
								});
						}
						else {
							this._progressSource.next(new ResponseMigrationProgress('[User fcmtoken migration] error to remove fcmtokens : ' + uid + ', ' + id + ', ' + response.Message));
							resolve(false);
						}
					});
			}
			else {
				// console.error('[User goal migration] there is no data for ', uid);
				resolve(false);
			}
		});
	}

	/**
	 * 모든 사용자 건강 데이터 마이그레이션
	 */
	public migrateUserHealthDatas(): Promise<boolean> {
		return new Promise<boolean>(async (resolve) => {

			this.userProvider.getList({ countPerPage: 99999999 })
				.subscribe(async (response) => {
					// 가져오는데 성공한 경우
					if(response.Result === EnumResponseResult.Success) {

						const users = response.Data.Items;

						// 모든 사용자에 대해서 처리
						for(let index = 0; index < users.length; index++) {
							await this.migrateUserHealthData(users[index].Id, users[index].OldId);
							this._progressSource.next(new ResponseMigrationProgress('[User health migration] process user for ' + users[index].Id + ', ' + users[index].OldId, (index + 1), users.length));
						}
						resolve(true);
					}
					else {
						this._progressSource.next(new ResponseMigrationProgress('[User goal migration] error to get users'));
						resolve(false);
					}
				});
		});
	}

	/**
	 * 사용자 건강 데이터 마이그레이션
	 */
	public async migrateUserHealthData(id: string, uid: string): Promise<boolean> {

		return new Promise<boolean>(async (resolve) => {

			if(!id) {
				this._progressSource.next(new ResponseMigrationProgress('[User goal migration] invalid id : ' + uid + ', ' + id));
				resolve(false);
				return;
			}

			if(!uid) {
				this._progressSource.next(new ResponseMigrationProgress('[User goal migration] invalid uid : ' + uid + ', ' + id));
				resolve(false);
				return;
			}

			const userHealth = await this.db.collection('user_health')
				.doc(uid)
				.collection('health')
				.doc('_base')
				.get();

			if(userHealth.exists) {
				const request: RequestPatientHealth = new RequestPatientHealth();

				const data = userHealth.data();
				switch(data.diabeteType) {
					case '1형':
						request.DiabeteType = EnumDiabeteType.Type1;
						break;
					case '2형':
						request.DiabeteType = EnumDiabeteType.Type2;
						break;
					case '임신성':
						request.DiabeteType = EnumDiabeteType.Gestational;
						break;
				}
				switch(data.diabeteDiseasePeriod) {
					case '1년미만':
						request.DiabeteDiseasePeriod = EnumDiabeteDiseasePeriod.Period1;
						break;
					case '1년':
						request.DiabeteDiseasePeriod = EnumDiabeteDiseasePeriod.Period2;
						break;
					case '2년~10년':
						request.DiabeteDiseasePeriod = EnumDiabeteDiseasePeriod.Period3;
						break;
					case '10년이상':
						request.DiabeteDiseasePeriod = EnumDiabeteDiseasePeriod.Period4;
						break;
				}
				switch(data.diabeteRemedy) {
					case '습관교정':
						request.DiabeteRemedy = EnumDiabeteRemedy.HabitCorrection;
						break;
					case '경구약':
						request.DiabeteRemedy = EnumDiabeteRemedy.OralMedicine;
						break;
					case '인슐린':
						request.DiabeteRemedy = EnumDiabeteRemedy.Insulin;
						break;
				}
				request.DiabeteFamilyHistory = data.diabeteFamilyHistory ? data.diabeteFamilyHistory : false;
				request.HasDrunk = data.drinkHasDrunk ? data.drinkHasDrunk : false;
				request.DrinkCycle = data.drinkCycle ? data.drinkCycle : 0;
				request.DrinkAmount = data.drinkAmount ? data.drinkAmount : 0;
				switch(data.SmokeType) {
					case 'never':
						request.SmokeType = EnumSmokeType.Never;
						break;
					case 'ex':
						request.SmokeType = EnumSmokeType.Stop;
						break;
					case 'current':
						request.SmokeType = EnumSmokeType.Current;
						break;
				}
				request.HasDiseaseHighBloodPressure = data.hasDiseaseHighPressure ? data.hasDiseaseHighPressure : false;
				request.HasDiseaseRenalFailure = data.hasDiseaseRenal ? data.hasDiseaseRenal : false;
				request.HasDiseaseHyperlipidemia = data.hasDiseaseHyperlipidemia ? data.hasDiseaseHyperlipidemia : false;
				request.HasDiseaseGout = data.hasDiseaseGout ? data.hasDiseaseGout : false;
				request.HasDiseaseEtc = data.hasDiseaseEtc ? data.hasDiseaseEtc : null;
				request.ComplicationEye = data.complicationEyes ? data.complicationEyes : false;
				request.ComplicationCardiovascular = data.complicationHeart ? data.complicationHeart : false;
				request.ComplicationNeural = data.complicationNeural ? data.complicationNeural : false;
				request.ComplicationKidney = data.complicationKidney ? data.complicationKidney : false;
				request.Memo = data.memo ? data.memo : null;

				const response: ResponseData<ResponseUserHealth> = await this.userHealthProvider.update(id, request).toPromise();
				if(response.Result === EnumResponseResult.Success) {
					// console.log('[User health migration] success to update user goal : ', id, request);

					const requestInspect: RequestPatientInspect = new RequestPatientInspect();
					requestInspect.Hemoglobin = data.inspectHemoglobin ? data.inspectHemoglobin : false;
					requestInspect.Ast = data.inspectAST ? data.inspectAST : false;
					requestInspect.Alt = data.inspectALT ? data.inspectALT : false;
					requestInspect.Ggt = data.inspectGGT ? data.inspectGGT : false;
					requestInspect.Bun = data.inspectBUN ? data.inspectBUN : false;
					requestInspect.Creainine = data.inspectCreainine ? data.inspectCreainine : false;
					requestInspect.Ldl = data.inspectLDL ? data.inspectLDL : false;
					requestInspect.EstimatedGfr = data.inspectEstimatedGFR ? data.inspectEstimatedGFR : false;
					requestInspect.UricAcid = data.inspectUricAcid ? data.inspectUricAcid : false;
					requestInspect.GlucoseFasting = data.inspectGlucoseFasting ? data.inspectGlucoseFasting : false;
					requestInspect.HbA1c = data.inspectHbA1c ? data.inspectHbA1c : false;
					requestInspect.Glycoalbumin = data.inspectGlycoalbumin ? data.inspectGlycoalbumin : false;
					requestInspect.ACUrine = data.inspectACrUrine ? data.inspectACrUrine : false;
					requestInspect.Cholesterol = data.inspectCholesterol ? data.inspectCholesterol : false;
					requestInspect.Triglyceridel = data.inspectTriglyceridel ? data.inspectTriglyceridel : false;
					requestInspect.Hdl = data.inspectHDL ? data.inspectHDL : false;

					const responseAddInspect: ResponseData = await this.patientProvider.addInspect(id, requestInspect).toPromise();
					if(responseAddInspect.Result === EnumResponseResult.Success) {
						// console.log('[User health migration] success to update user goal : ', id, request);
						resolve(true);
					}
					else {
						console.error(request);
						this._progressSource.next(new ResponseMigrationProgress('[User health migration] error to update user goal : ' + uid + ', ' + id + ', ' + responseAddInspect.Message));
						resolve(false);
					}
				}
				else {
					console.error(request);
					this._progressSource.next(new ResponseMigrationProgress('[User health migration] error to update user goal : ' + uid + ', ' + id + ', ' + response.Message));
					resolve(false);
				}
			}
			else {
				// console.error('[User goal migration] there is no data for ', uid);
				resolve(false);
			}
		});
	}

	/**
	 * 모든 사용자 발송 메세지 데이터 마이그레이션
	 */
	public migrateUserMessages(): Promise<boolean> {

		return new Promise<boolean>(async (resolve) => {

			forkJoin([
				// 모든 기관 목록을 가져온다.
				this.agencyProvider.getList(),
				// 사용자 목록을 가져온다.
				this.userProvider.getList({ countPerPage: 99999999 })
			])
				.subscribe(async (responses) => {

					// 가져오는데 성공한 경우
					if(responses[0].Result === EnumResponseResult.Success && responses[1].Result === EnumResponseResult.Success) {

						const agencies: ResponseAgency[] = responses[0].Data.Items;
						const users: List<ResponseUserWithRoles> = new List<ResponseUserWithRoles>(responses[1].Data.Items);

						let totalCount: number = 0;
						let processedCount: number = 0;

						// 모든 기관에 대해서 처리
						for(let agencyIndex = 0; agencyIndex < agencies.length; agencyIndex++) {

							const agency: ResponseAgency = agencies[agencyIndex];

							if (agency.OldId) {

								// 해당 기관 내의 사용자에게 전송한 메세지 목록을 가져온다.
								const result = await this.db.collection('group')
									.doc(agency.OldId)
									.collection('uuid')
									.get();

								totalCount = totalCount + result.docs.length;
							}
						}

						// 모든 기관에 대해서 처리
						for(let agencyIndex = 0; agencyIndex < agencies.length; agencyIndex++) {

							const agency: ResponseAgency = agencies[agencyIndex];

							if (agency.OldId) {

								// 해당 기관 내의 사용자에게 전송한 메세지 목록을 가져온다.
								const result = await this.db.collection('group')
									.doc(agency.OldId)
									.collection('uuid')
									.get();

								// 사용자 정보 목록
								const userDocs = result.docs;

								// 모든 사용자 아이디 목록에 대해서 처리
								for(let index = 0; index < userDocs.length; index++) {

									const userDoc: any = userDocs[index].data();

									processedCount++;

									// 사용자 정보와 아이디가 유효한 경우
									if(userDoc && userDoc.uid) {

										// 사용자 정보가 유효한 경우
										const user: ResponseUserWithRoles = users.where(i => i.OldId === userDoc.uid).firstOrDefault();
										if(user) {

											// 해당 기관 내의 사용자에게 전송한 메세지 목록을 가져온다.
											const messages = await this.db.collection('group')
												.doc(agency.OldId)
												.collection('uuid')
												.doc(userDoc.uid)
												.collection('messages')
												.get();

											// 모든 메시지에 대해서 처리
											for(let index = 0; index < messages.docs.length; index++) {

												const row: any = messages.docs[index].data();

												const request: RequestAgencyPushMessage = new RequestAgencyPushMessage();
												request.Title = row.title;
												request.PhotoUrl = row.photoRef;
												request.Message = row.message;
												if (row.date instanceof firebase.firestore.Timestamp)
													request.RegDate = row.date.toDate();
												else
													request.RegDate = row.date;

												// 메시지 저장
												const responseData: ResponseData<ResponseAgencyPushMessage> = await this.agencyProvider.addPushMessage(agency.Id, user.Id, request).toPromise();

												if (responseData.Result === EnumResponseResult.Success) {
												} else {
													this._progressSource.next(new ResponseMigrationProgress('[User message data migration] error to add message data : ' + user.Id + ', ' + agency.Id + ', ' + responseData.Message, processedCount, totalCount));
												}
											}

											this._progressSource.next(new ResponseMigrationProgress('[User message data migration] processed message data for ' + user.Name + ' (' + user.Id + '), ' + agency.Id, processedCount, totalCount));
										}
										else {

											this._progressSource.next(new ResponseMigrationProgress('[User message data migration] error to add message data : user not found for ' + userDoc.uid, processedCount, totalCount));

										}
									}
									else {

										this._progressSource.next(new ResponseMigrationProgress('[User message data migration] error to add message data : user not found', processedCount, totalCount));

									}
								}
							}
						}

						// // 사용자에 대해서 처리
						// for(let index = 0; index < users.length; index++) {
						//
						// 	const user: ResponseUserWithRoles = users[index];
						//
						// 	await this.migrateUserMessageData(user.Id, user.OldId, responses[0].Data.Items);
						// 	this._progressSource.next(new ResponseMigrationProgress('[User message migration] process message for ' + user.Id + ', ' + user.OldId, (index + 1), users.length));
						// }
						resolve(true);
					}
					else {
						this._progressSource.next(new ResponseMigrationProgress('[User message migration] error to get agencies'));
						resolve(false);
					}
				});
		});

	}

	/**
	 * 사용자 발송 메세지 데이터 마이그레이션
	 */
	public async migrateUserMessageData(id: string, uid: string, agencies: ResponseAgency[]): Promise<boolean> {

		return new Promise<boolean>(async (resolve) => {
			if(!id) {
				this._progressSource.next(new ResponseMigrationProgress('[User goal migration] invalid id : ' + uid + ', ' + id));
				resolve(false);
				return;
			}

			if(!uid) {
				this._progressSource.next(new ResponseMigrationProgress('[User goal migration] invalid uid : ' + uid + ', ' + id));
				resolve(false);
				return;
			}

			// 모든 기관에 대해서 처리
			for(let agencyIndex = 0; agencyIndex < agencies.length; agencyIndex++) {

				const agency: ResponseAgency = agencies[agencyIndex];

				if(agency.OldId && uid) {

					// 모든 메시지 삭제
					await this.agencyProvider.removePushMessages(agency.Id, id, new Date(2010, 1, 1, 0, 0, 0), new Date(2020, 12, 18, 0, 0, 0)).toPromise();

					// 해당 기관 내의 사용자에게 전송한 메세지 목록을 가져온다.
					const result = await this.db.collection('group')
						.doc(agency.OldId)
						.collection('uuid')
						.doc(uid)
						.collection('messages')
						.get();

					const docs = result.docs;

					// 모든 메시지에 대해서 처리
					for(let index = 0; index < docs.length; index++) {

						const row: any = docs[index].data();

						const request: RequestAgencyPushMessage = new RequestAgencyPushMessage();
						request.Title = row.title;
						request.PhotoUrl = row.photoRef;
						request.Message = row.message;
						if (row.date instanceof firebase.firestore.Timestamp)
							request.RegDate = row.date.toDate();
						else
							request.RegDate = row.date;

						// 메시지 저장
						const responseData: ResponseData<ResponseAgencyPushMessage> = await this.agencyProvider.addPushMessage(agency.Id, id, request).toPromise();

						if (responseData.Result === EnumResponseResult.Success) {
							// console.log('[User goal migration] success to add bloodPressure_measurement : ', id, request);
						} else {
							console.error(request);
							this._progressSource.next(new ResponseMigrationProgress('[User message data migration] error to add message data : ' + uid + ', ' + id + ', ' + responseData.Message));
						}
					}
				}
			}

			resolve(true);
		});

	}

	/**
	 * 모든 사용자 상담 메세지 데이터 마이그레이션
	 */
	public migrateUserConsultings(): Promise<boolean> {

		return new Promise<boolean>(async (resolve) => {

			forkJoin([
				// 모든 기관 목록을 가져온다.
				this.agencyProvider.getList(),
				// 사용자 목록을 가져온다.
				this.userProvider.getList({ countPerPage: 99999999 })
			])
				.subscribe(async (responses) => {

					// 가져오는데 성공한 경우
					if(responses[0].Result === EnumResponseResult.Success && responses[1].Result === EnumResponseResult.Success) {

						const users = responses[1].Data.Items;

						// 사용자에 대해서 처리
						for(let index = 0; index < users.length; index++) {

							const user: ResponseUserWithRoles = users[index];

							await this.migrateUserConsultingData(user.Id, user.OldId, responses[0].Data.Items);
							this._progressSource.next(new ResponseMigrationProgress('[User consulting migration] process consulting for ' + user.Id + ', ' + user.OldId, (index + 1), users.length));
						}
						resolve(true);
					}
					else {
						this._progressSource.next(new ResponseMigrationProgress('[User consulting migration] error to get agencies'));
						resolve(false);
					}
				});
		});

	}

	/**
	 * 사용자 상담 데이터 마이그레이션
	 */
	public async migrateUserConsultingData(id: string, uid: string, agencies: ResponseAgency[]): Promise<boolean> {

		return new Promise<boolean>(async (resolve) => {
			if(!id) {
				this._progressSource.next(new ResponseMigrationProgress('[User goal migration] invalid id : ' + uid + ', ' + id));
				resolve(false);
				return;
			}

			if(!uid) {
				this._progressSource.next(new ResponseMigrationProgress('[User goal migration] invalid uid : ' + uid + ', ' + id));
				resolve(false);
				return;
			}

			// 모든 기관에 대해서 처리
			for(let agencyIndex = 0; agencyIndex < agencies.length; agencyIndex++) {

				const agency: ResponseAgency = agencies[agencyIndex];

				if(agency.OldId && uid) {

					// 모든 상담 삭제
					await this.agencyProvider.removeConsultings(agency.Id, id, new Date(2010, 1, 1, 0, 0, 0), new Date(2020, 12, 18, 0, 0, 0)).toPromise();

					// 해당 기관 내의 사용자 상담 목록을 가져온다.
					const result = await this.db.collection('group')
						.doc(agency.OldId)
						.collection('uuid')
						.doc(uid)
						.collection('consulting')
						.get();

					const docs = result.docs;

					// 모든 상담에 대해서 처리
					for(let index = 0; index < docs.length; index++) {

						const row: any = docs[index].data();

						const request: RequestAgencyConsulting = new RequestAgencyConsulting();
						request.BoundType = row.boundType === '인바운드' ? EnumConsultingBoundType.InBound : EnumConsultingBoundType.OutBound;
						switch(row.eventType) {
							case '기기/앱':
								request.EventType = EnumConsultingEventType.App;
								break;
							case '건강상담':
								request.EventType = EnumConsultingEventType.Health;
								break;
							case '영양상담':
								request.EventType = EnumConsultingEventType.Nutrition;
								break;
							case '푸시메시지':
								request.EventType = EnumConsultingEventType.PushMessage;
								break;
							case '기타':
								request.EventType = EnumConsultingEventType.Etc;
								break;
						}
						if (row.startTime instanceof firebase.firestore.Timestamp)
							request.StartTime = row.startTime.toDate();
						else
							request.StartTime = row.startTime;
						if (row.endTime instanceof firebase.firestore.Timestamp)
							request.EndTime = row.endTime.toDate();
						else
							request.EndTime = row.endTime;
						request.Title = row.title;
						request.Description = row.message;

						// 상담 저장
						const responseData: ResponseData<ResponseAgencyConsulting> = await this.agencyProvider.addConsulting(agency.Id, id, request).toPromise();

						if (responseData.Result === EnumResponseResult.Success) {
							// console.log('[User goal migration] success to add bloodPressure_measurement : ', id, request);
						} else {
							console.error(request);
							this._progressSource.next(new ResponseMigrationProgress('[User message data migration] error to add message data : ' + uid + ', ' + id + ', ' + responseData.Message));
						}
					}
				}
			}

			resolve(true);
		});

	}

	/**
	 * 모든 측정 데이터에 사용자 ID 저장
	 */
	public updateUidToDeviceDatas() {
		return new Promise(async (res) => {

			// 전체 사용자 목록을 가져온다.
			this.userProvider.getList({ skip: 0, countPerPage: 9999999, orderFields: ['OldId'], orderDirections: ['asc'] })
				.subscribe(async (response) => {
					// 가져오는데 성공한 경우
					if(response.Result === EnumResponseResult.Success) {

						const users = response.Data.Items;

						this._progressSource.next(new ResponseMigrationProgress('device_data user count : ' + users.length));

						// 사용자에 대해서 처리
						for(let index = 0; index < users.length; index++) {
							const user: any = users[index];

							if(user) {
								const dataCount: number = await this.migrateUserDeviceData(true, user.Id, user.OldId, index, users.length);

								if(dataCount > 0) {

									// 아이디 업데이트
									this.db.collection('device_data')
										.doc(user.OldId)
										.set(Object.assign({}, { uid: user.OldId }))
										.then(() => {
											this._progressSource.next(new ResponseMigrationProgress('[User device data migration] process user for ' + user.OldId + ', ' + user.Id + ', ' + 'data count : ' + dataCount, (index + 1), users.length));
										})
										.catch((e: any) => {
											console.error(e);
										});
								}
								else {
									this._progressSource.next(new ResponseMigrationProgress('[User device data migration] there is no device data for ' + user.OldId + ', ' + user.Id + ', ' + 'data count : ' + dataCount, (index + 1), users.length));
								}
							}
						}

						res(users.length);
					}
					else {
						this._progressSource.next(new ResponseMigrationProgress('[User goal migration] error to get users'));

						res(0);
					}
				}, () => {
					res(0);
				});
		});
	}

	/**
	 * 모든 사용자 측정 데이터 마이그레이션
	 */
	public migrateDeviceDatas() {
		return new Promise(async (res) => {

			// 전체 사용자 목록을 가져온다.
			this.userProvider.getList({ skip: 0, countPerPage: 9999999, orderFields: ['OldId'], orderDirections: ['asc'] })
				.subscribe(async (response) => {
					// 가져오는데 성공한 경우
					if(response.Result === EnumResponseResult.Success) {

						const users = response.Data.Items;

						this._progressSource.next(new ResponseMigrationProgress('device_data user count : ' + users.length));

						// 사용자에 대해서 처리
						for(let index = 0; index < users.length; index++) {
							const user: ResponseUserWithRoles = users[index];

							const dataCount = await this.migrateUserDeviceData(false, user.Id, user.OldId, index, users.length);
							this._progressSource.next(new ResponseMigrationProgress('[User device data migration] process user for ' + user.OldId + ', ' + user.Id + ', data count : ' + dataCount, (index + 1), users.length));
						}

						res(users.length);
					}
					else {
						this._progressSource.next(new ResponseMigrationProgress('[User device data migration] error to get users'));

						res(0);
					}
				}, () => {
					res(0);
				});
		});
	}

	/**
	 * 특정 사용자 측정 데이터 마이그레이션
	 */
	public async migrateUserDeviceData(onlyCountCheck: boolean, id: string, uid: string, currentIndex: number, totalCount: number, removeAllOldData: boolean = true): Promise<number> {
		return new Promise(async (res) => {

			if(!id) {
				this._progressSource.next(new ResponseMigrationProgress('[User goal migration] invalid id : ' + uid + ', ' + id));
				res(0);
			}
			else if(!uid) {
				this._progressSource.next(new ResponseMigrationProgress('[User goal migration] invalid uid : ' + uid + ', ' + id));
				res(0);
			}
			else {

				const delay: number = 10;

				let dataCount: number = 0;

				this.getDownloadUrl(null);

				dataCount = dataCount + await this.migrateBloodPressure(onlyCountCheck, id, uid, currentIndex, totalCount, removeAllOldData);
				if(onlyCountCheck && dataCount > 0) {
					res(dataCount); return;
				}

				await this.delay(delay);

				dataCount = dataCount + await this.migrateBodyScale(onlyCountCheck, id, uid, currentIndex, totalCount, removeAllOldData);
				if(onlyCountCheck && dataCount > 0) {
					res(dataCount); return;
				}

				await this.delay(delay);

				dataCount = dataCount + await this.migrateFoodLens(onlyCountCheck, id, uid, currentIndex, totalCount, removeAllOldData);
				if(onlyCountCheck && dataCount > 0) {
					res(dataCount); return;
				}

				await this.delay(delay);

				dataCount = dataCount + await this.migrateGlucosemeter(onlyCountCheck, id, uid, currentIndex, totalCount, removeAllOldData);
				if(onlyCountCheck && dataCount > 0) {
					res(dataCount); return;
				}

				await this.delay(delay);

				dataCount = dataCount + await this.migrateHba1c(onlyCountCheck, id, uid, currentIndex, totalCount, removeAllOldData);
				if(onlyCountCheck && dataCount > 0) {
					res(dataCount); return;
				}

				await this.delay(delay);

				dataCount = dataCount + await this.migratePedometerActivity(onlyCountCheck, id, uid, currentIndex, totalCount, removeAllOldData);
				if(onlyCountCheck && dataCount > 0) {
					res(dataCount); return;
				}

				await this.delay(delay);

				dataCount = dataCount + await this.migratePedometerHeartRate(onlyCountCheck, id, uid, currentIndex, totalCount, removeAllOldData);
				if(onlyCountCheck && dataCount > 0) {
					res(dataCount); return;
				}

				await this.delay(delay);

				dataCount = dataCount + await this.migratePedometerSleep(onlyCountCheck, id, uid, currentIndex, totalCount, removeAllOldData);
				if(onlyCountCheck && dataCount > 0) {
					res(dataCount); return;
				}

				await this.delay(delay);

				// dataCount = dataCount + await this.migratePedometerActivitySummary(onlyCountCheck, id, uid, removeAllOldData);
				// if(onlyCountCheck && dataCount > 0) {
				// 	res(dataCount); return;
				// }
				//
				// await this.delay(delay);
				//
				// dataCount = dataCount + await this.migratePedometerSleepSummary(onlyCountCheck, id, uid, removeAllOldData);
				// if(onlyCountCheck && dataCount > 0) {
				// 	res(dataCount); return;
				// }
				//
				// await this.delay(delay);

				res(dataCount);
			}
		});
	}

	/**
	 * 혈압 데이터 마이그레이션
	 */
	private migrateBloodPressure(onlyCountCheck: boolean, id: string, uid: string, currentIndex: number, totalCount: number, removeAllOldData: boolean): Promise<number> {
		return new Promise(async (res) => {

			// 이전 데이터 삭제
			if(removeAllOldData)
				await this.userDeviceDataProvider.removeBloodPressureDatas(id, new Date(2010, 1, 1, 0, 0, 0), new Date(2020, 12, 18, 0, 0, 0)).toPromise();

			const totalCountData = await this.db.collection('device_data')
				.doc(uid)
				.collection('bloodpresure_measurement')
				.get();

			if(totalCountData.docs.length > 0) {

				if(!onlyCountCheck) {
					let lastItem: any = null;
					let addCount: number = 0;
					for(let pageIndex = 0; pageIndex < Math.ceil(totalCountData.docs.length / this.countPerPage); pageIndex++) {

						const deviceDatasBloodPressureMeasurement: any = await this.db.collection('device_data')
							.doc(uid)
							.collection('bloodpresure_measurement')
							.orderBy('date')
							.startAfter(lastItem)
							.limit(this.countPerPage)
							.get();

						const requests: RequestBloodPressureData[] = [];
						for (let index = 0; index < deviceDatasBloodPressureMeasurement.docs.length; index++) {

							const value = deviceDatasBloodPressureMeasurement.docs[index];

							const data = value.data();

							if(data.date) {
								const request: RequestBloodPressureData = new RequestBloodPressureData();
								if (data.date instanceof firebase.firestore.Timestamp)
									request.RegDate = data.date.toDate();
								else
									request.RegDate = moment(data.date).toDate();
								request.Diastolic = this.parseNumber(data.diastolic);
								request.Systolic = this.parseNumber(data.systolic);
								request.Mean = this.parseNumber(data.mean);
								request.Rate = this.parseNumber(data.rate);
								if(data.ignore)
									request.IsDeleted = data.ignore;
								requests.push(request);
								addCount++;
							}

							lastItem = value;
						}

						this._progressSource.next(new ResponseMigrationProgress('[User device data migration] bloodPressure_measurement : send data to server (totalCount : ' + totalCountData.docs.length
							+ ', addCount : ' + addCount + ')', currentIndex + 1, totalCount));

						if(requests.length > 0) {
							const responseData: ResponseData = await this.userDeviceDataProvider.addBloodPressureData(id, requests).toPromise();

							// console.log('[User goal migration] process bloodPressure_measurement (' + (dataIndex + 1) + '/' + bloodPressureDatas.length + ')');

							if (responseData.Result === EnumResponseResult.Success) {
								// console.log('[User goal migration] success to add bloodPressure_measurement : ', id, request);
							} else {
								console.error(requests);
								this._progressSource.next(new ResponseMigrationProgress('[User device data migration] error to add bloodPressure_measurement data : ' + uid + ', ' + id + ', ' + responseData.Message, currentIndex + 1, totalCount));
							}
						}
					}

				}

				res(totalCountData.docs.length);
			}
			else {
				// console.error('[User device data migration] bloodPressure_measurement is no data for ', uid);
				res(0);
			}
		});
	}

	/**
	 * 혈압 체성분계 마이그레이션
	 */
	private migrateBodyScale(onlyCountCheck: boolean, id: string, uid: string, currentIndex: number, totalCount: number, removeAllOldData: boolean): Promise<number> {
		return new Promise(async (res) => {

			// 이전 데이터 삭제
			if(removeAllOldData)
				await this.userDeviceDataProvider.removeBodyScaleDatas(id, new Date(2010, 1, 1, 0, 0, 0), new Date(2020, 12, 18, 0, 0, 0)).toPromise();

			const totalCountData = await this.db.collection('device_data')
				.doc(uid)
				.collection('bodyscale_measurement')
				.get();

			if(totalCountData.docs.length > 0) {

				if(!onlyCountCheck) {
					let lastItem: any = null;
					let addCount: number = 0;
					for(let pageIndex = 0; pageIndex < Math.ceil(totalCountData.docs.length / this.countPerPage); pageIndex++) {
						const deviceDatasBodyscaleMeasurement: any = await this.db.collection('device_data')
							.doc(uid)
							.collection('bodyscale_measurement')
							.orderBy('date')
							.startAfter(lastItem)
							.limit(this.countPerPage)
							.get();

						const requests: RequestBodyScaleData[] = [];
						for(let index = 0; index < deviceDatasBodyscaleMeasurement.docs.length; index++) {

							const value = deviceDatasBodyscaleMeasurement.docs[index];

							const data = value.data();

							if(data.date) {
								const request: RequestBodyScaleData = new RequestBodyScaleData();
								if (data.date instanceof firebase.firestore.Timestamp)
									request.RegDate = data.date.toDate();
								else
									request.RegDate = moment(data.date).toDate();
								request.Bmi = this.parseNumber(data.bmi);
								request.Bmr = this.parseNumber(data.bmr);
								request.Bone = this.parseNumber(data.bone);
								request.Fat = this.parseNumber(data.fat);
								request.Muscle = this.parseNumber(data.muscle);
								request.Visceral = this.parseNumber(data.visceral);
								request.Water = this.parseNumber(data.water);
								request.Weight = this.parseNumber(data.weight);
								if(data.ignore)
									request.IsDeleted = data.ignore;
								requests.push(request);
								addCount++;
							}

							lastItem = value;
						}

						this._progressSource.next(new ResponseMigrationProgress('[User device data migration] bodyscale_measurement : send data to server (totalCount : ' + totalCountData.docs.length
							+ ', addCount : ' + addCount + ')', currentIndex + 1, totalCount));

						if(requests.length > 0) {
							const responseData: ResponseData = await this.userDeviceDataProvider.addBodyScaleData(id, requests).toPromise();

							// console.log('[User goal migration] process bodyscale_measurement (' + (dataIndex + 1) + '/' + bloodPressureDatas.length + ')');

							if(responseData.Result === EnumResponseResult.Success) {
								// console.log('[User goal migration] success to add bodyscale_measurement : ', id, request);
							}
							else {
								console.error(requests);
								this._progressSource.next(new ResponseMigrationProgress('[User device data migration] error to add bodyscale_measurement data : ' + uid + ', ' + id + ', ' + responseData.Message, currentIndex + 1, totalCount));
							}
						}
					}

				}

				res(totalCountData.docs.length);
			}
			else {
				// console.error('[User device data migration] bodyscale_measurement is no data for ', uid);
				res(0);
			}
		});
	}

	/**
	 * 혈압 식단 마이그레이션
	 */
	private migrateFoodLens(onlyCountCheck: boolean, id: string, uid: string, currentIndex: number, totalCount: number, removeAllOldData: boolean): Promise<number> {
		return new Promise(async (res) => {

			// 이전 데이터 삭제
			if(removeAllOldData)
				await this.userDeviceDataProvider.removeFoodLensDatas(id, new Date(2010, 1, 1, 0, 0, 0), new Date(2020, 12, 18, 0, 0, 0)).toPromise();

			const totalCountData = await this.db.collection('device_data')
				.doc(uid)
				.collection('foodlens_measurement')
				.get();

			if(totalCountData.docs.length > 0) {

				if(!onlyCountCheck) {
					let lastItem: any = null;
					let addCount: number = 0;
					for(let pageIndex = 0; pageIndex < Math.ceil(totalCountData.docs.length / this.countPerPage); pageIndex++) {
						const deviceDatasFoodlensMeasurement: any = await this.db.collection('device_data')
							.doc(uid)
							.collection('foodlens_measurement')
							.orderBy('date')
							.startAfter(lastItem)
							.limit(this.countPerPage)
							.get();

						const requests: RequestFoodLensData[] = [];
						for(let index = 0; index < deviceDatasFoodlensMeasurement.docs.length; index++) {

							const value = deviceDatasFoodlensMeasurement.docs[index];

							const data = value.data();

							if(data.date) {
								const request: RequestFoodLensData = new RequestFoodLensData();
								if (data.date instanceof firebase.firestore.Timestamp)
									request.RegDate = data.date.toDate();
								else
									request.RegDate = moment(data.date).toDate();
								switch(data.mealType) {
									case 'breakfast':
										request.MealType = EnumMealType.Breakfast;
										break;
									case 'lunch':
										request.MealType = EnumMealType.Lunch;
										break;
									case 'dinner':
										request.MealType = EnumMealType.Dinner;
										break;
									case 'snack':
										request.MealType = EnumMealType.Snack;
										break;
								}
								if(data.refPhoto) {
									request.PhotoUrl = await this.getDownloadUrl(data.refPhoto);
								}
								if(data.ignore)
									request.IsDeleted = data.ignore;
								request.Foods = [];
								for(let detailIndex = 0; detailIndex < data.composition.length; detailIndex++) {
									const detail: any = data.composition[detailIndex];

									const requestFood: RequestFoodLensDetailData = new RequestFoodLensDetailData();
									requestFood.FoodName = detail.foodname;
									requestFood.PhotoUrl = detail.refPhoto;
									requestFood.EatAmount = this.parseNumber(detail.eatAmount);
									requestFood.Unit = detail.unit;
									requestFood.Calcium = this.parseNumber(detail.calcium);
									requestFood.Calories = this.parseNumber(detail.calories);
									requestFood.Carbohydrate = this.parseNumber(detail.carbonhydrate);
									requestFood.Cholesterol = this.parseNumber(detail.cholesterol);
									requestFood.DietaryFiber = this.parseNumber(detail.dietrayfiber);
									requestFood.Fat = this.parseNumber(detail.fat);
									requestFood.Protein = this.parseNumber(detail.protein);
									requestFood.SaturatedFat = this.parseNumber(detail.saturatedfat);
									requestFood.Sodium = this.parseNumber(detail.sodium);
									requestFood.Sugar = this.parseNumber(detail.sugar);
									requestFood.TotalGram = this.parseNumber(detail.totalgram);
									requestFood.TransFat = this.parseNumber(detail.transfat);
									requestFood.VitaminA = this.parseNumber(detail.vitamina);
									requestFood.VitaminB = this.parseNumber(detail.vitaminb);
									requestFood.VitaminC = this.parseNumber(detail.vitaminc);
									requestFood.VitaminD = this.parseNumber(detail.vitamind);
									requestFood.VitaminE = this.parseNumber(detail.vitamine);

									request.Foods.push(requestFood);
								}
								requests.push(request);
								addCount++;
							}

							lastItem = value;
						}

						this._progressSource.next(new ResponseMigrationProgress('[User device data migration] foodlens_measurement : send data to server (totalCount : ' + totalCountData.docs.length
							+ ', addCount : ' + addCount + ')', currentIndex + 1, totalCount));

						if(requests.length > 0) {
							const responseData: ResponseData = await this.userDeviceDataProvider.addFoodLensData(id, requests).toPromise();

							// console.log('[User goal migration] process foodlens_measurement (' + (dataIndex + 1) + '/' + bloodPressureDatas.length + ')');

							if(responseData.Result === EnumResponseResult.Success) {
								// console.log('[User goal migration] success to add foodlens_measurement : ', id, request);
							}
							else {
								console.error(requests);
								this._progressSource.next(new ResponseMigrationProgress('[User device data migration] error to add foodlens_measurement data : ' + uid + ', ' + id + ', ' + responseData.Message, currentIndex + 1, totalCount));
							}
						}
					}

				}

				res(totalCountData.docs.length);
			}
			else {
				// console.error('[User device data migration] foodlens_measurement is no data for ', uid);
				res(0);
			}
		});
	}

	/**
	 * 혈압 혈당계 마이그레이션
	 */
	private migrateGlucosemeter(onlyCountCheck: boolean, id: string, uid: string, currentIndex: number, totalCount: number, removeAllOldData: boolean): Promise<number> {
		return new Promise(async (res) => {

			// 이전 데이터 삭제
			if(removeAllOldData)
				await this.userDeviceDataProvider.removeGlucosemeterDatas(id, new Date(2010, 1, 1, 0, 0, 0), new Date(2020, 12, 18, 0, 0, 0)).toPromise();

			const totalCountData = await this.db.collection('device_data')
				.doc(uid)
				.collection('glucosemeter_measurement')
				.get();

			if(totalCountData.docs.length > 0) {

				if(!onlyCountCheck) {
					let lastItem: any = null;
					let addCount: number = 0;
					for(let pageIndex = 0; pageIndex < Math.ceil(totalCountData.docs.length / this.countPerPage); pageIndex++) {
						const deviceDatasGlucosemeterMeasurement: any = await this.db.collection('device_data')
							.doc(uid)
							.collection('glucosemeter_measurement')
							.orderBy('date')
							.startAfter(lastItem)
							.limit(this.countPerPage)
							.get();

						const requests: RequestGlucosemeterData[] = [];
						for(let index = 0; index < deviceDatasGlucosemeterMeasurement.docs.length; index++) {

							const value = deviceDatasGlucosemeterMeasurement.docs[index];

							const data = value.data();

							if(data.date) {
								const request: RequestGlucosemeterData = new RequestGlucosemeterData();
								if (data.date instanceof firebase.firestore.Timestamp)
									request.RegDate = data.date.toDate();
								else
									request.RegDate = moment(data.date).toDate();
								switch(data.timeofday) {
									case 'breakfast':
										request.TimeOfDay = EnumTimeOfDay.Breakfast;
										break;
									case 'lunch':
										request.TimeOfDay = EnumTimeOfDay.Lunch;
										break;
									case 'dinner':
										request.TimeOfDay = EnumTimeOfDay.Dinner;
										break;
									case 'sleep':
										request.TimeOfDay = EnumTimeOfDay.Sleep;
										break;
								}
								switch(data.condition) {
									case 'b_meal':
										request.BeforeAndAfter = EnumBeforeAndAfter.Before;
										break;
									case 'a_meal':
										request.BeforeAndAfter = EnumBeforeAndAfter.After;
										break;
								}
								request.Value = this.parseNumber(data.measurement);
								if(data.ignore)
									request.IsDeleted = data.ignore;
								requests.push(request);
								addCount++;
							}

							lastItem = value;
						}

						this._progressSource.next(new ResponseMigrationProgress('[User device data migration] glucosemeter_measurement : send data to server (totalCount : ' + totalCountData.docs.length
							+ ', addCount : ' + addCount + ')', currentIndex + 1, totalCount));

						if(requests.length > 0) {
							const responseData: ResponseData = await this.userDeviceDataProvider.addGlucosemeterData(id, requests).toPromise();

							// console.log('[User goal migration] process glucosemeter_measurement (' + (dataIndex + 1) + '/' + bloodPressureDatas.length + ')');

							if(responseData.Result === EnumResponseResult.Success) {
								// console.log('[User goal migration] success to add glucosemeter_measurement : ', id, request);
							}
							else {
								console.error(requests);
								this._progressSource.next(new ResponseMigrationProgress('[User device data migration] error to add glucosemeter_measurement data : ' + uid + ', ' + id + ', ' + responseData.Message, currentIndex + 1, totalCount));
							}
						}
					}

				}

				res(totalCountData.docs.length);
			}
			else {
				// console.error('[User device data migration] glucosemeter_measurement is no data for ', uid);
				res(0);
			}
		});
	}

	/**
	 * Hba1c 데이터 마이그레이션
	 */
	private migrateHba1c(onlyCountCheck: boolean, id: string, uid: string, currentIndex: number, totalCount: number, removeAllOldData: boolean): Promise<number> {
		return new Promise(async (res) => {

			// 이전 데이터 삭제
			if(removeAllOldData)
				await this.userDeviceDataProvider.removeHba1cDatas(id, new Date(2010, 1, 1, 0, 0, 0), new Date(2020, 12, 18, 0, 0, 0)).toPromise();

			const totalCountData = await this.db.collection('device_data')
				.doc(uid)
				.collection('hba1c')
				.get();

			if(totalCountData.docs.length > 0) {

				if(!onlyCountCheck) {
					let lastItem: any = null;
					let addCount: number = 0;
					for(let pageIndex = 0; pageIndex < Math.ceil(totalCountData.docs.length / this.countPerPage); pageIndex++) {
						const deviceDatasHba1c: any = await this.db.collection('device_data')
							.doc(uid)
							.collection('hba1c')
							.orderBy('date')
							.startAfter(lastItem)
							.limit(this.countPerPage)
							.get();

						const requests: RequestGlucosemeterData[] = [];
						for(let index = 0; index < deviceDatasHba1c.docs.length; index++) {

							const value = deviceDatasHba1c.docs[index];

							const data = value.data();

							if(data.date) {
								const request: RequestGlucosemeterData = new RequestGlucosemeterData();
								if (data.date instanceof firebase.firestore.Timestamp)
									request.RegDate = data.date.toDate();
								else
									request.RegDate = moment(data.date).toDate();
								request.Value = this.parseNumber(data.measurement);
								if(data.ignore)
									request.IsDeleted = data.ignore;
								requests.push(request);
								addCount++;
							}

							lastItem = value;
						}

						this._progressSource.next(new ResponseMigrationProgress('[User device data migration] hba1c : send data to server (totalCount : ' + totalCountData.docs.length
							+ ', addCount : ' + addCount + ')', currentIndex + 1, totalCount));

						if(requests.length > 0) {
							const responseData: ResponseData = await this.userDeviceDataProvider.addHba1cData(id, requests).toPromise();

							// console.log('[User goal migration] process hba1c (' + (dataIndex + 1) + '/' + bloodPressureDatas.length + ')');

							if(responseData.Result === EnumResponseResult.Success) {
								// console.log('[User goal migration] success to add hba1c : ', id, request);
							}
							else {
								console.error(requests);
								this._progressSource.next(new ResponseMigrationProgress('[User device data migration] error to add hba1c data : ' + uid + ', ' + id + ', ' + responseData.Message, currentIndex + 1, totalCount));
							}
						}
					}

				}

				res(totalCountData.docs.length);
			}
			else {
				// console.error('[User device data migration] hba1c is no data for ', uid);
				res(0);

			}
		});
	}

	/**
	 * 활동계 활동 데이터 마이그레이션
	 */
	private migratePedometerActivity(onlyCountCheck: boolean, id: string, uid: string, currentIndex: number, totalCount: number, removeAllOldData: boolean): Promise<number> {
		return new Promise(async (res) => {

			// 이전 데이터 삭제
			if(removeAllOldData)
				await this.userDeviceDataProvider.removePedometerActivityDatas(id, new Date(2010, 1, 1, 0, 0, 0), new Date(2020, 12, 18, 0, 0, 0)).toPromise();

			const totalCountData = await this.db.collection('device_data')
				.doc(uid)
				.collection('pedometer_time_segment')
				.get();

			if(totalCountData.docs.length > 0) {

				if(!onlyCountCheck) {
					let lastItem: any = null;
					let addCount: number = 0;
					for(let pageIndex = 0; pageIndex < Math.ceil(totalCountData.docs.length / this.countPerPage); pageIndex++) {
						const deviceDatasPedometerTimeSegment: any = await this.db.collection('device_data')
							.doc(uid)
							.collection('pedometer_time_segment')
							.orderBy('date')
							.startAfter(lastItem)
							.limit(this.countPerPage)
							.get();

						const requests: RequestPedometerActivityData[] = [];
						for(let index = 0; index < deviceDatasPedometerTimeSegment.docs.length; index++) {

							const value = deviceDatasPedometerTimeSegment.docs[index];

							const data = value.data();

							if(data.date) {
								const request: RequestPedometerActivityData = new RequestPedometerActivityData();
								if (data.date instanceof firebase.firestore.Timestamp)
									request.RegDate = data.date.toDate();
								else
									request.RegDate = moment(data.date).toDate();
								request.Calories = this.parseNumber(data.cal);
								request.Distance = this.parseNumber(data.dist);
								request.Duration = this.parseNumber(data.duration);
								request.Step = this.parseNumber(data.step);
								if(data.ignore)
									request.IsDeleted = data.ignore;
								requests.push(request);
								addCount++;
							}

							lastItem = value;
						}

						this._progressSource.next(new ResponseMigrationProgress('[User device data migration] pedometer_time_segment : send data to server (totalCount : ' + totalCountData.docs.length
							+ ', addCount : ' + addCount + ')', currentIndex + 1, totalCount));

						if(requests.length > 0) {
							const responseData: ResponseData = await this.userDeviceDataProvider.addPedometerActivityData(id, requests).toPromise();

							// console.log('[User goal migration] process pedometer_time_segment (' + (dataIndex + 1) + '/' + bloodPressureDatas.length + ')');

							if(responseData.Result === EnumResponseResult.Success) {
								// console.log('[User goal migration] success to add pedometer_time_segment : ', id, request);
							}
							else {
								console.error(requests);
								this._progressSource.next(new ResponseMigrationProgress('[User device data migration] error to add pedometer_time_segment data : ' + uid + ', ' + id + ', ' + responseData.Message, currentIndex + 1, totalCount));
							}
						}
					}

				}

				res(totalCountData.docs.length);
			}
			else {
				// console.error('[User device data migration] pedometer_time_segment is no data for ', uid);
				res(0);
			}
		});
	}

	/**
	 * 활동계 수면 데이터 마이그레이션
	 */
	private migratePedometerSleep(onlyCountCheck: boolean, id: string, uid: string, currentIndex: number, totalCount: number, removeAllOldData: boolean): Promise<number> {
		return new Promise(async (res) => {

			// 이전 데이터 삭제
			if(removeAllOldData)
				await this.userDeviceDataProvider.removePedometerSleepDatas(id, new Date(2010, 1, 1, 0, 0, 0), new Date(2020, 12, 18, 0, 0, 0)).toPromise();

			const totalCountData = await this.db.collection('device_data')
				.doc(uid)
				.collection('pedometer_sleep_segment')
				.get();

			if(totalCountData.docs.length > 0) {

				if(!onlyCountCheck) {
					let lastItem: any = null;
					let addCount: number = 0;
					for(let pageIndex = 0; pageIndex < Math.ceil(totalCountData.docs.length / this.countPerPage); pageIndex++) {
						const deviceDatasPedometerSleepSegment: any = await this.db.collection('device_data')
							.doc(uid)
							.collection('pedometer_sleep_segment')
							.orderBy('date')
							.startAfter(lastItem)
							.limit(this.countPerPage)
							.get();

						const requests: RequestPedometerSleepData[] = [];
						for(let index = 0; index < deviceDatasPedometerSleepSegment.docs.length; index++) {

							const value = deviceDatasPedometerSleepSegment.docs[index];

							const data = value.data();

							if(data.date) {
								const request: RequestPedometerSleepData = new RequestPedometerSleepData();
								if (data.date instanceof firebase.firestore.Timestamp)
									request.RegDate = data.date.toDate();
								else
									request.RegDate = moment(data.date).toDate();
								request.SleepIndex = this.parseNumber(data.sleepIndex);
								if(data.ignore)
									request.IsDeleted = data.ignore;
								requests.push(request);
								addCount++;
							}

							lastItem = value;
						}

						this._progressSource.next(new ResponseMigrationProgress('[User device data migration] pedometer_sleep_segment : send data to server (totalCount : ' + totalCountData.docs.length
							+ ', addCount : ' + addCount + ')', currentIndex + 1, totalCount));

						if(requests.length > 0) {
							this.userDeviceDataProvider.addPedometerSleepData(id, requests).toPromise()
								.then((responseData) => {

									// console.log('[User goal migration] process pedometer_sleep_segment (' + (dataIndex + 1) + '/' + bloodPressureDatas.length + ')');

									if(responseData.Result === EnumResponseResult.Success) {
										// console.log('[User goal migration] success to add pedometer_sleep_segment : ', id, request);
									}
									else {
										console.error(requests);
										this._progressSource.next(new ResponseMigrationProgress('[User device data migration] error to add pedometer_sleep_segment data : ' + uid + ', ' + id + ', ' + responseData.Message, currentIndex + 1, totalCount));
									}
								});
						}
					}

				}

				res(totalCountData.docs.length);
			}
			else {
				// console.error('[User device data migration] pedometer_sleep_segment is no data for ', uid);
				res(0);
			}
		});
	}

	/**
	 * 활동계 심박 데이터 마이그레이션
	 */
	private migratePedometerHeartRate(onlyCountCheck: boolean, id: string, uid: string, currentIndex: number, totalCount: number, removeAllOldData: boolean): Promise<number> {
		return new Promise(async (res) => {

			// 이전 데이터 삭제
			if(removeAllOldData)
				await this.userDeviceDataProvider.removePedometerHeartRateDatas(id, new Date(2010, 1, 1, 0, 0, 0), new Date(2020, 12, 18, 0, 0, 0)).toPromise();

			const totalCountData = await this.db.collection('device_data')
				.doc(uid)
				.collection('pedometer_heartrate_segments')
				.get();

			if(totalCountData.docs.length > 0) {

				if(!onlyCountCheck) {
					let lastItem: any = null;
					let addCount: number = 0;
					for(let pageIndex = 0; pageIndex < Math.ceil(totalCountData.docs.length / this.countPerPage); pageIndex++) {
						const deviceDatasPedometerHeartrateSegments: any = await this.db.collection('device_data')
							.doc(uid)
							.collection('pedometer_heartrate_segments')
							.orderBy('date')
							.startAfter(lastItem)
							.limit(this.countPerPage)
							.get();

						const requests: RequestPedometerHeartRateData[] = [];
						for(let index = 0; index < deviceDatasPedometerHeartrateSegments.docs.length; index++) {

							const value = deviceDatasPedometerHeartrateSegments.docs[index];

							const data = value.data();

							if(data.date) {
								const request: RequestPedometerHeartRateData = new RequestPedometerHeartRateData();
								if (data.date instanceof firebase.firestore.Timestamp)
									request.RegDate = data.date.toDate();
								else
									request.RegDate = moment(data.date).toDate();
								request.Rate = this.parseNumber(data.rate);
								if(data.ignore)
									request.IsDeleted = data.ignore;
								requests.push(request);
								addCount++;
							}

							lastItem = value;
						}

						this._progressSource.next(new ResponseMigrationProgress('[User device data migration] pedometer_heartrate_segments : send data to server (totalCount : ' + totalCountData.docs.length
							+ ', addCount : ' + addCount + ')', currentIndex + 1, totalCount));

						if(requests.length > 0) {
							const responseData: ResponseData = await this.userDeviceDataProvider.addPedometerHeartRateData(id, requests).toPromise();

							// console.log('[User goal migration] process pedometer_heartrate_segments (' + (dataIndex + 1) + '/' + bloodPressureDatas.length + ')');

							if(responseData.Result === EnumResponseResult.Success) {
								// console.log('[User goal migration] success to add pedometer_heartrate_segments : ', id, request);
							}
							else {
								console.error(requests);
								this._progressSource.next(new ResponseMigrationProgress('[User device data migration] error to add pedometer_heartrate_segments data : ' + uid + ', ' + id + ', ' + responseData.Message, currentIndex + 1, totalCount));
							}
						}
					}

				}

				res(totalCountData.docs.length);
			}
			else {
				// console.error('[User device data migration] pedometer_heartrate_segments is no data for ', uid);
				res(0);
			}
		});
	}

	// /**
	//  * 활동계 활동 요약 데이터 마이그레이션
	//  */
	// private migratePedometerActivitySummary(onlyCountCheck: boolean, id: string, uid: string, removeAllOldData: boolean): Promise<number> {
	// 	return new Promise(async (res) => {
	//
	// 		// 이전 데이터 삭제
	// 		if(removeAllOldData)
	// 			await this.userDeviceDataProvider.removePedometerActivitySummaries(id, new Date(2010, 1, 1, 0, 0, 0), new Date()).toPromise();
	//
	// 		const totalCountData = await this.db.collection('device_data')
	// 			.doc(uid)
	// 			.collection('pedometer_day_summary')
	// 			.get();
	//
	// 		if(totalCountData.docs.length > 0) {
	//
	// 			if(!onlyCountCheck) {
	// 				let lastItem: any = null;
	// 				for(let index = 0; index < Math.ceil(totalCountData.docs.length / this.countPerPage); index++) {
	// 					const deviceDatasPedometerDaySummary: any = await this.db.collection('device_data')
	// 						.doc(uid)
	// 						.collection('pedometer_day_summary')
	// 						.orderBy('date')
	// 						.startAfter(lastItem)
	// 						.limit(this.countPerPage)
	// 						.get();
	//
	// 					let addCount: number = 0;
	// 					const requests: RequestPedometerActivitySummary[] = [];
	// 					for(let index = 0; index < deviceDatasPedometerDaySummary.docs.length; index++) {
	//
	// 						const value = deviceDatasPedometerDaySummary.docs[index];
	//
	// 						const data = value.data();
	//
	// 						const request: RequestPedometerActivitySummary = new RequestPedometerActivitySummary();
	// 						request.RegDate = data.date.toDate();
	// 						request.Calories = this.parseNumber(data.cal);
	// 						request.Distance = this.parseNumber(data.dist);
	// 						request.Duration = this.parseNumber(0);
	// 						request.Step = this.parseNumber(data.step);
	// 						requests.push(request);
	// 						addCount++;
	//
	// 						lastItem = value;
	// 					}
	//
	// 					console.log('[User device data migration] pedometer_day_summary : send data to server (totalCount : ' + deviceDatasPedometerDaySummary.docs.length + ', addCount : ' + addCount + ')');
	//
	// 					if(requests.length > 0) {
	// 						this.userDeviceDataProvider.addPedometerActivitySummary(id, requests).toPromise()
	// 							.then((responseData) => {
	//
	// 								// console.log('[User goal migration] process pedometer_day_summary (' + (dataIndex + 1) + '/' + bloodPressureDatas.length + ')');
	//
	// 								if(responseData.Result === EnumResponseResult.Success) {
	// 									// console.log('[User goal migration] success to add pedometer_day_summary : ', id, request);
	// 								}
	// 								else {
	// 									console.log(requests);
	// 									console.error('[User device data migration] error to add pedometer_day_summary data : ', uid, id, responseData.Message);
	// 								}
	// 							});
	// 					}
	// 				}
	// 			}
	//
	// 			res(totalCountData.docs.length);
	// 		}
	// 		else {
	// 			// console.error('[User device data migration] pedometer_day_summary is no data for ', uid);
	// 			res(0);
	// 		}
	// 	});
	// }
	//
	// /**
	//  * 활동계 수면 요약 데이터 마이그레이션
	//  */
	// private migratePedometerSleepSummary(onlyCountCheck: boolean, id: string, uid: string, removeAllOldData: boolean): Promise<number> {
	// 	return new Promise(async (res) => {
	//
	// 		// 이전 데이터 삭제
	// 		if(removeAllOldData)
	// 			await this.userDeviceDataProvider.removePedometerSleepSummaries(id, new Date(2010, 1, 1, 0, 0, 0), new Date()).toPromise();
	//
	// 		const totalCountData = await this.db.collection('device_data')
	// 			.doc(uid)
	// 			.collection('pedometer_sleep_summary')
	// 			.get();
	//
	// 		if(totalCountData.docs.length > 0) {
	//
	// 			if(!onlyCountCheck) {
	//
	// 				let lastItem: any = null;
	// 				for(let index = 0; index < Math.ceil(totalCountData.docs.length / this.countPerPage); index++) {
	// 					const deviceDatasPedometerSleepSummary: any = await this.db.collection('device_data')
	// 						.doc(uid)
	// 						.collection('pedometer_sleep_summary')
	// 						.orderBy('date')
	// 						.startAfter(lastItem)
	// 						.limit(this.countPerPage)
	// 						.get();
	//
	// 					let addCount: number = 0;
	// 					const requests: RequestPedometerSleepSummary[] = [];
	// 					for(let index = 0; index < deviceDatasPedometerSleepSummary.docs.length; index++) {
	//
	// 						const value = deviceDatasPedometerSleepSummary.docs[index];
	//
	// 						const data = value.data();
	//
	// 						const request: RequestPedometerSleepSummary = new RequestPedometerSleepSummary();
	// 						request.RegDate = data.date.toDate();
	// 						request.Light = this.parseNumber(data.lightSleep);
	// 						request.Deep = this.parseNumber(data.deepSleep);
	// 						requests.push(request);
	// 						addCount++;
	//
	// 						lastItem = value;
	//
	// 						// console.log('[User device data migration] pedometer_sleep_segment : ' + ' (' + (index + 1) + '/' + deviceDatasPedometerSleepSummary.docs.length + ')');
	// 					}
	//
	// 					console.log('[User device data migration] pedometer_sleep_summary : send data to server (totalCount : ' + deviceDatasPedometerSleepSummary.docs.length + ', addCount : ' + addCount + ')');
	//
	// 					if(requests.length > 0) {
	// 						this.userDeviceDataProvider.addPedometerSleepSummary(id, requests).toPromise()
	// 							.then((responseData) => {
	//
	// 								// console.log('[User goal migration] process pedometer_sleep_summary (' + (dataIndex + 1) + '/' + bloodPressureDatas.length + ')');
	//
	// 								if(responseData.Result === EnumResponseResult.Success) {
	// 									// console.log('[User goal migration] success to add pedometer_sleep_summary : ', id, request);
	// 								}
	// 								else {
	// 									console.log(requests);
	// 									console.error('[User device data migration] error to add pedometer_sleep_summary data : ', uid, id, responseData.Message);
	// 								}
	// 							});
	// 					}
	// 				}
	// 			}
	//
	// 			res(totalCountData.docs.length);
	// 		}
	// 		else {
	// 			// console.error('[User device data migration] bloodPressure_measurement is no data for ', uid);
	// 			res(0);
	// 		}
	// 	});
	// }

	/**
	 * 특정 값을 number 값으로 변환한다.
	 * @param value 원본 값
	 * @return 변환된 number 값
	 */
	private parseNumber(value: any): number {
		if(value) {
			if(typeof(value) === 'number')
				return value;
			else if(typeof(value) === 'string') {
				if(value.length > 0)
					return parseInt(value, 10);
				else
					return 0;
			}
		}
		else
			return 0;
	}

	/**
	 * Fire Storage 파일 다운로드
	 * @param url 다운로드할 URL
	 */
	private getDownloadUrl(url: string): Promise<string> {
		return new Promise((res) => {
			if(url) {
				this.storage.ref(url).getDownloadURL()
					.then((addr: string) => {
						res(addr);
					})
					.catch(() => {
						res(null);
					});
			}
			else
				res(null);
		});
	}

	private delay(ms: number) {
		return new Promise( resolve => setTimeout(resolve, ms) );
	}
}
