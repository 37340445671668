/**
 * 전역 확장 메서드
 */
interface Array<T> {
	isEmpty(): boolean;

	firstOrDefault(): any;
}


/**
 * 확장 - Array 가 Null 또는 Undefined 인지 체크한다
 */
Array.prototype.isEmpty = function(): boolean {
	let isEmpty: boolean = false;

	// 유효성 검사
	if (this === null) {
		isEmpty = true;
	}

	if (typeof this === 'undefined')
		isEmpty = true;

	if (this.length === 0)
		isEmpty = true;

	return isEmpty;
};


/**
 * 확장 - Array 가 의 첫번째 객체를 리턴한다
 */
Array.prototype.firstOrDefault = function(): any {
	return this[0];
};

