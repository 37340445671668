/**
 * 사용자 공통 데이터 응답 클래스
 */
export class ResponseCommonData {
	/**
	 * 사용자 아이디
	 */
	public UserId: string;
	/**
	 * 데이터 아이디
	 */
	public DataId: string;
	/**
	 * 등록일시
	 */
	public RegDate: Date;
}


