
export class RequestUserHealthGoal {
	public ActivityStep: number;
	public ActivityCalorieMin: number;
	public ActivityCalorieMax: number;
	public GlucoseBeforeMealMin: number;
	public GlucoseBeforeMealMax: number;
	public GlucoseAfterMealMin: number;
	public GlucoseAfterMealMax: number;
	public GlucoseBeforeSleepMin: number;
	public GlucoseBeforeSleepMax: number;
	public BloodPressureMin: number;
	public BloodPressureMax: number;
	public BodyScaleWeightMin: number;
	public BodyScaleWeightMax: number;
	public BodyScaleBmiMin: number;
	public BodyScaleBmiMax: number;
}


