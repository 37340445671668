import {ResponseProfile} from './response-profile.model';
import {EnumUserGender} from '../../enums/enum-user-gender.model';


export class ResponseLogin {
	public Id: string;
	public LoginId: string;
	public Email: string;
	public Name: string;
	public DisplayName: string;
	public PasswordChangeDate: Date;
	public PhoneNumber: string;
	public ReceiveSms: boolean;
	public ReceiveEmail: boolean;
	public Roles: string[];
	public LastLoginDateTIme: Date;
	public HasPassword: boolean;
	public Profile: ResponseProfile;
	public ExternalAccountGender: EnumUserGender;
	public ExternalAccountPhotoUrl: string;
}


