


export enum EnumConsultingBoundType {
	InBound = 0,
	OutBound = 1,
}

export namespace EnumConsultingBoundType {

	export function toDisplayName(value: EnumConsultingBoundType) {
		switch (value) {
			case EnumConsultingBoundType.InBound:
				return 'UL_USER_DATA_CONSULTING_BOUND_TYPE_IN';
			case EnumConsultingBoundType.OutBound:
				return 'UL_USER_DATA_CONSULTING_BOUND_TYPE_OUT';
			default:
				return value;
		}
	}
	export function toDisplayDescription(value: EnumConsultingBoundType) {
		switch (value) {
			case EnumConsultingBoundType.InBound:
				return 'UL_USER_DATA_CONSULTING_BOUND_TYPE_IN';
			case EnumConsultingBoundType.OutBound:
				return 'UL_USER_DATA_CONSULTING_BOUND_TYPE_OUT';
			default:
				return value;
		}
	}
	export function toDisplayGroupName(value: EnumConsultingBoundType) {
		return 'UL_USER_DATA_CONSULTING_BOUND_TYPE';
	}
	export function toDisplayShortName(value: EnumConsultingBoundType) {
		switch (value) {
			case EnumConsultingBoundType.InBound:
				return 'UL_USER_DATA_CONSULTING_BOUND_TYPE_IN';
			case EnumConsultingBoundType.OutBound:
				return 'UL_USER_DATA_CONSULTING_BOUND_TYPE_OUT';
			default:
				return value;
		}
	}
	export function toDisplayPrompt(value: EnumConsultingBoundType) {
		switch (value) {
			case EnumConsultingBoundType.InBound:
				return 'UL_USER_DATA_CONSULTING_BOUND_TYPE_IN';
			case EnumConsultingBoundType.OutBound:
				return 'UL_USER_DATA_CONSULTING_BOUND_TYPE_OUT';
			default:
				return value;
		}
	}
}
