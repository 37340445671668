/**
 * 식사 유형
 */
export enum EnumMealType {
	Breakfast,
	Lunch,
	Dinner,
	Snack,
}

export namespace EnumMealType {

	export function toDisplayName(value: EnumMealType) {
		switch (value) {
			case EnumMealType.Breakfast:
				return 'UL_USER_DATA_FOODLENS_MEAL_TYPE_BREAKFAST';
			case EnumMealType.Lunch:
				return 'UL_USER_DATA_FOODLENS_MEAL_TYPE_LUNCH';
			case EnumMealType.Dinner:
				return 'UL_USER_DATA_FOODLENS_MEAL_TYPE_DINNER';
			case EnumMealType.Snack:
				return 'UL_USER_DATA_FOODLENS_MEAL_TYPE_SNACK';
			default:
				return value;
		}
	}
	export function toDisplayDescription(value: EnumMealType) {
		switch (value) {
			case EnumMealType.Breakfast:
				return 'UL_USER_DATA_FOODLENS_MEAL_TYPE_BREAKFAST';
			case EnumMealType.Lunch:
				return 'UL_USER_DATA_FOODLENS_MEAL_TYPE_LUNCH';
			case EnumMealType.Dinner:
				return 'UL_USER_DATA_FOODLENS_MEAL_TYPE_DINNER';
			case EnumMealType.Snack:
				return 'UL_USER_DATA_FOODLENS_MEAL_TYPE_SNACK';
			default:
				return value;
		}
	}
	export function toDisplayGroupName(value: EnumMealType) {
		return 'UL_USER_DATA_FOODLENS_MEAL_TYPE';
	}
	export function toDisplayShortName(value: EnumMealType) {
		switch (value) {
			case EnumMealType.Breakfast:
				return 'UL_USER_DATA_FOODLENS_MEAL_TYPE_BREAKFAST';
			case EnumMealType.Lunch:
				return 'UL_USER_DATA_FOODLENS_MEAL_TYPE_LUNCH';
			case EnumMealType.Dinner:
				return 'UL_USER_DATA_FOODLENS_MEAL_TYPE_DINNER';
			case EnumMealType.Snack:
				return 'UL_USER_DATA_FOODLENS_MEAL_TYPE_SNACK';
			default:
				return value;
		}
	}
	export function toDisplayPrompt(value: EnumMealType) {
		switch (value) {
			case EnumMealType.Breakfast:
				return 'UL_USER_DATA_FOODLENS_MEAL_TYPE_BREAKFAST';
			case EnumMealType.Lunch:
				return 'UL_USER_DATA_FOODLENS_MEAL_TYPE_LUNCH';
			case EnumMealType.Dinner:
				return 'UL_USER_DATA_FOODLENS_MEAL_TYPE_DINNER';
			case EnumMealType.Snack:
				return 'UL_USER_DATA_FOODLENS_MEAL_TYPE_SNACK';
			default:
				return value;
		}
	}
}
