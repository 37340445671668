import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { EnumDropDownList } from '../components/enum-dropdownlist/enum-dropdownlist.component';

// 번역 json 파일 로드
export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
	imports: [
		CommonModule,
		DropDownListModule,
		FormsModule,
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			}
		}),
	],
	declarations: [
		EnumDropDownList
	],
	exports: [
		EnumDropDownList
	]
})
export class EnumDropDownListModule {

}
