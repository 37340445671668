import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {RouterModule} from '@angular/router';

import {HttpServiceInterceptor} from './http-service.interceptor';

// Extension Methods
import {CommonKendoGridService} from './common.grid.service';
import {GridColumnConfigService} from './grid-column-config.service';


@NgModule({
	imports: [
		CommonModule,
		RouterModule,
	],
	exports: [],
	declarations: [],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpServiceInterceptor,
			multi: true
		},
		CommonKendoGridService,
		GridColumnConfigService
	]
})
export class CoreModule {
}
