


export enum EnumDiabeteType {
	Type1,
	Type2,
	Gestational,
}

export namespace EnumDiabeteType {

	export function toDisplayName(value: EnumDiabeteType) {
		switch (value) {
			case EnumDiabeteType.Type1:
				return 'UL_USER_DATA_HEALTH_DIABETE_TYPE_1';
			case EnumDiabeteType.Type2:
				return 'UL_USER_DATA_HEALTH_DIABETE_TYPE_2';
			case EnumDiabeteType.Gestational:
				return 'UL_USER_DATA_HEALTH_DIABETE_TYPE_GESTATIONAL';
			default:
				return value;
		}
	}
	export function toDisplayDescription(value: EnumDiabeteType) {
		switch (value) {
			case EnumDiabeteType.Type1:
				return 'UL_USER_DATA_HEALTH_DIABETE_TYPE_1';
			case EnumDiabeteType.Type2:
				return 'UL_USER_DATA_HEALTH_DIABETE_TYPE_2';
			case EnumDiabeteType.Gestational:
				return 'UL_USER_DATA_HEALTH_DIABETE_TYPE_GESTATIONAL';
			default:
				return value;
		}
	}
	export function toDisplayGroupName(value: EnumDiabeteType) {
		return 'UL_USER_DATA_HEALTH_DIABETE_TYPE';
	}
	export function toDisplayShortName(value: EnumDiabeteType) {
		switch (value) {
			case EnumDiabeteType.Type1:
				return 'UL_USER_DATA_HEALTH_DIABETE_TYPE_1';
			case EnumDiabeteType.Type2:
				return 'UL_USER_DATA_HEALTH_DIABETE_TYPE_2';
			case EnumDiabeteType.Gestational:
				return 'UL_USER_DATA_HEALTH_DIABETE_TYPE_GESTATIONAL';
			default:
				return value;
		}
	}
	export function toDisplayPrompt(value: EnumDiabeteType) {
		switch (value) {
			case EnumDiabeteType.Type1:
				return 'UL_USER_DATA_HEALTH_DIABETE_TYPE_1';
			case EnumDiabeteType.Type2:
				return 'UL_USER_DATA_HEALTH_DIABETE_TYPE_2';
			case EnumDiabeteType.Gestational:
				return 'UL_USER_DATA_HEALTH_DIABETE_TYPE_GESTATIONAL';
			default:
				return value;
		}
	}
}
