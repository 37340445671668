


export class ResponseDataWithCreatedBy {
	public RegDate: Date;
	public RegId: string;
	public RegName: string;

	constructor(RegDate?: Date, RegId?: string, RegName?: string) {
		this.RegDate = RegDate;
		this.RegId = RegId;
		this.RegName = RegName;
	}
}


