export function enumToTextValues(T: any, filter?: (value: number) => boolean): { text: string, value: number }[] {
	const list: { text: string, value: number }[] = [];

	for (const item in T) {
		if (typeof T[item] !== 'number')
			continue;

		const value = Number(T[item]);

		if (filter && !filter(value))
			continue;

		const text = T.hasOwnProperty('toDisplayShortName') ? T.toDisplayShortName(value) : T[value];

		list.push({
			text,
			value
		});
	}

	return list;
}
