import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService } from './auth.service';
import { EnumResponseResult } from '../../_model/enums/enum-response-result.model';
import { ResponseData } from '../../_model/response-data.model';
import {MessageNotificationService} from './notification.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

	constructor(
		private authService: AuthService,
		private router: Router,
		private messageService: MessageNotificationService,
	) {
	}

	async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

		if (next.data && next.data.expectedClaims) {
			const hasClaim = await this.authService.hasClaimInChacheOneOf(next.data.expectedClaims);

			if (!hasClaim) {
				if (next.data.expectedClaims.length === 1 && next.data.expectedClaims[0] === 'cssp.dashboard.all') {
					this.router.navigate(['/dashboard/blank']);
				}
				else
					this.router.navigate(['/account/login']);
			}
			return hasClaim;
		} else {
			const checkLoginResponse: ResponseData = await this.authService.checkLogin().toPromise();
			if (checkLoginResponse.Result === EnumResponseResult.Error) {
				this.messageService.error('[' + checkLoginResponse.Code + '] ' + checkLoginResponse.Message);
				this.router.navigate(['/account/login']);
			}
			else
				return true;
		}
	}
}
