import {RequestUserCommonData} from './request-user-common-data.model';
import {EnumDataInputMethod} from '../../enums/enum-data-input-method.model';

/**
 * 사용자 활동계 심박 데이터 요청 클래스
 */
export class RequestPedometerHeartRateData extends RequestUserCommonData {
	/**
	 * 심박수
	 */
	public Rate: number;
	/**
	 * 데이터 입력 방법
	 */
	public InputMethod: EnumDataInputMethod = EnumDataInputMethod.Device;
}


