import {Directive, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';

@Directive({
	selector: '[appDisable]'
})
export class DisableAnchorDirective implements OnChanges {

	@Input('appDisable') disabled: boolean;
	@Input() href: string;
	@HostBinding('attr.href') hrefAttr: any;
	@HostBinding('attr.aria-disabled') ariaDisabled: boolean;

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		this.setHref();
		this.setDisabled();
	}

	setHref() {

		this.hrefAttr = this.disabled
			? null // this.sn.bypassSecurityTrustUrl('javascript:void(0)')
			: this.href;

	}

	setDisabled() {

		this.ariaDisabled = this.disabled;

	}
}
