import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {ResponseList} from '../../../_model/response-list.model';
import {catchError, map} from 'rxjs/operators';
import {ResponseData} from '../../../_model/response-data.model';
import {ResponseUserWithRoles} from '../../../_model/response/account/response-user-with-roles.model';
import {RequestUserRegist} from '../../../_model/request/account/request-user-regist.model';
import {RequestUserUpdate} from '../../../_model/request/account/request-user-update.model';
import {RequestUpdateProfile} from '../../../_model/request/account/request-update-profile.model';
import {ResponseProfile} from '../../../_model/response/account/response-profile.model';
import {RequestFcmToken} from '../../../_model/request/common/request-fcm-token.model';
import {RequestUserChangePassword} from '../../../_model/request/account/request-user-change-password.model';

/**
 * 사용자 데이터 프로바이더
 */
@Injectable()
export class UserProvider {
	/**
	 * API URL
	 */
	private API_URL = `${environment.apiUrl}/Users`;

	/**
	 * 생성자
	 */
	constructor(
		private httpClient: HttpClient
	) {
	}

	/**
	 * 사용자 목록을 가져온다.
	 */
	getList(param: any = null): Observable<ResponseList<ResponseUserWithRoles>> {
		return this.httpClient.get<ResponseList<ResponseUserWithRoles>>(`${this.API_URL}`, { params: param })
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 사용자 정보를 가져온다.
	 * @param id 사용자 아이디
	 */
	get(id: string): Observable<ResponseData<ResponseUserWithRoles>> {
		return this.httpClient.get<ResponseData<ResponseUserWithRoles>>(`${this.API_URL}/${id}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 사용자 부가정보를 가져온다.
	 * @param id 사용자 아이디
	 */
	getProfile(id: string): Observable<ResponseData<ResponseProfile>> {
		return this.httpClient.get<ResponseData<ResponseProfile>>(`${this.API_URL}/${id}/Profile`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 핸드폰 번호 중복 검사
	 * @param value 핸드폰 번호
	 */
	checkDuplicatedPhoneNumber(value: string): Observable<ResponseData> {
		return this.httpClient.get<ResponseData>(`${this.API_URL}/Unique/Phone/${value}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 이메일 중복 검사
	 * @param value 이메일
	 */
	checkDuplicatedEmail(value: string): Observable<ResponseData> {
		return this.httpClient.get<ResponseData>(`${this.API_URL}/Unique/Email/${value}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 사용자 정보를 등록한다.
	 * @param request 등록 정보 객체
	 */
	add(request: RequestUserRegist): Observable<ResponseData<ResponseUserWithRoles>> {
		return this.httpClient.post<ResponseData<ResponseUserWithRoles>>(`${this.API_URL}`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 사용자 정보를 수정한다.
	 * @param id 사용자 아이디
	 * @param request 수정 정보 객체
	 */
	update(id: string, request: RequestUserUpdate): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URL}/${id}`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 사용자 부가정보를 수정한다.
	 * @param id 사용자 아이디
	 * @param request 수정 정보 객체
	 */
	updateProfile(id: string, request: RequestUpdateProfile): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URL}/${id}/Profile`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 사용자 비밀번호를 수정한다.
	 * @param id 사용자 아이디
	 * @param request 비밀번호 수정 정보 객체
	 */
	changePassword(id: string, request: RequestUserChangePassword): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URL}/${id}/ChangePassword`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 사용자 정보를 삭제한다.
	 * @param id 역할 아이디
	 */
	remove(id: string): Observable<ResponseData> {
		return this.httpClient.delete<ResponseData>(`${this.API_URL}/${id}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 사용자 토큰 정보를 등록한다.
	 * @param userId 사용자 아이디
	 * @param request 등록 정보 객체
	 */
	addToken(userId: string, request: RequestFcmToken): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.API_URL}/${userId}/FcmTokens`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자 모든 토큰 정보를 삭제한다.
	 * @param userId 사용자 아이디
	 */
	removeAllTokens(userId: string): Observable<ResponseData> {
		return this.httpClient.delete<ResponseData>(`${this.API_URL}/${userId}/FcmTokens`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자 토큰 정보를 삭제한다.
	 * @param userId 사용자 아이디
	 * @param tokenId 토큰 아이디
	 */
	removeToken(userId: string, tokenId: string): Observable<ResponseData> {
		return this.httpClient.delete<ResponseData>(`${this.API_URL}/${userId}/${tokenId}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

}
