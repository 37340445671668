import {Component, OnInit} from '@angular/core';


@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

	// 통신중 플래그
	public inCommunication: boolean = false;

	constructor(
	) {
	}

	/**
	 * 초기화 이벤트
	 */
	ngOnInit() {

	}
}
