/**
 * 사용자 공통 데이터 요청 클래스
 */
export class RequestUserCommonData {
	/**
	 * 등록일시
	 */
	public RegDate: Date;
	/**
	 * 삭제여부
	 */
	public IsDeleted: boolean = false;
}


