import {ResponseCommonData} from './response-common-data.model';

/**
 * 사용자 활동계 활동 요약 데이터 응답 클래스
 */
export class ResponsePedometerActivitySummary extends ResponseCommonData {
	/**
	 * 칼로리
	 */
	public Calories: number;

	/**
	 * 거리
	 */
	public Distance: number;

	/**
	 * 운동시간
	 */
	public Duration: number;

	/**
	 * 걸음수
	 */
	public Step: number;

	/**
	 * 생성자
	 * @param regDate 데이터일자
	 * @param step 걸음수
	 * @param distance 거리
	 * @param calories 칼로리
	 * @param duration 운동시간
	 */
	constructor(regDate?: Date, step?: number, distance?: number, calories?: number, duration?: number) {
		super();

		if(regDate)
			this.RegDate = regDate;
		if(step)
			this.Step = step;
		if(distance)
			this.Distance = distance;
		if(calories)
			this.Calories = calories;
		if(duration)
			this.Duration = duration;
	}
}


