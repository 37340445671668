


export enum EnumConsultingEventType {
	App,
	Health,
	Nutrition,
	PushMessage,
	Etc,
}

export namespace EnumConsultingEventType {

	export function toDisplayName(value: EnumConsultingEventType) {
		switch (value) {
			case EnumConsultingEventType.App:
				return 'UL_USER_DATA_CONSULTING_EVENT_TYPE_APP';
			case EnumConsultingEventType.Health:
				return 'UL_USER_DATA_CONSULTING_EVENT_TYPE_HEALTH';
			case EnumConsultingEventType.Nutrition:
				return 'UL_USER_DATA_CONSULTING_EVENT_TYPE_NUTRITION';
			case EnumConsultingEventType.PushMessage:
				return 'UL_USER_DATA_CONSULTING_EVENT_TYPE_PUSH_MESSAGE';
			case EnumConsultingEventType.Etc:
				return 'UL_USER_DATA_CONSULTING_EVENT_TYPE_ETC';
			default:
				return value;
		}
	}
	export function toDisplayDescription(value: EnumConsultingEventType) {
		switch (value) {
			case EnumConsultingEventType.App:
				return 'UL_USER_DATA_CONSULTING_EVENT_TYPE_APP';
			case EnumConsultingEventType.Health:
				return 'UL_USER_DATA_CONSULTING_EVENT_TYPE_HEALTH';
			case EnumConsultingEventType.Nutrition:
				return 'UL_USER_DATA_CONSULTING_EVENT_TYPE_NUTRITION';
			case EnumConsultingEventType.PushMessage:
				return 'UL_USER_DATA_CONSULTING_EVENT_TYPE_PUSH_MESSAGE';
			case EnumConsultingEventType.Etc:
				return 'UL_USER_DATA_CONSULTING_EVENT_TYPE_ETC';
			default:
				return value;
		}
	}
	export function toDisplayGroupName(value: EnumConsultingEventType) {
		return 'UL_USER_DATA_CONSULTING_EVENT_TYPE';
	}
	export function toDisplayShortName(value: EnumConsultingEventType) {
		switch (value) {
			case EnumConsultingEventType.App:
				return 'UL_USER_DATA_CONSULTING_EVENT_TYPE_APP';
			case EnumConsultingEventType.Health:
				return 'UL_USER_DATA_CONSULTING_EVENT_TYPE_HEALTH';
			case EnumConsultingEventType.Nutrition:
				return 'UL_USER_DATA_CONSULTING_EVENT_TYPE_NUTRITION';
			case EnumConsultingEventType.PushMessage:
				return 'UL_USER_DATA_CONSULTING_EVENT_TYPE_PUSH_MESSAGE';
			case EnumConsultingEventType.Etc:
				return 'UL_USER_DATA_CONSULTING_EVENT_TYPE_ETC';
			default:
				return value;
		}
	}
	export function toDisplayPrompt(value: EnumConsultingEventType) {
		switch (value) {
			case EnumConsultingEventType.App:
				return 'UL_USER_DATA_CONSULTING_EVENT_TYPE_APP';
			case EnumConsultingEventType.Health:
				return 'UL_USER_DATA_CONSULTING_EVENT_TYPE_HEALTH';
			case EnumConsultingEventType.Nutrition:
				return 'UL_USER_DATA_CONSULTING_EVENT_TYPE_NUTRITION';
			case EnumConsultingEventType.PushMessage:
				return 'UL_USER_DATA_CONSULTING_EVENT_TYPE_PUSH_MESSAGE';
			case EnumConsultingEventType.Etc:
				return 'UL_USER_DATA_CONSULTING_EVENT_TYPE_ETC';
			default:
				return value;
		}
	}
}
