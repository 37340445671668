import {DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {Injectable, TemplateRef, ViewContainerRef} from '@angular/core';
import {Observable} from 'rxjs';
import {DialogResult} from '@progress/kendo-angular-dialog/dist/es2015/dialog/dialog-settings';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class CommonDialogService {

	/**
	 * 다이얼로그 서비스
	 */
	private _dialogService: DialogService = null;

	constructor(
		dialogService: DialogService,
		private translateService: TranslateService,
	) {
		this._dialogService = dialogService;
	}

	/**
	 * 확인 다이얼로그를 표시한다.
	 * @param title 다이얼로그 제목
	 * @param content 내용
	 * @param width 다이얼로그 넓이 (0인 경우, 지정안함)
	 * @param height 다이얼로그 높이 (0인 경우, 지정안함)
	 * @param container 표시할 컨테이너
	 */
	confirm(title: string, content: string, width: number = 0, height: number = 0, container: ViewContainerRef = null): Observable<DialogResult> {

		const yes: string = this.translateService.instant('UL_COMMON__YES');
		const no: string = this.translateService.instant('UL_COMMON__NO');

		const dialog: DialogRef = this.create(title, content
			,                                    [
				{ text: yes, primary: true },
				{ text: no }
			]
			,                                    width, height
			,                                    container
		);

		return dialog.result;
	}

	/**
	 * 확인 다이얼로그를 표시한다.
	 * @param title 다이얼로그 제목
	 * @param content 내용 템플릿
	 * @param width 다이얼로그 넓이 (0인 경우, 지정안함)
	 * @param height 다이얼로그 높이 (0인 경우, 지정안함)
	 * @param container 표시할 컨테이너
	 */
	confirmWithTemplate(title: string, content: TemplateRef<any>, width: number = 0, height: number = 0, container: ViewContainerRef = null): Observable<DialogResult> {

		const yes: string = this.translateService.instant('UL_COMMON__YES');
		const no: string = this.translateService.instant('UL_COMMON__NO');

		const dialog: DialogRef = this.create(title, content
			,                                    [
				{ text: yes, primary: true },
				{ text: no }
			]
			,                                    width, height
			,                                    container
		);

		return dialog.result;
	}

	/**
	 * 안내 다이얼로그를 표시한다.
	 * @param title 다이얼로그 제목
	 * @param content 내용
	 * @param width 다이얼로그 넓이 (0인 경우, 지정안함)
	 * @param height 다이얼로그 높이 (0인 경우, 지정안함)
	 * @param container 표시할 컨테이너
	 */
	information(title: string, content: string, width: number = 0, height: number = 0, container: ViewContainerRef = null): Observable<DialogResult> {
		const dialog: DialogRef = this.create(title, content
			,                                    [
				{ text: '예', primary: true }
			]
			,                                    width, height
			,                                    container
		);

		return dialog.result;
	}

	/**
	 * 안내 다이얼로그를 표시한다.
	 * @param title 다이얼로그 제목
	 * @param content 내용 템플릿
	 * @param width 다이얼로그 넓이 (0인 경우, 지정안함)
	 * @param height 다이얼로그 높이 (0인 경우, 지정안함)
	 * @param container 표시할 컨테이너
	 */
	informationWithTemplate(title: string, content: TemplateRef<any>, width: number = 0, height: number = 0, container: ViewContainerRef = null): Observable<DialogResult> {

		const ok: string = this.translateService.instant('UL_COMMON__OK');

		const dialog: DialogRef = this.create(title, content
			,                                    [
				{ text: ok, primary: true }
			]
			,                                    width, height
			,                                    container
		);

		return dialog.result;
	}

	/**
	 * 템플릿으로 다이얼로그를 표시한다.
	 * @param title 다이얼로그 제목
	 * @param content 내용 템플릿
	 * @param actionButtons 버튼 목록
	 * @param width 다이얼로그 넓이 (0인 경우, 지정안함)
	 * @param height 다이얼로그 높이 (0인 경우, 지정안함)
	 * @param container 표시할 컨테이너
	 */
	show(title: string, content: TemplateRef<any>, actionButtons: any[] | TemplateRef<any>, width: number = 0, height: number = 0, container: ViewContainerRef = null): Observable<DialogResult> {
		const dialog: DialogRef = this.create(title, content
			,                                    actionButtons
			,                                    width, height
			,                                    container
		);

		return dialog.result;
	}

	/**
	 * 다이얼로그를 생성한다.
	 * @param title 다이얼로그 제목
	 * @param content 내용
	 * @param actionButtons 버튼 목록
	 * @param width 다이얼로그 넓이 (0인 경우, 지정안함)
	 * @param height 다이얼로그 높이 (0인 경우, 지정안함)
	 * @param container 표시할 컨테이너
	 */
	create(title: string, content: string | TemplateRef<any>, actionButtons: any[] | TemplateRef<any>, width: number = 0, height: number = 0, container: ViewContainerRef = null): DialogRef {

		const dialog: DialogRef = this._dialogService.open({
			title,
			content,
			actions: actionButtons,
			width,
			height,
			minWidth: width,
			appendTo: container
		});

		return dialog;
	}
}
