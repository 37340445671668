import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';

@Injectable()
export class PagesToggleService {
	// Sidebar Toggle - Mobile
	private _sideBarToggle = new Subject() as Subject<boolean>;
	sideBarToggle = this._sideBarToggle.asObservable();

	// Secondary Sidebar Toggle - Mobile
	private _secondarySideBarToggle = new Subject() as Subject<any>;
	secondarySideBarToggle = this._secondarySideBarToggle.asObservable();

	// Horizontal Menu Toggle - Mobile
	private _mobileHorizontaMenu = new Subject() as Subject<boolean>;
	mobileHorizontaMenu = this._mobileHorizontaMenu.asObservable();

	// Menu Pin Toggle
	private _menuPinToggle = new Subject();
	menuPinToggle = this._menuPinToggle.asObservable();

	// Menu Pin Toggle
	private _menuDrawer = new Subject() as Subject<string>;
	menuDrawer = this._menuDrawer.asObservable();

	// Page Wrapper Class
	private _pageContainerClass = new Subject() as Subject<string>;
	pageContainerClass = this._pageContainerClass.asObservable();

	// Page Content Class
	private _contentClass = new Subject() as Subject<string>;
	contentClass = this._contentClass.asObservable();

	// Header Class
	private _headerClass = new Subject() as Subject<string>;
	headerClass = this._headerClass.asObservable();

	// Body Layout Class
	private _bodyLayoutClass = new Subject() as Subject<string>;
	bodyLayoutClass = this._bodyLayoutClass.asObservable();

	// App Layout
	private _layout = new Subject() as Subject<string>;
	Applayout = this._layout.asObservable();

	// Footer Visiblity
	private _footer = new Subject() as Subject<boolean>;
	Footer = this._footer.asObservable();

	// Page Container Hover Event - Used for sidebar
	private _pageContainerHover = new Subject() as Subject<boolean>;
	pageContainerHover = this._pageContainerHover.asObservable();

	setContent(className: string) {
		this._contentClass.next(className);
	}

	setPageContainer(className: string) {
		this._pageContainerClass.next(className);
	}

	setHeaderClass(className: string) {
		this._headerClass.next(className);
	}

	setBodyLayoutClass(className: string) {
		this._bodyLayoutClass.next(className);
	}

	removeBodyLayoutClass(className: string) {
		this._bodyLayoutClass.next(className);
	}

	changeLayout(className: string) {
		this._layout.next(className);
	}

	toggleMenuPin(toggle: boolean) {
		this._menuPinToggle.next({text: toggle});
	}

	toggleMenuDrawer() {
		this._menuDrawer.next();
	}

	toggleMobileSideBar(toggle: boolean) {
		this._sideBarToggle.next(toggle);
	}

	toggleSecondarySideBar(toggle: boolean) {
		this._secondarySideBarToggle.next(toggle);
	}

	toggleMobileHorizontalMenu(toggle: boolean) {
		this._mobileHorizontaMenu.next(toggle);
	}

	toggleFooter(toggle: boolean) {
		this._footer.next(toggle);
	}

	triggerPageContainerHover(toggle: boolean) {
		this._pageContainerHover.next(toggle);
	}

}
