import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {ResponseList} from '../../../_model/response-list.model';
import {catchError, map} from 'rxjs/operators';
import {ResponseData} from '../../../_model/response-data.model';
import {ResponseUserActionLog} from '../../../_model/response/log/response-user-action-log.model';
import {RequestUserActionLog} from '../../../_model/request/log/request-system-log.model';

/**
 * 사용자 로그 데이터 프로바이더
 */
@Injectable()
export class UserActionLogProvider {
	/**
	 * API URL
	 */
	private API_URL = `${environment.apiUrl}/UserActionLogs`;

	/**
	 * 생성자
	 */
	constructor(
		private httpClient: HttpClient
	) {
	}

	/**
	 * 로그 목록을 가져온다.
	 */
	getList(param: any = null): Observable<ResponseList<ResponseUserActionLog>> {
		return this.httpClient.get<ResponseList<ResponseUserActionLog>>(`${this.API_URL}`, { params: param })
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 로그 정보를 등록한다.
	 * @param request 등록 정보 객체
	 */
	add(request: RequestUserActionLog): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.API_URL}`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

}
