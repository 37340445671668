import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BreadcrumbPath } from '../classes/breadcrumb-path';

@Injectable()
export class BreadcrumbService {
	constructor() { }

	private emitChangeSource = new Subject<BreadcrumbPath[]>();

	pathChanged$ = this.emitChangeSource.asObservable();

	changePath(paths: BreadcrumbPath[]) {
		this.emitChangeSource.next(paths);
	}
}
