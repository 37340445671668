import {ResponseCommonData} from './response-common-data.model';
import {EnumDataInputMethod} from '../../enums/enum-data-input-method.model';

/**
 * 사용자 활동계 수면 데이터 응답 클래스
 */
export class ResponsePedometerSleepData extends ResponseCommonData {
	/**
	 * 수면 인덱스
	 */
	public SleepIndex: number;
	/**
	 * 데이터 입력 방법
	 */
	public InputMethod: EnumDataInputMethod;
}


