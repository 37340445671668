import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ResponseData} from '../../../_model/response-data.model';
import {ResponseList} from '../../../_model/response-list.model';
import {ResponseUserHealth} from '../../../_model/response/data/response-user-health.model';
import {ResponseUserHealthEx} from '../../../_model/response/data/response-user-health-ex.model';
import {RequestPatientHealth} from '../../../_model/request/patient/request-patient-health.model';

/**
 * 사용자 건강 정보 데이터 프로바이더
 */
@Injectable()
export class UserHealthProvider {
	/**
	 * API URL
	 */
	private API_ACCOUNT_URL = `${environment.apiUrl}/Account`;
	/**
	 * API URL
	 */
	private API_URL = `${environment.apiUrl}/UserDeviceDataManagements`;

	/**
	 * 생성자
	 */
	constructor(
		private httpClient: HttpClient
	) {
	}

	/**
	 * 로그인한 사용자의 건강 데이터를 수정한다.
	 * @param request 건강 정보
	 */
	updateMyHealth(request: RequestPatientHealth): Observable<ResponseData<ResponseUserHealth>> {
		return this.httpClient.put<ResponseData<ResponseUserHealth>>(`${this.API_ACCOUNT_URL}/Health`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 로그인한 사용자의 건강 데이터를 가져온다.
	 */
	getMyHealth(): Observable<ResponseData<ResponseUserHealth>> {
		return this.httpClient.get<ResponseData<ResponseUserHealth>>(`${this.API_ACCOUNT_URL}/Health`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 전체 사용자의 건강 데이터를 가져온다.
	 * @param param 검색 파라미터
	 */
	getList(param: any = null): Observable<ResponseList<ResponseUserHealthEx>> {
		return this.httpClient.get<ResponseList<ResponseUserHealthEx>>(`${this.API_URL}/HealthDatas`, { params: param })
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 건강 정보를 가져온다.
	 * @param id 사용자 식별자
	 */
	get(id: string): Observable<ResponseData<ResponseUserHealth>> {
		return this.httpClient.get<ResponseData<ResponseUserHealth>>(`${this.API_URL}/HealthDatas/${id}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 건강 기본값 정보를 저장한다.
	 * @param id 사용자 식별자
	 */
	updateDefault(id: string): Observable<ResponseData<ResponseUserHealth>> {
		return this.httpClient.put<ResponseData<ResponseUserHealth>>(`${this.API_URL}/HealthDatas/${id}/Default`, null)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 건강 정보를 수정한다.
	 * @param id 사용자 식별자
	 * @param request 건강 정보
	 */
	update(id: string, request: RequestPatientHealth): Observable<ResponseData<ResponseUserHealth>> {
		return this.httpClient.put<ResponseData<ResponseUserHealth>>(`${this.API_URL}/HealthDatas/${id}`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

}
