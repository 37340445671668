import {EnumConsultingBoundType} from '../../enums/enum-consulting-bound-type.model';
import {EnumConsultingEventType} from '../../enums/enum-consulting-event-type.model';


export class RequestAgencyConsulting {
	public BoundType: EnumConsultingBoundType;
	public EventType: EnumConsultingEventType;
	public StartTime: Date;
	public EndTime: Date;
	public Title: string;
	public Description: string;
}


