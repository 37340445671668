export class RequestPatientInspect {
	public Hemoglobin: number;
	public Ast: number;
	public Alt: number;
	public Ggt: number;
	public Bun: number;
	public Creainine: number;
	public Ldl: number;
	public EstimatedGfr: number;
	public UricAcid: number;
	public GlucoseFasting: number;
	public HbA1c: number;
	public Glycoalbumin: number;
	public ACUrine: number;
	public Cholesterol: number;
	public Triglyceridel: number;
	public Hdl: number;
	public AgencyId: string = null;
}


