import {RequestUserCommonData} from './request-user-common-data.model';
import {EnumDataInputMethod} from '../../enums/enum-data-input-method.model';

/**
 * 사용자 활동계 수면 데이터 요청 클래스
 */
export class RequestPedometerSleepData extends RequestUserCommonData {
	/**
	 * 수면 인덱스
	 */
	public SleepIndex: number;
	/**
	 * 데이터 입력 방법
	 */
	public InputMethod: EnumDataInputMethod = EnumDataInputMethod.Device;
}


