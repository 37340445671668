
import { CommonRequestData } from 'src/app/_model/commondata/common-request-data.model';
import {EnumUserGender} from '../../enums/enum-user-gender.model';

export class RequestUpdateProfile extends CommonRequestData {
	public PhotoUrl: string;
	public Gender: EnumUserGender;
	public BirthDate: Date;
	public Height: number;
	public Weight: number;
	public AgencyIds: string[];
}


