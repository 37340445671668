import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ResponseData} from '../../../_model/response-data.model';
import {ResponseList} from '../../../_model/response-list.model';
import {ResponseBloodPressureData} from '../../../_model/response/data/response-blood-pressure-data.model';
import {RequestBloodPressureData} from '../../../_model/request/data/request-blood-pressure-data.model';
import {RequestBodyScaleData} from '../../../_model/request/data/request-body-scale-data.model';
import {ResponseBodyScaleData} from '../../../_model/response/data/response-body-scale-data.model';
import {RequestFoodLensData} from '../../../_model/request/data/request-food-lens-data.model';
import {ResponseFoodLensData} from '../../../_model/response/data/response-food-lens-data.model';
import {RequestGlucosemeterData} from '../../../_model/request/data/request-glucosemeter-data.model';
import {ResponseGlucosemeterData} from '../../../_model/response/data/response-glucosemeter-data.model';
import {RequestHba1cData} from '../../../_model/request/data/request-hba1c-data.model';
import {ResponseHba1cData} from '../../../_model/response/data/response-hba1c-data.model';
import {RequestPedometerActivityData} from '../../../_model/request/data/request-pedometer-activity-data.model';
import {ResponsePedometerActivityData} from '../../../_model/response/data/response-pedometer-activity-data.model';
import {RequestPedometerHeartRateData} from '../../../_model/request/data/request-pedometer-heartrate-data.model';
import {ResponsePedometerHeartRateData} from '../../../_model/response/data/response-pedometer-heartrate-data.model';
import {RequestPedometerSleepData} from '../../../_model/request/data/request-pedometer-sleep-data.model';
import {ResponsePedometerSleepData} from '../../../_model/response/data/response-pedometer-sleep-data.model';
import {RequestPedometerActivitySummary} from '../../../_model/request/data/request-pedometer-activity-summary.model';
import {ResponsePedometerActivitySummary} from '../../../_model/response/data/response-pedometer-activity-summary.model';
import {RequestPedometerSleepSummary} from '../../../_model/request/data/request-pedometer-sleep-summary.model';
import {ResponsePedometerSleepSummary} from '../../../_model/response/data/response-pedometer-sleep-summary.model';
import * as moment from 'moment';
import {ResponsePedometerHeartRateSummary} from '../../../_model/response/data/response-pedometer-heartrate-summary.model';

/**
 * 사용자 조절목표 설정 정보 데이터 프로바이더
 */
@Injectable()
export class UserDeviceDataProvider {
	/**
	 * API URL
	 */
	private API_URL = `${environment.apiUrl}/UserDeviceDataManagements`;

	/**
	 * 생성자
	 */
	constructor(
		private httpClient: HttpClient
	) {
	}

	/**
	 * 특정 사용자의 혈압 데이터를 등록한다.
	 * @param userId 사용자 아이디
	 * @param requests 혈압 데이터 정보
	 */
	addBloodPressureData(userId: string, requests: RequestBloodPressureData[]): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.API_URL}/BloodPressureDatas/${userId}`, requests)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 혈압 데이터를 수정한다.
	 * @param userId 사용자 아이디
	 * @param dataId 데이터 아이디
	 * @param request 혈압 데이터 정보
	 */
	updateBloodPressureData(userId: string, dataId: string, request: RequestBloodPressureData): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URL}/BloodPressureDatas/${userId}/${dataId}`, request)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 특정 혈압 데이터를 삭제한다.
	 * @param userId 사용자 아이디
	 * @param dataId 데이터 아이디
	 */
	removeBloodPressureData(userId: string, dataId: string): Observable<ResponseData> {

		return this.httpClient.delete<ResponseData>(`${this.API_URL}/BloodPressureDatas/${userId}/${dataId}`)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 혈압 데이터를 삭제한다.
	 * @param userId 사용자 아이디
	 * @param searchStartDate 검색 시작일자
	 * @param searchEndDate 검색 종료일자
	 */
	removeBloodPressureDatas(userId: string, searchStartDate?: Date, searchEndDate?: Date): Observable<ResponseData> {

		const startDate: string = moment(searchStartDate).format('YYYY-MM-DD HH:mm:ss');
		const endDate: string = moment(searchEndDate).format('YYYY-MM-DD HH:mm:ss');

		return this.httpClient.delete<ResponseData>(`${this.API_URL}/BloodPressureDatas/${userId}?searchStartDate=${startDate}&searchEndDate=${endDate}`)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 혈압 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param dataId 사용자 아이디
	 */
	getBloodPressureData(userId: string, dataId: string): Observable<ResponseData<ResponseBloodPressureData>> {
		return this.httpClient.get<ResponseData<ResponseBloodPressureData>>(`${this.API_URL}/BloodPressureDatas/${userId}/${dataId}`)
			.pipe(
				map((result) => {

					if(result.Data)
						result.Data.RegDate = moment(result.Data.RegDate).toDate();

					const newData: ResponseData<ResponseBloodPressureData> = Object.assign(new ResponseData<ResponseBloodPressureData>(), result);
					newData.Data = Object.assign(new ResponseBloodPressureData(), result.Data);
					return newData;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 혈압 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param param 검색 조건 파라미터
	 */
	getUserBloodPressureDatas(userId: string, param: any): Observable<ResponseList<ResponseBloodPressureData>> {
		return this.httpClient.get<ResponseList<ResponseBloodPressureData>>(`${this.API_URL}/BloodPressureDatas/${userId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponseBloodPressureData[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();

							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponseBloodPressureData(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponseBloodPressureData> = new ResponseList<ResponseBloodPressureData>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 전체 사용자의 혈압 데이터를 가져온다.
	 * @param agencyId 기관 아이디
	 * @param param 검색 조건 파라미터
	 */
	getBloodPressureDatas(agencyId: string, param: any): Observable<ResponseList<ResponseBloodPressureData>> {
		return this.httpClient.get<ResponseList<ResponseBloodPressureData>>(`${this.API_URL}/BloodPressureDatas/Agencies/${agencyId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponseBloodPressureData[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();

							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponseBloodPressureData(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponseBloodPressureData> = new ResponseList<ResponseBloodPressureData>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 체성분계 데이터를 등록한다.
	 * @param userId 사용자 아이디
	 * @param requests 체성분계 데이터 정보
	 */
	addBodyScaleData(userId: string, requests: RequestBodyScaleData[]): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.API_URL}/BodyScaleDatas/${userId}`, requests)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 체성분계 데이터를 수정한다.
	 * @param userId 사용자 아이디
	 * @param dataId 데이터 아이디
	 * @param request 체성분계 데이터 정보
	 */
	updateBodyScaleData(userId: string, dataId: string, request: RequestBodyScaleData): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URL}/BodyScaleDatas/${userId}/${dataId}`, request)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 특정 체성분계 데이터를 삭제한다.
	 * @param userId 사용자 아이디
	 * @param dataId 데이터 아이디
	 */
	removeBodyScaleData(userId: string, dataId: string): Observable<ResponseData> {

		return this.httpClient.delete<ResponseData>(`${this.API_URL}/BodyScaleDatas/${userId}/${dataId}`)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 체성분계 데이터를 삭제한다.
	 * @param userId 사용자 아이디
	 * @param searchStartDate 검색 시작일자
	 * @param searchEndDate 검색 종료일자
	 */
	removeBodyScaleDatas(userId: string, searchStartDate?: Date, searchEndDate?: Date): Observable<ResponseData> {

		const startDate: string = moment(searchStartDate).format('YYYY-MM-DD HH:mm:ss');
		const endDate: string = moment(searchEndDate).format('YYYY-MM-DD HH:mm:ss');

		return this.httpClient.delete<ResponseData>(`${this.API_URL}/BodyScaleDatas/${userId}?searchStartDate=${startDate}&searchEndDate=${endDate}`)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 체성분계 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param dataId 사용자 아이디
	 */
	getBodyScaleData(userId: string, dataId: string): Observable<ResponseData<ResponseBodyScaleData>> {
		return this.httpClient.get<ResponseData<ResponseBodyScaleData>>(`${this.API_URL}/BodyScaleDatas/${userId}/${dataId}`)
			.pipe(
				map((result) => {
					if(result.Data)
						result.Data.RegDate = moment(result.Data.RegDate).toDate();
					const newData: ResponseData<ResponseBodyScaleData> = Object.assign(new ResponseData<ResponseBodyScaleData>(), result);
					newData.Data = Object.assign(new ResponseBodyScaleData(), result.Data);
					return newData;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 체성분계 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param param 검색 조건 파라미터
	 */
	getUserBodyScaleDatas(userId: string, param: any): Observable<ResponseList<ResponseBodyScaleData>> {
		return this.httpClient.get<ResponseList<ResponseBodyScaleData>>(`${this.API_URL}/BodyScaleDatas/${userId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponseBodyScaleData[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponseBodyScaleData(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponseBodyScaleData> = new ResponseList<ResponseBodyScaleData>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 전체 사용자의 체성분계 데이터를 가져온다.
	 * @param agencyId 기관 아이디
	 * @param param 검색 조건 파라미터
	 */
	getBodyScaleDatas(agencyId: string, param: any): Observable<ResponseList<ResponseBodyScaleData>> {
		return this.httpClient.get<ResponseList<ResponseBodyScaleData>>(`${this.API_URL}/BodyScaleDatas/Agencies/${agencyId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponseBodyScaleData[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponseBodyScaleData(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponseBodyScaleData> = new ResponseList<ResponseBodyScaleData>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 식단 데이터를 등록한다.
	 * @param userId 사용자 아이디
	 * @param requests 식단 데이터 정보
	 */
	addFoodLensData(userId: string, requests: RequestFoodLensData[]): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.API_URL}/FoodLensDatas/${userId}`, requests)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 특정 식단 데이터를 삭제한다.
	 * @param userId 사용자 아이디
	 * @param dataId 데이터 아이디
	 */
	removeFoodLensData(userId: string, dataId: string): Observable<ResponseData> {

		return this.httpClient.delete<ResponseData>(`${this.API_URL}/FoodLensDatas/${userId}/${dataId}`)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 식단 데이터를 삭제한다.
	 * @param userId 사용자 아이디
	 * @param searchStartDate 검색 시작일자
	 * @param searchEndDate 검색 종료일자
	 */
	removeFoodLensDatas(userId: string, searchStartDate?: Date, searchEndDate?: Date): Observable<ResponseData> {

		const startDate: string = moment(searchStartDate).format('YYYY-MM-DD HH:mm:ss');
		const endDate: string = moment(searchEndDate).format('YYYY-MM-DD HH:mm:ss');

		return this.httpClient.delete<ResponseData>(`${this.API_URL}/FoodLensDatas/${userId}?searchStartDate=${startDate}&searchEndDate=${endDate}`)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 식단 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param dataId 사용자 아이디
	 */
	getFoodLensData(userId: string, dataId: string): Observable<ResponseData<ResponseFoodLensData>> {
		return this.httpClient.get<ResponseData<ResponseFoodLensData>>(`${this.API_URL}/FoodLensDatas/${userId}/${dataId}`)
			.pipe(
				map((result) => {
					if(result.Data)
						result.Data.RegDate = moment(result.Data.RegDate).toDate();
					const newData: ResponseData<ResponseFoodLensData> = Object.assign(new ResponseData<ResponseFoodLensData>(), result);
					newData.Data = Object.assign(new ResponseFoodLensData(), result.Data);
					return newData;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 식단 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param param 검색 조건 파라미터
	 */
	getUserFoodLensDatas(userId: string, param: any): Observable<ResponseList<ResponseFoodLensData>> {
		return this.httpClient.get<ResponseList<ResponseFoodLensData>>(`${this.API_URL}/FoodLensDatas/${userId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponseFoodLensData[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponseFoodLensData(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponseFoodLensData> = new ResponseList<ResponseFoodLensData>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 전체 사용자의 식단 데이터를 가져온다.
	 * @param agencyId 기관 아이디
	 * @param param 검색 조건 파라미터
	 */
	getFoodLensDatas(agencyId: string, param: any): Observable<ResponseList<ResponseFoodLensData>> {
		return this.httpClient.get<ResponseList<ResponseFoodLensData>>(`${this.API_URL}/FoodLensDatas/Agencies/${agencyId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponseFoodLensData[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponseFoodLensData(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponseFoodLensData> = new ResponseList<ResponseFoodLensData>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 혈당계 데이터를 등록한다.
	 * @param userId 사용자 아이디
	 * @param requests 혈당계 데이터 정보
	 */
	addGlucosemeterData(userId: string, requests: RequestGlucosemeterData[]): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.API_URL}/GlucosemeterDatas/${userId}`, requests)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 혈당계 데이터를 수정한다.
	 * @param userId 사용자 아이디
	 * @param dataId 데이터 아이디
	 * @param request 혈당계 데이터 정보
	 */
	updateGlucosemeterData(userId: string, dataId: string, request: RequestGlucosemeterData): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URL}/GlucosemeterDatas/${userId}/${dataId}`, request)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 특정 혈당계 데이터를 삭제한다.
	 * @param userId 사용자 아이디
	 * @param dataId 데이터 아이디
	 */
	removeGlucosemeterData(userId: string, dataId: string): Observable<ResponseData> {

		return this.httpClient.delete<ResponseData>(`${this.API_URL}/GlucosemeterDatas/${userId}/${dataId}`)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 혈당계 데이터를 삭제한다.
	 * @param userId 사용자 아이디
	 * @param searchStartDate 검색 시작일자
	 * @param searchEndDate 검색 종료일자
	 */
	removeGlucosemeterDatas(userId: string, searchStartDate?: Date, searchEndDate?: Date): Observable<ResponseData> {

		const startDate: string = moment(searchStartDate).format('YYYY-MM-DD HH:mm:ss');
		const endDate: string = moment(searchEndDate).format('YYYY-MM-DD HH:mm:ss');

		return this.httpClient.delete<ResponseData>(`${this.API_URL}/GlucosemeterDatas/${userId}?searchStartDate=${startDate}&searchEndDate=${endDate}`)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 혈당계 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param dataId 사용자 아이디
	 */
	getGlucosemeterData(userId: string, dataId: string): Observable<ResponseData<ResponseGlucosemeterData>> {
		return this.httpClient.get<ResponseData<ResponseGlucosemeterData>>(`${this.API_URL}/GlucosemeterDatas/${userId}/${dataId}`)
			.pipe(
				map((result) => {
					if(result.Data)
						result.Data.RegDate = moment(result.Data.RegDate).toDate();
					const newData: ResponseData<ResponseGlucosemeterData> = Object.assign(new ResponseData<ResponseGlucosemeterData>(), result);
					newData.Data = Object.assign(new ResponseGlucosemeterData(), result.Data);
					return newData;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 혈당계 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param param 검색 조건 파라미터
	 */
	getUserGlucosemeterDatas(userId: string, param: any): Observable<ResponseList<ResponseGlucosemeterData>> {
		return this.httpClient.get<ResponseList<ResponseGlucosemeterData>>(`${this.API_URL}/GlucosemeterDatas/${userId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponseGlucosemeterData[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponseGlucosemeterData(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponseGlucosemeterData> = new ResponseList<ResponseGlucosemeterData>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 전체 사용자의 혈당계 데이터를 가져온다.
	 * @param agencyId 기관 아이디
	 * @param param 검색 조건 파라미터
	 */
	getGlucosemeterDatas(agencyId: string, param: any): Observable<ResponseList<ResponseGlucosemeterData>> {
		return this.httpClient.get<ResponseList<ResponseGlucosemeterData>>(`${this.API_URL}/GlucosemeterDatas/Agencies/${agencyId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponseGlucosemeterData[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponseGlucosemeterData(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponseGlucosemeterData> = new ResponseList<ResponseGlucosemeterData>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 Hba1c 데이터를 등록한다.
	 * @param userId 사용자 아이디
	 * @param requests Hba1c 데이터 정보
	 */
	addHba1cData(userId: string, requests: RequestHba1cData[]): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.API_URL}/Hba1cDatas/${userId}`, requests)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 Hba1c 데이터를 수정한다.
	 * @param userId 사용자 아이디
	 * @param dataId 데이터 아이디
	 * @param request Hba1c 데이터 정보
	 */
	updateHba1cData(userId: string, dataId: string, request: RequestHba1cData): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URL}/Hba1cDatas/${userId}/${dataId}`, request)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 특정 Hba1c 데이터를 삭제한다.
	 * @param userId 사용자 아이디
	 * @param dataId 데이터 아이디
	 */
	removeHba1cData(userId: string, dataId: string): Observable<ResponseData> {

		return this.httpClient.delete<ResponseData>(`${this.API_URL}/Hba1cDatas/${userId}/${dataId}`)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 Hba1c 데이터를 삭제한다.
	 * @param userId 사용자 아이디
	 * @param searchStartDate 검색 시작일자
	 * @param searchEndDate 검색 종료일자
	 */
	removeHba1cDatas(userId: string, searchStartDate?: Date, searchEndDate?: Date): Observable<ResponseData> {

		const startDate: string = moment(searchStartDate).format('YYYY-MM-DD HH:mm:ss');
		const endDate: string = moment(searchEndDate).format('YYYY-MM-DD HH:mm:ss');

		return this.httpClient.delete<ResponseData>(`${this.API_URL}/Hba1cDatas/${userId}?searchStartDate=${startDate}&searchEndDate=${endDate}`)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 Hba1c 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param dataId 사용자 아이디
	 */
	getHba1cData(userId: string, dataId: string): Observable<ResponseData<ResponseHba1cData>> {
		return this.httpClient.get<ResponseData<ResponseHba1cData>>(`${this.API_URL}/Hba1cDatas/${userId}/${dataId}`)
			.pipe(
				map((result) => {
					if(result.Data)
						result.Data.RegDate = moment(result.Data.RegDate).toDate();
					const newData: ResponseData<ResponseHba1cData> = Object.assign(new ResponseData<ResponseHba1cData>(), result);
					newData.Data = Object.assign(new ResponseHba1cData(), result.Data);
					return newData;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 Hba1c 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param param 검색 조건 파라미터
	 */
	getUserHba1cDatas(userId: string, param: any): Observable<ResponseList<ResponseHba1cData>> {
		return this.httpClient.get<ResponseList<ResponseHba1cData>>(`${this.API_URL}/Hba1cDatas/${userId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponseHba1cData[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponseHba1cData(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponseHba1cData> = new ResponseList<ResponseHba1cData>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 전체 사용자의 Hba1c 데이터를 가져온다.
	 * @param agencyId 기관 아이디
	 * @param param 검색 조건 파라미터
	 */
	getHba1cDatas(agencyId: string, param: any): Observable<ResponseList<ResponseHba1cData>> {
		return this.httpClient.get<ResponseList<ResponseHba1cData>>(`${this.API_URL}/Hba1cDatas/Agencies/${agencyId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponseHba1cData[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponseHba1cData(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponseHba1cData> = new ResponseList<ResponseHba1cData>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 활동 데이터를 등록한다.
	 * @param userId 사용자 아이디
	 * @param requests 활동계 활동 데이터 정보
	 */
	addPedometerActivityData(userId: string, requests: RequestPedometerActivityData[]): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.API_URL}/PedometerActivityDatas/${userId}`, requests)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 활동 데이터를 수정한다.
	 * @param userId 사용자 아이디
	 * @param dataId 데이터 아이디
	 * @param request 활동계 활동 데이터 정보
	 */
	updatePedometerActivityData(userId: string, dataId: string, request: RequestPedometerActivityData): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URL}/PedometerActivityDatas/${userId}/${dataId}`, request)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 특정 활동계 활동 데이터를 삭제한다.
	 * @param userId 사용자 아이디
	 * @param dataId 데이터 아이디
	 */
	removePedometerActivityData(userId: string, dataId: string): Observable<ResponseData> {

		return this.httpClient.delete<ResponseData>(`${this.API_URL}/PedometerActivityDatas/${userId}/${dataId}`)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 활동 데이터를 삭제한다.
	 * @param userId 사용자 아이디
	 * @param searchStartDate 검색 시작일자
	 * @param searchEndDate 검색 종료일자
	 */
	removePedometerActivityDatas(userId: string, searchStartDate?: Date, searchEndDate?: Date): Observable<ResponseData> {

		const startDate: string = moment(searchStartDate).format('YYYY-MM-DD HH:mm:ss');
		const endDate: string = moment(searchEndDate).format('YYYY-MM-DD HH:mm:ss');

		return this.httpClient.delete<ResponseData>(`${this.API_URL}/PedometerActivityDatas/${userId}?searchStartDate=${startDate}&searchEndDate=${endDate}`)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 활동 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param dataId 사용자 아이디
	 */
	getPedometerActivityData(userId: string, dataId: string): Observable<ResponseData<ResponsePedometerActivityData>> {
		return this.httpClient.get<ResponseData<ResponsePedometerActivityData>>(`${this.API_URL}/PedometerActivityDatas/${userId}/${dataId}`)
			.pipe(
				map((result) => {
					if(result.Data)
						result.Data.RegDate = moment(result.Data.RegDate).toDate();
					const newData: ResponseData<ResponsePedometerActivityData> = Object.assign(new ResponseData<ResponsePedometerActivityData>(), result);
					newData.Data = Object.assign(new ResponsePedometerActivityData(), result.Data);
					return newData;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 활동 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param param 검색 조건 파라미터
	 */
	getUserPedometerActivityDatas(userId: string, param: any): Observable<ResponseList<ResponsePedometerActivityData>> {
		return this.httpClient.get<ResponseList<ResponsePedometerActivityData>>(`${this.API_URL}/PedometerActivityDatas/${userId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponsePedometerActivityData[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponsePedometerActivityData(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponsePedometerActivityData> = new ResponseList<ResponsePedometerActivityData>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 전체 사용자의 활동계 활동 데이터를 가져온다.
	 * @param agencyId 기관 아이디
	 * @param param 검색 조건 파라미터
	 */
	getPedometerActivityDatas(agencyId: string, param: any): Observable<ResponseList<ResponsePedometerActivityData>> {
		return this.httpClient.get<ResponseList<ResponsePedometerActivityData>>(`${this.API_URL}/PedometerActivityDatas/Agencies/${agencyId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponsePedometerActivityData[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponsePedometerActivityData(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponsePedometerActivityData> = new ResponseList<ResponsePedometerActivityData>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 심박 데이터를 등록한다.
	 * @param userId 사용자 아이디
	 * @param requests 활동계 심박 데이터 정보
	 */
	addPedometerHeartRateData(userId: string, requests: RequestPedometerHeartRateData[]): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.API_URL}/PedometerHeartRateDatas/${userId}`, requests)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 심박 데이터를 수정한다.
	 * @param userId 사용자 아이디
	 * @param dataId 데이터 아이디
	 * @param request 활동계 심박 데이터 정보
	 */
	updatePedometerHeartRateData(userId: string, dataId: string, request: RequestPedometerHeartRateData): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URL}/PedometerHeartRateDatas/${userId}/${dataId}`, request)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 특정 활동계 심박 데이터를 삭제한다.
	 * @param userId 사용자 아이디
	 * @param dataId 데이터 아이디
	 */
	removePedometerHeartRateData(userId: string, dataId: string): Observable<ResponseData> {

		return this.httpClient.delete<ResponseData>(`${this.API_URL}/PedometerHeartRateDatas/${userId}/${dataId}`)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 심박 데이터를 삭제한다.
	 * @param userId 사용자 아이디
	 * @param searchStartDate 검색 시작일자
	 * @param searchEndDate 검색 종료일자
	 */
	removePedometerHeartRateDatas(userId: string, searchStartDate?: Date, searchEndDate?: Date): Observable<ResponseData> {

		const startDate: string = moment(searchStartDate).format('YYYY-MM-DD HH:mm:ss');
		const endDate: string = moment(searchEndDate).format('YYYY-MM-DD HH:mm:ss');

		return this.httpClient.delete<ResponseData>(`${this.API_URL}/PedometerHeartRateDatas/${userId}?searchStartDate=${startDate}&searchEndDate=${endDate}`)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 심박 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param dataId 사용자 아이디
	 */
	getPedometerHeartRateData(userId: string, dataId: string): Observable<ResponseData<ResponsePedometerHeartRateData>> {
		return this.httpClient.get<ResponseData<ResponsePedometerHeartRateData>>(`${this.API_URL}/PedometerHeartRateDatas/${userId}/${dataId}`)
			.pipe(
				map((result) => {
					if(result.Data)
						result.Data.RegDate = moment(result.Data.RegDate).toDate();
					const newData: ResponseData<ResponsePedometerHeartRateData> = Object.assign(new ResponseData<ResponsePedometerHeartRateData>(), result);
					newData.Data = Object.assign(new ResponsePedometerHeartRateData(), result.Data);
					return newData;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 심박 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param param 검색 조건 파라미터
	 */
	getUserPedometerHeartRateDatas(userId: string, param: any): Observable<ResponseList<ResponsePedometerHeartRateData>> {
		return this.httpClient.get<ResponseList<ResponsePedometerHeartRateData>>(`${this.API_URL}/PedometerHeartRateDatas/${userId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponsePedometerHeartRateData[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponsePedometerHeartRateData(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponsePedometerHeartRateData> = new ResponseList<ResponsePedometerHeartRateData>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 전체 사용자의 활동계 심박 데이터를 가져온다.
	 * @param agencyId 기관 아이디
	 * @param param 검색 조건 파라미터
	 */
	getPedometerHeartRateDatas(agencyId: string, param: any): Observable<ResponseList<ResponsePedometerHeartRateData>> {
		return this.httpClient.get<ResponseList<ResponsePedometerHeartRateData>>(`${this.API_URL}/PedometerHeartRateDatas/Agencies/${agencyId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponsePedometerHeartRateData[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponsePedometerHeartRateData(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponsePedometerHeartRateData> = new ResponseList<ResponsePedometerHeartRateData>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 수면 데이터를 등록한다.
	 * @param userId 사용자 아이디
	 * @param requests 활동계 수면 데이터 정보
	 */
	addPedometerSleepData(userId: string, requests: RequestPedometerSleepData[]): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.API_URL}/PedometerSleepDatas/${userId}`, requests)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 수면 데이터를 수정한다.
	 * @param userId 사용자 아이디
	 * @param dataId 데이터 아이디
	 * @param request 활동계 수면 데이터 정보
	 */
	updatePedometerSleepData(userId: string, dataId: string, request: RequestPedometerSleepData): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URL}/PedometerSleepDatas/${userId}/${dataId}`, request)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 특정 활동계 수면 데이터를 삭제한다.
	 * @param userId 사용자 아이디
	 * @param dataId 데이터 아이디
	 */
	removePedometerSleepData(userId: string, dataId: string): Observable<ResponseData> {

		return this.httpClient.delete<ResponseData>(`${this.API_URL}/PedometerSleepDatas/${userId}/${dataId}`)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 수면 데이터를 삭제한다.
	 * @param userId 사용자 아이디
	 * @param searchStartDate 검색 시작일자
	 * @param searchEndDate 검색 종료일자
	 */
	removePedometerSleepDatas(userId: string, searchStartDate?: Date, searchEndDate?: Date): Observable<ResponseData> {

		const startDate: string = moment(searchStartDate).format('YYYY-MM-DD HH:mm:ss');
		const endDate: string = moment(searchEndDate).format('YYYY-MM-DD HH:mm:ss');

		return this.httpClient.delete<ResponseData>(`${this.API_URL}/PedometerSleepDatas/${userId}?searchStartDate=${startDate}&searchEndDate=${endDate}`)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 수면 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param dataId 사용자 아이디
	 */
	getPedometerSleepData(userId: string, dataId: string): Observable<ResponseData<ResponsePedometerSleepData>> {
		return this.httpClient.get<ResponseData<ResponsePedometerSleepData>>(`${this.API_URL}/PedometerSleepDatas/${userId}/${dataId}`)
			.pipe(
				map((result) => {
					if(result.Data)
						result.Data.RegDate = moment(result.Data.RegDate).toDate();
					const newData: ResponseData<ResponsePedometerSleepData> = Object.assign(new ResponseData<ResponsePedometerSleepData>(), result);
					newData.Data = Object.assign(new ResponsePedometerSleepData(), result.Data);
					return newData;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 수면 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param param 검색 조건 파라미터
	 */
	getUserPedometerSleepDatas(userId: string, param: any): Observable<ResponseList<ResponsePedometerSleepData>> {
		return this.httpClient.get<ResponseList<ResponsePedometerSleepData>>(`${this.API_URL}/PedometerSleepDatas/${userId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponsePedometerSleepData[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponsePedometerSleepData(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponsePedometerSleepData> = new ResponseList<ResponsePedometerSleepData>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 전체 사용자의 활동계 수면 데이터를 가져온다.
	 * @param agencyId 기관 아이디
	 * @param param 검색 조건 파라미터
	 */
	getPedometerSleepDatas(agencyId: string, param: any): Observable<ResponseList<ResponsePedometerSleepData>> {
		return this.httpClient.get<ResponseList<ResponsePedometerSleepData>>(`${this.API_URL}/PedometerSleepDatas/Agencies/${agencyId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponsePedometerSleepData[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponsePedometerSleepData(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponsePedometerSleepData> = new ResponseList<ResponsePedometerSleepData>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	// /**
	//  * 특정 사용자의 활동계 활동 요약 데이터를 등록한다.
	//  * @param userId 사용자 아이디
	//  * @param requests 활동계 활동 요약 데이터 정보
	//  */
	// addPedometerActivityDataSummary(userId: string, requests: RequestPedometerActivitySummary[]): Observable<ResponseData> {
	// 	return this.httpClient.post<ResponseData>(`${this.API_URL}/PedometerActivityDataSummaries/${userId}`, requests)
	// 		.pipe(
	// 			map((result) => {
	// 				return Object.assign(new ResponseData(), result);
	// 			}),
	// 			catchError((err) => {
	// 				return throwError(err);
	// 			})
	// 		);
	// }
	//
	// /**
	//  * 특정 사용자의 활동계 활동 요약 데이터를 삭제한다.
	//  * @param userId 사용자 아이디
	//  * @param searchStartDate 검색 시작일자
	//  * @param searchEndDate 검색 종료일자
	//  */
	// removePedometerActivityDataSummaries(userId: string, searchStartDate?: Date, searchEndDate?: Date): Observable<ResponseData> {
	//
	// 	const startDate: string = moment(searchStartDate).format('YYYY-MM-DD HH:mm:ss');
	// 	const endDate: string = moment(searchEndDate).format('YYYY-MM-DD HH:mm:ss');
	//
	// 	return this.httpClient.delete<ResponseData>(`${this.API_URL}/PedometerActivityDataSummaries/${userId}?searchStartDate=${startDate}&searchEndDate=${endDate}`)
	// 		.pipe(
	// 			map((result) => {
	// 				return Object.assign(new ResponseData(), result);
	// 			}),
	// 			catchError((err) => {
	// 				return throwError(err);
	// 			})
	// 		);
	// }

	/**
	 * 특정 사용자의 활동계 활동 요약 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param dataId 사용자 아이디
	 */
	getPedometerActivityDataSummary(userId: string, dataId: string): Observable<ResponseData<ResponsePedometerActivitySummary>> {
		return this.httpClient.get<ResponseData<ResponsePedometerActivitySummary>>(`${this.API_URL}/PedometerActivityDataSummaries/${userId}/${dataId}`)
			.pipe(
				map((result) => {
					if(result.Data)
						result.Data.RegDate = moment(result.Data.RegDate).toDate();
					return Object.assign(new ResponseData<ResponsePedometerActivitySummary>(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 활동 요약 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param param 검색 조건 파라미터
	 */
	getUserPedometerActivityDataSummaries(userId: string, param: any): Observable<ResponseList<ResponsePedometerActivitySummary>> {
		return this.httpClient.get<ResponseList<ResponsePedometerActivitySummary>>(`${this.API_URL}/PedometerActivityDataSummaries/${userId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponsePedometerActivitySummary[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponsePedometerActivitySummary(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponsePedometerActivitySummary> = new ResponseList<ResponsePedometerActivitySummary>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 전체 사용자의 활동계 활동 요약 데이터를 가져온다.
	 * @param agencyId 기관 아이디
	 * @param param 검색 조건 파라미터
	 */
	getPedometerActivityDataSummaries(agencyId: string, param: any): Observable<ResponseList<ResponsePedometerActivitySummary>> {
		return this.httpClient.get<ResponseList<ResponsePedometerActivitySummary>>(`${this.API_URL}/PedometerActivityDataSummaries/Agencies/${agencyId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponsePedometerActivitySummary[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponsePedometerActivitySummary(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponsePedometerActivitySummary> = new ResponseList<ResponsePedometerActivitySummary>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 맥박 요약 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param dataId 데이터 아이디
	 */
	getPedometerHeartRateSummary(userId: string, dataId: string): Observable<ResponseData<ResponsePedometerHeartRateSummary>> {
		return this.httpClient.get<ResponseData<ResponsePedometerHeartRateSummary>>(`${this.API_URL}/PedometerHeartRateSummaries/${userId}/${dataId}`)
			.pipe(
				map((result) => {
					if(result.Data)
						result.Data.RegDate = moment(result.Data.RegDate).toDate();
					const newData: ResponseData<ResponsePedometerHeartRateSummary> = Object.assign(new ResponseData<ResponsePedometerHeartRateSummary>(), result);
					newData.Data = Object.assign(new ResponsePedometerHeartRateSummary(), result.Data);
					return newData;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 맥박 요약 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param param 검색 조건 파라미터
	 */
	getUserPedometerHeartRateSummaries(userId: string, param: any): Observable<ResponseList<ResponsePedometerHeartRateSummary>> {
		return this.httpClient.get<ResponseList<ResponsePedometerHeartRateSummary>>(`${this.API_URL}/PedometerHeartRateSummaries/${userId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponsePedometerHeartRateSummary[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponsePedometerHeartRateSummary(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponsePedometerHeartRateSummary> = new ResponseList<ResponsePedometerHeartRateSummary>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 맥박 요약 데이터를 가져온다.
	 * @param agencyId 기관 아이디
	 * @param param 검색 조건 파라미터
	 */
	getPedometerHeartRateSummaries(agencyId: string, param: any): Observable<ResponseList<ResponsePedometerHeartRateSummary>> {
		return this.httpClient.get<ResponseList<ResponsePedometerHeartRateSummary>>(`${this.API_URL}/PedometerHeartRateSummaries/Agencies/${agencyId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponsePedometerHeartRateSummary[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponsePedometerHeartRateSummary(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponsePedometerHeartRateSummary> = new ResponseList<ResponsePedometerHeartRateSummary>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	// /**
	//  * 특정 사용자의 활동계 수면 요약 데이터를 등록한다.
	//  * @param userId 사용자 아이디
	//  * @param requests 활동계 수면 요약 데이터 정보
	//  */
	// addPedometerSleepDataSummary(userId: string, requests: RequestPedometerSleepSummary[]): Observable<ResponseData> {
	// 	return this.httpClient.post<ResponseData>(`${this.API_URL}/PedometerSleepDataSummaries/${userId}`, requests)
	// 		.pipe(
	// 			map((result) => {
	// 				return Object.assign(new ResponseData(), result);
	// 			}),
	// 			catchError((err) => {
	// 				return throwError(err);
	// 			})
	// 		);
	// }
	//
	// /**
	//  * 특정 사용자의 활동계 수면 요약 데이터를 삭제한다.
	//  * @param userId 사용자 아이디
	//  * @param searchStartDate 검색 시작일자
	//  * @param searchEndDate 검색 종료일자
	//  */
	// removePedometerSleepDataSummaries(userId: string, searchStartDate?: Date, searchEndDate?: Date): Observable<ResponseData> {
	//
	// 	const startDate: string = moment(searchStartDate).format('YYYY-MM-DD HH:mm:ss');
	// 	const endDate: string = moment(searchEndDate).format('YYYY-MM-DD HH:mm:ss');
	//
	// 	return this.httpClient.delete<ResponseData>(`${this.API_URL}/PedometerSleepDataSummaries/${userId}?searchStartDate=${startDate}&searchEndDate=${endDate}`)
	// 		.pipe(
	// 			map((result) => {
	// 				return Object.assign(new ResponseData(), result);
	// 			}),
	// 			catchError((err) => {
	// 				return throwError(err);
	// 			})
	// 		);
	// }

	/**
	 * 특정 사용자의 활동계 수면 요약 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param dataId 사용자 아이디
	 */
	getPedometerSleepDataSummary(userId: string, dataId: string): Observable<ResponseData<ResponsePedometerSleepSummary>> {
		return this.httpClient.get<ResponseData<ResponsePedometerSleepSummary>>(`${this.API_URL}/PedometerSleepDataSummaries/${userId}/${dataId}`)
			.pipe(
				map((result) => {
					if(result.Data)
						result.Data.RegDate = moment(result.Data.RegDate).toDate();
					const newData: ResponseData<ResponsePedometerSleepSummary> = Object.assign(new ResponseData<ResponsePedometerSleepSummary>(), result);
					newData.Data = Object.assign(new ResponsePedometerSleepSummary(), result.Data);
					return newData;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 수면 요약 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param param 검색 조건 파라미터
	 */
	getUserPedometerSleepDataSummaries(userId: string, param: any): Observable<ResponseList<ResponsePedometerSleepSummary>> {
		return this.httpClient.get<ResponseList<ResponsePedometerSleepSummary>>(`${this.API_URL}/PedometerSleepDataSummaries/${userId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponsePedometerSleepSummary[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponsePedometerSleepSummary(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponsePedometerSleepSummary> = new ResponseList<ResponsePedometerSleepSummary>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 전체 사용자의 활동계 수면 요약 데이터를 가져온다.
	 * @param agencyId 기관 아이디
	 * @param param 검색 조건 파라미터
	 */
	getPedometerSleepDataSummaries(agencyId: string, param: any): Observable<ResponseList<ResponsePedometerSleepSummary>> {
		return this.httpClient.get<ResponseList<ResponsePedometerSleepSummary>>(`${this.API_URL}/PedometerSleepDataSummaries/Agencies/${agencyId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponsePedometerSleepSummary[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponsePedometerSleepSummary(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponsePedometerSleepSummary> = new ResponseList<ResponsePedometerSleepSummary>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}



	// 아래 함수 삭제 예정  /////////////////////////////////////////////////////////////////////////////////////////////////
	/**
	 * 특정 사용자의 활동계 활동 요약 데이터를 등록한다.
	 * @param userId 사용자 아이디
	 * @param requests 활동계 활동 요약 데이터 정보
	 */
	addPedometerActivitySummary(userId: string, requests: RequestPedometerActivitySummary[]): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.API_URL}/PedometerActivitySummaries/${userId}`, requests)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 활동 요약 데이터를 삭제한다.
	 * @param userId 사용자 아이디
	 * @param searchStartDate 검색 시작일자
	 * @param searchEndDate 검색 종료일자
	 */
	removePedometerActivitySummaries(userId: string, searchStartDate?: Date, searchEndDate?: Date): Observable<ResponseData> {

		const startDate: string = moment(searchStartDate).format('YYYY-MM-DD HH:mm:ss');
		const endDate: string = moment(searchEndDate).format('YYYY-MM-DD HH:mm:ss');

		return this.httpClient.delete<ResponseData>(`${this.API_URL}/PedometerActivitySummaries/${userId}?searchStartDate=${startDate}&searchEndDate=${endDate}`)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 활동 요약 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param dataId 사용자 아이디
	 */
	getPedometerActivitySummary(userId: string, dataId: string): Observable<ResponseData<ResponsePedometerActivitySummary>> {
		return this.httpClient.get<ResponseData<ResponsePedometerActivitySummary>>(`${this.API_URL}/PedometerActivitySummaries/${userId}/${dataId}`)
			.pipe(
				map((result) => {
					if(result.Data)
						result.Data.RegDate = moment(result.Data.RegDate).toDate();
					return Object.assign(new ResponseData<ResponsePedometerActivitySummary>(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 활동 요약 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param param 검색 조건 파라미터
	 */
	getUserPedometerActivitySummaries(userId: string, param: any): Observable<ResponseList<ResponsePedometerActivitySummary>> {
		return this.httpClient.get<ResponseList<ResponsePedometerActivitySummary>>(`${this.API_URL}/PedometerActivitySummaries/${userId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponsePedometerActivitySummary[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponsePedometerActivitySummary(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponsePedometerActivitySummary> = new ResponseList<ResponsePedometerActivitySummary>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 전체 사용자의 활동계 활동 요약 데이터를 가져온다.
	 * @param agencyId 기관 아이디
	 * @param param 검색 조건 파라미터
	 */
	getPedometerActivitySummaries(agencyId: string, param: any): Observable<ResponseList<ResponsePedometerActivitySummary>> {
		return this.httpClient.get<ResponseList<ResponsePedometerActivitySummary>>(`${this.API_URL}/PedometerActivitySummaries/Agencies/${agencyId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponsePedometerActivitySummary[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponsePedometerActivitySummary(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponsePedometerActivitySummary> = new ResponseList<ResponsePedometerActivitySummary>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 수면 요약 데이터를 등록한다.
	 * @param userId 사용자 아이디
	 * @param requests 활동계 수면 요약 데이터 정보
	 */
	addPedometerSleepSummary(userId: string, requests: RequestPedometerSleepSummary[]): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.API_URL}/PedometerSleepSummaries/${userId}`, requests)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 수면 요약 데이터를 삭제한다.
	 * @param userId 사용자 아이디
	 * @param searchStartDate 검색 시작일자
	 * @param searchEndDate 검색 종료일자
	 */
	removePedometerSleepSummaries(userId: string, searchStartDate?: Date, searchEndDate?: Date): Observable<ResponseData> {

		const startDate: string = moment(searchStartDate).format('YYYY-MM-DD HH:mm:ss');
		const endDate: string = moment(searchEndDate).format('YYYY-MM-DD HH:mm:ss');

		return this.httpClient.delete<ResponseData>(`${this.API_URL}/PedometerSleepSummaries/${userId}?searchStartDate=${startDate}&searchEndDate=${endDate}`)
			.pipe(
				map((result) => {
					return Object.assign(new ResponseData(), result);
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 수면 요약 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param dataId 사용자 아이디
	 */
	getPedometerSleepSummary(userId: string, dataId: string): Observable<ResponseData<ResponsePedometerSleepSummary>> {
		return this.httpClient.get<ResponseData<ResponsePedometerSleepSummary>>(`${this.API_URL}/PedometerSleepSummaries/${userId}/${dataId}`)
			.pipe(
				map((result) => {
					if(result.Data)
						result.Data.RegDate = moment(result.Data.RegDate).toDate();
					const newData: ResponseData<ResponsePedometerSleepSummary> = Object.assign(new ResponseData<ResponsePedometerSleepSummary>(), result);
					newData.Data = Object.assign(new ResponsePedometerSleepSummary(), result.Data);
					return newData;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 활동계 수면 요약 데이터를 가져온다.
	 * @param userId 사용자 아이디
	 * @param param 검색 조건 파라미터
	 */
	getUserPedometerSleepSummaries(userId: string, param: any): Observable<ResponseList<ResponsePedometerSleepSummary>> {
		return this.httpClient.get<ResponseList<ResponsePedometerSleepSummary>>(`${this.API_URL}/PedometerSleepSummaries/${userId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponsePedometerSleepSummary[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponsePedometerSleepSummary(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponsePedometerSleepSummary> = new ResponseList<ResponsePedometerSleepSummary>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 전체 사용자의 활동계 수면 요약 데이터를 가져온다.
	 * @param agencyId 기관 아이디
	 * @param param 검색 조건 파라미터
	 */
	getPedometerSleepSummaries(agencyId: string, param: any): Observable<ResponseList<ResponsePedometerSleepSummary>> {
		return this.httpClient.get<ResponseList<ResponsePedometerSleepSummary>>(`${this.API_URL}/PedometerSleepSummaries/Agencies/${agencyId}`, { params: param })
			.pipe(
				map((result) => {
					const items: ResponsePedometerSleepSummary[] = [];

					// 데이터가 존재하는 경우
					if(result.Data.Items) {
						// 모든 데이터에 대해서 처리
						for(const item of result.Data.Items) {
							item.RegDate = moment(item.RegDate).toDate();
							// api에서 넘어온 객체를 반환 타입에 맞게 복사
							items.push(Object.assign(new ResponsePedometerSleepSummary(), item));
						}
					}

					// 목록 객체를 생성하여 값 복사
					const data: ResponseList<ResponsePedometerSleepSummary> = new ResponseList<ResponsePedometerSleepSummary>();
					Object.assign(data, result);
					data.Data.Items = items;
					return data;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
}
