import {AfterViewInit, Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';


@Component({
	selector: 'app-applink',
	templateUrl: './applink.component.html',
	styleUrls: ['./applink.component.scss']
})
export class ApplinkComponent implements AfterViewInit {

	// deepLink URL
	deepLinkUrl: string = '';

	/**
	 * 생성자
	 * @param route Active Route 객체
	 */
	constructor(
		private route: ActivatedRoute,
	) {

		// DEEP LINK URL을 가져온다.
		this.deepLinkUrl = this.route.snapshot.queryParamMap.get('link');
	}

	ngAfterViewInit(): void {
		window.location.href = 'autochekcare://autochekcare.aprilis.asia/app' + this.deepLinkUrl;
	}
}
