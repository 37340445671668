import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {ResponseList} from '../../../_model/response-list.model';
import {catchError, map} from 'rxjs/operators';
import {ResponseData} from '../../../_model/response-data.model';
import {ResponseAgency} from '../../../_model/response/agency/response-agency.model';
import {RequestAgency} from '../../../_model/request/agency/request-agency.model';
import {ResponseAgencyName} from '../../../_model/response/agency/response-agency-name.model';
import {ResponseAgencyUser} from '../../../_model/response/agency/response-agency-user.model';
import {ResponseUserCore} from '../../../_model/response/account/response-user-core.model';
import {ResponseAgencyPatientDiabeteTypes} from '../../../_model/response/agency/response-agency-patient-diabete-types.model';
import * as moment from 'moment';
import {ResponseAgencyConsulting} from '../../../_model/response/agency/response-agency-consulting.model';
import {RequestAgencyConsulting} from '../../../_model/request/agency/request-agency-consulting.model';
import {ResponseAgencyPushMessage} from '../../../_model/response/agency/response-agency-push-message.model';
import {RequestAgencyPushMessage} from '../../../_model/request/agency/request-agency-push-message.model';
import {ResponseGlucosemeterStatData} from '../../../_model/response/data/response-glucosemeter-stat-data.model';
import {ResponseBodyScaleStatData} from '../../../_model/response/data/response-body-scale-stat-data.model';
import {ResponseBloodPressureStatData} from '../../../_model/response/data/response-blood-pressure-stat-data.model';
import {ResponsePedometerActivityStatData} from '../../../_model/response/data/response-pedometer-activity-stat-data.model';

/**
 * 기관 정보 데이터 프로바이더
 */
@Injectable()
export class AgencyProvider {
	/**
	 * API URL
	 */
	private API_URL = `${environment.apiUrl}/Agencies`;

	// 담당기관 변경 알람 원본
	private _changeAgencySource: BehaviorSubject<ResponseAgency> = null;

	// 담당기관 변경 변경 감시
	changeAgencySource$: Observable<ResponseAgency> = null;

	/**
	 * 생성자
	 */
	constructor(
		private httpClient: HttpClient
	) {
		this._changeAgencySource = new BehaviorSubject<ResponseAgency>(null);
		this.changeAgencySource$ = this._changeAgencySource.asObservable();
	}

	/**
	 * 기관 변경
	 * @param agency 변경할 기관 정보
	 */
	changeAgency(agency: ResponseAgency) {
		this._changeAgencySource.next(agency);
	}

	/**
	 * 기관명 목록을 가져온다.
	 * @param param 검색 파라미터
	 */
	getName(param: any = null): Observable<ResponseList<ResponseAgencyName>> {
		return this.httpClient.get<ResponseList<ResponseAgencyName>>(`${this.API_URL}/Names`, { params: param })
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 내 담당 기관 목록을 가져온다.
	 */
	getMyList(): Observable<ResponseList<ResponseAgency>> {
		return this.httpClient.get<ResponseList<ResponseAgency>>(`${this.API_URL}/Mine`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 목록을 가져온다.
	 * @param param 검색 파라미터
	 */
	getList(param: any = null): Observable<ResponseList<ResponseAgency>> {
		return this.httpClient.get<ResponseList<ResponseAgency>>(`${this.API_URL}`, { params: param })
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 정보를 가져온다.
	 * @param id 기관 아이디
	 */
	get(id: string): Observable<ResponseData<ResponseAgency>> {
		return this.httpClient.get<ResponseData<ResponseAgency>>(`${this.API_URL}/${id}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 기관 정보를 등록한다.
	 * @param request 등록 정보 객체
	 */
	add(request: RequestAgency): Observable<ResponseData<ResponseAgency>> {
		return this.httpClient.post<ResponseData<ResponseAgency>>(`${this.API_URL}`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 기관 정보를 수정한다.
	 * @param id 기관 아이디
	 * @param request 수정 정보 객체
	 */
	update(id: string, request: RequestAgency): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URL}/${id}`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 기관 정보를 삭제한다.
	 * @param id 역할 아이디
	 */
	remove(id: string): Observable<ResponseData> {
		return this.httpClient.delete<ResponseData>(`${this.API_URL}/${id}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 추가 가능한 기관 사용자인지 여부를 확인한다.
	 * @param id 기관 아이디
	 * @param userValue 사용자 정보 (핸드폰번호/이메일)
	 */
	canAdd(id: string, userValue: string): Observable<ResponseData<ResponseUserCore>> {
		if(id)
			return this.httpClient.get<ResponseData<ResponseUserCore>>(`${this.API_URL}/${id}/Users/${userValue}/CanAdd`)
				.pipe(
					map((result) => {
						return result;
					}),
					catchError((err) => {
						return throwError(err);
					})
				);
		else
			return this.httpClient.get<ResponseData<ResponseUserCore>>(`${this.API_URL}/Users/${userValue}/CanAdd`)
				.pipe(
					map((result) => {
						return result;
					}),
					catchError((err) => {
						return throwError(err);
					})
				);
	}

	/**
	 * 기관 사용자 정보 목록을 가져온다.
	 * @param id 기관 아이디
	 * @param param 검색 파라미터
	 */
	getUserList(id: string, param: any = null): Observable<ResponseList<ResponseAgencyUser>> {
		return this.httpClient.get<ResponseList<ResponseAgencyUser>>(`${this.API_URL}/${id}/Users`, { params: param })
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 기관 사용자 정보를 가져온다.
	 * @param id 기관 아이디
	 * @param userId 사용자 아이디
	 */
	getUser(id: string, userId: string): Observable<ResponseData<ResponseAgencyUser>> {
		return this.httpClient.get<ResponseData<ResponseAgencyUser>>(`${this.API_URL}/${id}/Users/${userId}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 기관 사용자 정보를 등록한다.
	 * @param id 기관 아이디
	 * @param userId 사용자 아이디
	 */
	addUser(id: string, userId: string): Observable<ResponseData<ResponseAgencyUser>> {
		return this.httpClient.post<ResponseData<ResponseAgencyUser>>(`${this.API_URL}/${id}/Users/${userId}`, null)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 기관 사용자 정보를 삭제한다.
	 * @param id 기관 아이디
	 * @param userId 사용자 아이디
	 */
	removeUser(id: string, userId: string): Observable<ResponseData> {
		return this.httpClient.delete<ResponseData>(`${this.API_URL}/${id}/Users/${userId}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 기관 환자들의 당뇨유형별 환자수를 가져온다.
	 * @param id 기관 아이디
	 */
	getPatientDiabeteTypes(id: string): Observable<ResponseList<ResponseAgencyPatientDiabeteTypes>> {
		return this.httpClient.get<ResponseList<ResponseAgencyPatientDiabeteTypes>>(`${this.API_URL}/${id}/Patients/DiabeteTypes`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 날짜의 기관 환자들의 혈당 측정 데이터 목록을 가져온다.
	 * @param id 기관 아이디
	 * @param date 일자
	 */
	getGlucosemeterStatistics(id: string, date: Date): Observable<ResponseData<ResponseGlucosemeterStatData>> {
		const dateParam = moment(date).format('YYYY-MM-DD');
		return this.httpClient.get<ResponseData<ResponseGlucosemeterStatData>>(`${this.API_URL}/${id}/Patients/GlucosemeterStatistics/${dateParam}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 날짜의 기관 환자들의 체지방 측정 데이터 목록을 가져온다.
	 * @param id 기관 아이디
	 * @param date 일자
	 */
	getBodyScaleStatistics(id: string, date: Date): Observable<ResponseData<ResponseBodyScaleStatData>> {
		const dateParam = moment(date).format('YYYY-MM-DD');
		return this.httpClient.get<ResponseData<ResponseBodyScaleStatData>>(`${this.API_URL}/${id}/Patients/BodyScaleStatistics/${dateParam}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 날짜의 기관 환자들의 혈압 측정 데이터 목록을 가져온다.
	 * @param id 기관 아이디
	 * @param date 일자
	 */
	getBloodPressureStatistics(id: string, date: Date): Observable<ResponseData<ResponseBloodPressureStatData>> {
		const dateParam = moment(date).format('YYYY-MM-DD');
		return this.httpClient.get<ResponseData<ResponseBloodPressureStatData>>(`${this.API_URL}/${id}/Patients/BloodPressureStatistics/${dateParam}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 날짜의 기관 환자들의 활동계 활동 측정 데이터 목록을 가져온다.
	 * @param id 기관 아이디
	 * @param date 일자
	 */
	getPedometerActivityStatistics(id: string, date: Date): Observable<ResponseData<ResponsePedometerActivityStatData>> {
		const dateParam = moment(date).format('YYYY-MM-DD');
		return this.httpClient.get<ResponseData<ResponsePedometerActivityStatData>>(`${this.API_URL}/${id}/Patients/PedometerActivityStatistics/${dateParam}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 상담 목록을 가져온다.
	 * @param agencyId 기관 아이디
	 * @param userId 사용자 아이디
	 * @param param 검색 파라미터
	 */
	getConsultingList(agencyId: string, userId: string, param: any = null): Observable<ResponseList<ResponseAgencyConsulting>> {
		return this.httpClient.get<ResponseList<ResponseAgencyConsulting>>(`${this.API_URL}/${agencyId}/Consultings/${userId}`, { params: param })
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 상담 정보를 가져온다.
	 * @param agencyId 기관 아이디
	 * @param userId 사용자 아이디
	 * @param id 상담 아이디
	 */
	getConsulting(agencyId: string, userId: string, id: string): Observable<ResponseData<ResponseAgencyConsulting>> {
		return this.httpClient.get<ResponseData<ResponseAgencyConsulting>>(`${this.API_URL}/${agencyId}/Consultings/${userId}/${id}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 상담 정보를 등록한다.
	 * @param agencyId 기관 아이디
	 * @param userId 사용자 아이디
	 * @param request 등록 정보 객체
	 */
	addConsulting(agencyId: string, userId: string, request: RequestAgencyConsulting): Observable<ResponseData<ResponseAgencyConsulting>> {
		return this.httpClient.post<ResponseData<ResponseAgencyConsulting>>(`${this.API_URL}/${agencyId}/Consultings/${userId}`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 상담 정보를 수정한다.
	 * @param agencyId 기관 아이디
	 * @param userId 사용자 아이디
	 * @param id 상담 아이디
	 * @param request 수정 정보 객체
	 */
	updateConsulting(agencyId: string, userId: string, id: string, request: RequestAgency): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URL}/${agencyId}/Consultings/${userId}/${id}`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 모든 상담 정보를 삭제한다.
	 * @param agencyId 기관 아이디
	 * @param userId 사용자 아이디
	 * @param searchStartDate 검색 시작일자
	 * @param searchEndDate 검색 종료일자
	 */
	removeConsultings(agencyId: string, userId: string, searchStartDate: Date, searchEndDate: Date): Observable<ResponseData> {

		const startDate: string = moment(searchStartDate).format('YYYY-MM-DD HH:mm:ss');
		const endDate: string = moment(searchEndDate).format('YYYY-MM-DD HH:mm:ss');

		return this.httpClient.delete<ResponseData>(`${this.API_URL}/${agencyId}/Consultings/${userId}?searchStartDate=${startDate}&searchEndDate=${endDate}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 상담 정보를 삭제한다.
	 * @param agencyId 기관 아이디
	 * @param userId 사용자 아이디
	 * @param id 상담 아이디
	 */
	removeConsulting(agencyId: string, userId: string, id: string): Observable<ResponseData> {
		return this.httpClient.delete<ResponseData>(`${this.API_URL}/${agencyId}/Consultings/${userId}/${id}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 푸쉬 알람 목록을 가져온다.
	 * @param agencyId 기관 아이디
	 * @param userId 사용자 아이디
	 * @param param 검색 파라미터
	 */
	getPushMessageList(agencyId: string, userId: string, param: any = null): Observable<ResponseList<ResponseAgencyPushMessage>> {
		return this.httpClient.get<ResponseList<ResponseAgencyPushMessage>>(`${this.API_URL}/${agencyId}/PushMessages/${userId}`, { params: param })
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 푸쉬 알람 정보를 가져온다.
	 * @param agencyId 기관 아이디
	 * @param userId 사용자 아이디
	 * @param id 푸쉬 알람 아이디
	 */
	getPushMessage(agencyId: string, userId: string, id: string): Observable<ResponseData<ResponseAgencyPushMessage>> {
		return this.httpClient.get<ResponseData<ResponseAgencyPushMessage>>(`${this.API_URL}/${agencyId}/PushMessages/${userId}/${id}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 푸쉬 알람 정보를 등록한다.
	 * @param agencyId 기관 아이디
	 * @param userId 사용자 아이디
	 * @param request 등록 정보 객체
	 */
	addPushMessage(agencyId: string, userId: string, request: RequestAgencyPushMessage): Observable<ResponseData<ResponseAgencyPushMessage>> {
		return this.httpClient.post<ResponseData<ResponseAgencyPushMessage>>(`${this.API_URL}/${agencyId}/PushMessages/${userId}`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 모든 푸쉬 알람 정보를 삭제한다.
	 * @param agencyId 기관 아이디
	 * @param userId 사용자 아이디
	 * @param searchStartDate 검색 시작일자
	 * @param searchEndDate 검색 종료일자
	 */
	removePushMessages(agencyId: string, userId: string, searchStartDate: Date, searchEndDate: Date): Observable<ResponseData> {

		const startDate: string = moment(searchStartDate).format('YYYY-MM-DD HH:mm:ss');
		const endDate: string = moment(searchEndDate).format('YYYY-MM-DD HH:mm:ss');

		return this.httpClient.delete<ResponseData>(`${this.API_URL}/${agencyId}/PushMessages/${userId}?searchStartDate=${startDate}&searchEndDate=${endDate}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 푸쉬 알람 정보를 삭제한다.
	 * @param agencyId 기관 아이디
	 * @param userId 사용자 아이디
	 * @param id 푸쉬 알람 아이디
	 */
	removePushMessage(agencyId: string, userId: string, id: string): Observable<ResponseData> {
		return this.httpClient.delete<ResponseData>(`${this.API_URL}/${agencyId}/PushMessages/${userId}/${id}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}
}
