import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {ResponseList} from '../../../_model/response-list.model';
import {catchError, map} from 'rxjs/operators';
import {ResponseData} from '../../../_model/response-data.model';
import {ResponsePatient} from '../../../_model/response/patient/response-patient.model';
import {ResponsePatientInspect} from '../../../_model/response/patient/response-patient-inspect.model';
import {RequestPatient} from '../../../_model/request/patient/request-patient.model';
import {RequestPatientInspect} from '../../../_model/request/patient/request-patient-inspect.model';
import {RequestPatientCategoryAndNo} from '../../../_model/request/patient/request-patient-category-and-no.model';
import {RequestPatientHealthReportWithFile} from '../../../_model/request/patient/request-patient-health-report-with-file.model';
import {ResponsePatientHealthReport} from '../../../_model/response/patient/response-patient-health-report.model';

/**
 * 기관 고객 데이터 프로바이더
 */
@Injectable()
export class PatientProvider {
	/**
	 * API URL
	 */
	private API_URL = `${environment.apiUrl}/Patients`;

	/**
	 * 생성자
	 */
	constructor(
		private httpClient: HttpClient
	) {
	}

	/**
	 * 전체 고객 수를 가져온다.
	 */
	getAllCount(): Observable<ResponseData<number>> {
		return this.httpClient.get<ResponseData<number>>(`${this.API_URL}/AllCount`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 목록을 가져온다.
	 * @param id 기관 아이디
	 * @param param 검색 파라미터
	 */
	getList(id: string, param: any = null): Observable<ResponseList<ResponsePatient>> {
		return this.httpClient.get<ResponseList<ResponsePatient>>(`${this.API_URL}/${id}`, { params: param })
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 정보를 가져온다.
	 * @param id 기관 아이디
	 * @param userId 고객 아이디
	 */
	get(id: string, userId: string): Observable<ResponseData<ResponsePatient>> {
		return this.httpClient.get<ResponseData<ResponsePatient>>(`${this.API_URL}/${id}/${userId}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 기관 고객 정보를 수정한다.
	 * @param id 기관 아이디
	 * @param oldId 이전 데이터 사용자 아이디
	 * @param request 고객 정보
	 */
	updateCategoryAndNo(id: string, oldId: string, request: RequestPatientCategoryAndNo): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URL}/${id}/${oldId}/CategoryAndNo`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 기관 고객 정보를 수정한다.
	 * @param id 기관 아이디
	 * @param userId 고객 아이디
	 * @param request 고객 정보
	 */
	update(id: string, userId: string, request: RequestPatient): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URL}/${id}/${userId}`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 기관 고객의 검사 기록을 가져온다.
	 * @param id 고객 아이디
	 * @param param 검색 파라미터
	 */
	getInspects(id: string, param: any = null): Observable<ResponseList<ResponsePatientInspect>> {
		return this.httpClient.get<ResponseList<ResponsePatientInspect>>(`${this.API_URL}/${id}/Inspects`, { params: param })
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 검사 정보를 추가한다.
	 * @param id 고객 아이디
	 * @param request 검사 정보
	 */
	addInspect(id: string, request: RequestPatientInspect): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.API_URL}/${id}/Inspects`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 건강 리포트를 등록한다.
	 * @param id 고객 아이디
	 * @param request 건강 리포트 등록 요청 객체
	 */
	addReport(id: string, request: RequestPatientHealthReportWithFile): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.API_URL}/${id}/Reports`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 건강 리포트를 등록한다.
	 * @param id 고객 아이디
	 * @param dataId 데이터 아이디
	 */
	removeReport(id: string, dataId: string): Observable<ResponseData> {
		return this.httpClient.delete<ResponseData>(`${this.API_URL}/${id}/Reports/${dataId}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 건강 리포트를 가져온다.
	 * @param id 고객 아이디
	 * @param dataId 데이터 아이디
	 */
	getReport(id: string, dataId: string): Observable<ResponseData<ResponsePatientHealthReport>> {
		return this.httpClient.get<ResponseData<ResponsePatientHealthReport>>(`${this.API_URL}/${id}/Reports/${dataId}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 전체 사용자의 건강 리포트를 가져온다.
	 * @param id 고객 아이디
	 * @param param 검색 파라미터
	 */
	getReports(id: string, param: any = null): Observable<ResponseList<ResponsePatientHealthReport>> {
		return this.httpClient.get<ResponseList<ResponsePatientHealthReport>>(`${this.API_URL}/${id}/Reports`, { params: param })
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 탈퇴 한 특정 기관 고객 정보를 복구한다.
	 * @param id 고객 아이디
	 */
	UpdateActive(id: string): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URL}/${id}/active`, null)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}
}
