


export enum EnumDiabeteDiseasePeriod {
	Period1,
	Period2,
	Period3,
	Period4,
}

export namespace EnumDiabeteDiseasePeriod {

	export function toDisplayName(value: EnumDiabeteDiseasePeriod) {
		switch (value) {
			case EnumDiabeteDiseasePeriod.Period1:
				return 'UL_USER_DATA_HEALTH_DIABETE_DISEASE_PERIOD_PERIOD_1';
			case EnumDiabeteDiseasePeriod.Period2:
				return 'UL_USER_DATA_HEALTH_DIABETE_DISEASE_PERIOD_PERIOD_2';
			case EnumDiabeteDiseasePeriod.Period3:
				return 'UL_USER_DATA_HEALTH_DIABETE_DISEASE_PERIOD_PERIOD_3';
			case EnumDiabeteDiseasePeriod.Period4:
				return 'UL_USER_DATA_HEALTH_DIABETE_DISEASE_PERIOD_PERIOD_4';
			default:
				return value;
		}
	}
	export function toDisplayDescription(value: EnumDiabeteDiseasePeriod) {
		switch (value) {
			case EnumDiabeteDiseasePeriod.Period1:
				return 'UL_USER_DATA_HEALTH_DIABETE_DISEASE_PERIOD_PERIOD_1';
			case EnumDiabeteDiseasePeriod.Period2:
				return 'UL_USER_DATA_HEALTH_DIABETE_DISEASE_PERIOD_PERIOD_2';
			case EnumDiabeteDiseasePeriod.Period3:
				return 'UL_USER_DATA_HEALTH_DIABETE_DISEASE_PERIOD_PERIOD_3';
			case EnumDiabeteDiseasePeriod.Period4:
				return 'UL_USER_DATA_HEALTH_DIABETE_DISEASE_PERIOD_PERIOD_4';
			default:
				return value;
		}
	}
	export function toDisplayGroupName(value: EnumDiabeteDiseasePeriod) {
		return 'UL_USER_DATA_HEALTH_DIABETE_DISEASE_PERIOD';
	}
	export function toDisplayShortName(value: EnumDiabeteDiseasePeriod) {
		switch (value) {
			case EnumDiabeteDiseasePeriod.Period1:
				return 'UL_USER_DATA_HEALTH_DIABETE_DISEASE_PERIOD_PERIOD_1';
			case EnumDiabeteDiseasePeriod.Period2:
				return 'UL_USER_DATA_HEALTH_DIABETE_DISEASE_PERIOD_PERIOD_2';
			case EnumDiabeteDiseasePeriod.Period3:
				return 'UL_USER_DATA_HEALTH_DIABETE_DISEASE_PERIOD_PERIOD_3';
			case EnumDiabeteDiseasePeriod.Period4:
				return 'UL_USER_DATA_HEALTH_DIABETE_DISEASE_PERIOD_PERIOD_4';
			default:
				return value;
		}
	}
	export function toDisplayPrompt(value: EnumDiabeteDiseasePeriod) {
		switch (value) {
			case EnumDiabeteDiseasePeriod.Period1:
				return 'UL_USER_DATA_HEALTH_DIABETE_DISEASE_PERIOD_PERIOD_1';
			case EnumDiabeteDiseasePeriod.Period2:
				return 'UL_USER_DATA_HEALTH_DIABETE_DISEASE_PERIOD_PERIOD_2';
			case EnumDiabeteDiseasePeriod.Period3:
				return 'UL_USER_DATA_HEALTH_DIABETE_DISEASE_PERIOD_PERIOD_3';
			case EnumDiabeteDiseasePeriod.Period4:
				return 'UL_USER_DATA_HEALTH_DIABETE_DISEASE_PERIOD_PERIOD_4';
			default:
				return value;
		}
	}
}
