import { NgControl } from '@angular/forms';
import {Directive, Input} from '@angular/core';

@Directive({
	selector: '[appDisableInput]'
})
export class DisableInputDirective {

	@Input() set appDisableInput( condition: boolean ) {
		const action = condition ? 'disable' : 'enable';
		this.ngControl.control[action]();
	}

	constructor( private ngControl: NgControl ) {
	}

}
