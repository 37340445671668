import {EnumBeforeAndAfter} from '../../enums/enum-before-and-after.model';
import {EnumTimeOfDay} from '../../enums/enum-time-of-day.model';
import {RequestUserCommonData} from './request-user-common-data.model';
import {EnumDataInputMethod} from '../../enums/enum-data-input-method.model';

/**
 * 사용자 혈당계 데이터 요청 클래스
 */
export class RequestGlucosemeterData extends RequestUserCommonData {
	/**
	 * 시간대
	 */
	public TimeOfDay: EnumTimeOfDay;
	/**
	 * 시간대 전후
	 */
	public BeforeAndAfter: EnumBeforeAndAfter;
	/**
	 * 혈당값
	 */
	public Value: number;
	/**
	 * 데이터 입력 방법
	 */
	public InputMethod: EnumDataInputMethod = EnumDataInputMethod.Device;
}


