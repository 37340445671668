import {CommonResponseList} from './common-response-list.model';

export class CommonResponseListWithAuth<T> extends CommonResponseList<T> {
	constructor(IsNeedLogin?: boolean, AccessDenied?: boolean) {
		super();
		this.IsNeedLogin = IsNeedLogin;
		this.AccessDenied = AccessDenied;
	}

	public IsNeedLogin: boolean;
	public AccessDenied: boolean;
}
