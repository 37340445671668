import {ResponseCommonData} from './response-common-data.model';

/**
 * 사용자 활동계 수면 요약 데이터 응답 클래스
 */
export class ResponsePedometerSleepSummary extends ResponseCommonData {
	/**
	 * 얕은 수면 시간
	 */
	public Light: number;

	/**
	 * 깊은 수면 시간
	 */
	public Deep: number;
}


