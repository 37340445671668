import {RequestFoodLensDetailData} from './request-food-lens-detail-data.model';
import {EnumMealType} from '../../enums/enum-meal-type.model';
import {RequestUserCommonData} from './request-user-common-data.model';
import {EnumDataInputMethod} from '../../enums/enum-data-input-method.model';

/**
 * 사용자 식단 데이터 요청 클래스
 */
export class RequestFoodLensData extends RequestUserCommonData {
	/**
	 * 식사 타입
	 */
	public MealType: EnumMealType;
	/**
	 * 사진URL
	 */
	public PhotoUrl: string;
	/**
	 * 음식 상세 목록
	 */
	public Foods: RequestFoodLensDetailData[];
	/**
	 * 데이터 입력 방법
	 */
	public InputMethod: EnumDataInputMethod = EnumDataInputMethod.Manual;
}


