import {RequestUserCommonData} from './request-user-common-data.model';
import {EnumDataInputMethod} from '../../enums/enum-data-input-method.model';

/**
 * 사용자 활동계 활동 데이터 요청 클래스
 */
export class RequestPedometerActivityData extends RequestUserCommonData {
	/**
	 * 칼로리
	 */
	public Calories: number;

	/**
	 * 거리
	 */
	public Distance: number;

	/**
	 * 운동시간
	 */
	public Duration: number;

	/**
	 * 걸음수
	 */
	public Step: number;
	/**
	 * 데이터 입력 방법
	 */
	public InputMethod: EnumDataInputMethod = EnumDataInputMethod.Device;
}


