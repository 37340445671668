
/**
 * 마이그레이션 진행 정보 클래스
 */
export class ResponseMigrationProgress {
	// 등록일시
	public get RegDate(): Date {
		return this._regDate;
	}
	private _regDate: Date = new Date();
	// 메시지
	public Message: string;
	// 진행율
	public get Percentage(): string {
		// 전체 개수가 0보다 작거나 같은 경우
		if(this.TotalCount <= 0)
			return '';
		// 진행율 계산이 가능한 경우
		else
			return (this._processedCount / this._totalCount * 100).toFixed(1) + '%';
	}
	// 처리된 개수
	public get ProcessedCount(): number {
		return this._processedCount;
	}
	public set ProcessedCount(value: number) {
		this._processedCount = value;
	}
	private _processedCount: number = 0;
	// 전체 개수
	public get TotalCount(): number {
		return this._totalCount;
	}
	public set TotalCount(value: number) {
		this._totalCount = value;
	}
	private _totalCount: number = 0;

	/**
	 * 생성자
	 * @param message 메시지
	 * @param processedCount 처리된 개수
	 * @param totalCount 전체 개수
	 */
	constructor(message: string, processedCount?: number, totalCount?: number) {
		this.Message = message;
		if(processedCount)
			this.ProcessedCount = processedCount;
		if(totalCount) {
			this.TotalCount = totalCount;

			// console.log(this.Message, this.ProcessedCount, '/', this.TotalCount);
		}
		// else
		// 	console.log(this.Message);
	}
}

