import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PagesToggleService} from '../../shared/services/toggler.service';

@Component({
	selector: 'app-secondary-sidebar',
	templateUrl: './secondary-sidebar.component.html',
	styleUrls: ['./secondary-sidebar.component.scss']
})
export class SecondarySidebarComponent implements OnInit, OnDestroy {
	_toggleMobileSidebar = false;
	_togglePosition: any;
	_service: any;

	constructor(private toggler: PagesToggleService) {
	}

	_extraClass: any;

	@Input()
	set extraClass(value: string) {
		this._extraClass = value;
	}

	ngOnInit() {
		this._service = this.toggler.secondarySideBarToggle
			.subscribe((state: any) => {
				if (typeof (state) === 'boolean') {
					this._toggleMobileSidebar = state;
				} else {
					this._toggleMobileSidebar = state.open;
					const rect = state.$event.target.getBoundingClientRect();
					this._togglePosition = {
						position: 'fixed',
						top: (rect.top + rect.height) + 'px',
						left: rect.left + 'px',
						transform: 'translateX(-50%)'
					};
				}

			});
	}

	ngOnDestroy() {
		this._service.unsubscribe();
	}

}
