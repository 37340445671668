import {EnumDiabeteType} from '../../enums/enum-diabete-type.model';
import {EnumDiabeteDiseasePeriod} from '../../enums/enum-diabete-disease-period.model';
import {EnumDiabeteRemedy} from '../../enums/enum-diabete-remedy.model';
import {EnumSmokeType} from '../../enums/enum-smoke-type.model';

export class RequestPatientHealth {
	public DiabeteType: EnumDiabeteType;
	public DiabeteDiseasePeriod: EnumDiabeteDiseasePeriod;
	public DiabeteRemedy: EnumDiabeteRemedy;
	public DiabeteFamilyHistory: boolean;
	public HasDrunk: boolean;
	public DrinkCycle: number;
	public DrinkAmount: number;
	public SmokeType: EnumSmokeType;
	public HasDiseaseHighBloodPressure: boolean;
	public HasDiseaseRenalFailure: boolean;
	public HasDiseaseHyperlipidemia: boolean;
	public HasDiseaseGout: boolean;
	public HasDiseaseEtc: string;
	public ComplicationEye: boolean;
	public ComplicationCardiovascular: boolean;
	public ComplicationNeural: boolean;
	public ComplicationKidney: boolean;
	public Memo: number;
}


