import {CommonResponseData} from './common-response-data.model';

export class CommonResponseDataWithAuth<T = any> extends CommonResponseData<T> {
	constructor(IsNeedLogin?: boolean, AccessDenied?: boolean) {
		super();
		this.IsNeedLogin = IsNeedLogin;
		this.AccessDenied = AccessDenied;
	}

	public IsNeedLogin: boolean;
	public AccessDenied: boolean;
}
