import {RequestUserCommonData} from './request-user-common-data.model';
import {EnumDataInputMethod} from '../../enums/enum-data-input-method.model';

/**
 * 사용자 혈압 데이터 요청 클래스
 */
export class RequestBloodPressureData extends RequestUserCommonData {
	/**
	 * 이완기값
	 */
	public Diastolic: number;
	/**
	 * 수축기값
	 */
	public Systolic: number;
	/**
	 * 평균값
	 */
	public Mean: number;
	/**
	 * 비율
	 */
	public Rate: number;
	/**
	 * 데이터 입력 방법
	 */
	public InputMethod: EnumDataInputMethod = EnumDataInputMethod.Device;
}


