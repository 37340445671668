


export enum EnumUserGender {
	Female = 0,
	Male = 1
}

export namespace EnumUserGender {

	export function toDisplayName(value: EnumUserGender) {
		switch (value) {
			case EnumUserGender.Female:
				return 'UL_COMMON__GENDER_FEMALE';
			case EnumUserGender.Male:
				return 'UL_COMMON__GENDER_MALE';
			default:
				return value;
		}
	}
	export function toDisplayDescription(value: EnumUserGender) {
		switch (value) {
			case EnumUserGender.Female:
				return 'UL_COMMON__GENDER_FEMALE';
			case EnumUserGender.Male:
				return 'UL_COMMON__GENDER_MALE';
			default:
				return value;
		}
	}
	export function toDisplayGroupName(value: EnumUserGender) {
		return 'UL_COMMON__GENDER';
	}
	export function toDisplayShortName(value: EnumUserGender) {
		switch (value) {
			case EnumUserGender.Female:
				return 'UL_COMMON__GENDER_FEMALE';
			case EnumUserGender.Male:
				return 'UL_COMMON__GENDER_MALE';
			default:
				return value;
		}
	}
	export function toDisplayPrompt(value: EnumUserGender) {
		switch (value) {
			case EnumUserGender.Female:
				return 'UL_COMMON__GENDER_FEMALE';
			case EnumUserGender.Male:
				return 'UL_COMMON__GENDER_MALE';
			default:
				return value;
		}
	}
}
