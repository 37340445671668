import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import 'rxjs/add/operator/map';

import { Observable, throwError} from 'rxjs';

import { environment } from '../../../../environments/environment';
import { ResponseData } from '../../../_model/response-data.model';
import { RequestRegister } from '../../../_model/request/account/request-register.model';
import { RequestConfirmEmail } from '../../../_model/request/account/request-confirm-email.model';
import { RequestForgetPassword } from '../../../_model/request/account/request-forget-password.model';
import { RequestResetPassword } from '../../../_model/request/account/request-reset-password.model';
import { RequestChangePassword } from '../../../_model/request/account/request-change-password.model';
import {RequestPhoneVerifyCode} from '../../../_model/request/account/request-phone-verify-code.model';
import {RequestUpdateProfile} from '../../../_model/request/account/request-update-profile.model';
import {RequestUpdate} from '../../../_model/request/account/request-update.model';

@Injectable()

export class AccountsService {
	// 회원 정보 URL
	private ACCOUNT_URL = `${environment.apiUrl}/Account`;

	// 생성자
	constructor(
		private httpClient: HttpClient
	) {
	}

	/**
	 * 인증번호를 요청한다.
	 * @param phoneNumber 핸드폰 번호
	 */
	requestVerifyCode(phoneNumber: string): Observable<ResponseData> {

		return this.httpClient.post<ResponseData>(`${this.ACCOUNT_URL}/VerifyCode`, new RequestPhoneVerifyCode(phoneNumber))
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 인증번호를 확인한다.
	 * @param phoneNumber 핸드폰 번호
	 * @param verifyCode 인증 코드
	 */
	checkVerifyCode(phoneNumber: string, verifyCode: string): Observable<ResponseData> {
		return this.httpClient.get<ResponseData>(`${this.ACCOUNT_URL}/VerifyCode/${phoneNumber}/${verifyCode}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 회원 가입
	 */
	register(request: RequestRegister): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.ACCOUNT_URL}/Register`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 이메일 인증
	 */
	confirmEmail(request: RequestConfirmEmail): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.ACCOUNT_URL}/ConfirmEmail`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 비밀번호 찾기
	 */
	forgotPassword(request: RequestForgetPassword): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.ACCOUNT_URL}/ForgotPassword`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 비밀번호 재설정
	 */
	resetPassword(request: RequestResetPassword): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.ACCOUNT_URL}/ResetPassword`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 비밀번호 변경
	 */
	changePassword(request: RequestChangePassword): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.ACCOUNT_URL}/ChangePassword`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 계정정보(핸드폰번호, 이메일, 이름 등) 수정
	 */
	updateAccount(request: RequestUpdate): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.ACCOUNT_URL}`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 부가정보 수정
	 */
	updateProfile(request: RequestUpdateProfile): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.ACCOUNT_URL}/Profile`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}
}
