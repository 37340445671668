import {ResponseCommonData} from './response-common-data.model';
import {EnumDataInputMethod} from '../../enums/enum-data-input-method.model';

/**
 * 사용자 활동계 심박 데이터 응답 클래스
 */
export class ResponsePedometerHeartRateData extends ResponseCommonData {
	/**
	 * 심박수
	 */
	public Rate: number;
	/**
	 * 데이터 입력 방법
	 */
	public InputMethod: EnumDataInputMethod;
}


