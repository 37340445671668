import {ResponseCommonData} from './response-common-data.model';
import {EnumDataInputMethod} from '../../enums/enum-data-input-method.model';

/**
 * 사용자 혈압 데이터 응답 클래스
 */
export class ResponseBloodPressureData extends ResponseCommonData {
	/**
	 * 이완기값
	 */
	public Diastolic: number;
	/**
	 * 수축기값
	 */
	public Systolic: number;
	/**
	 * 평균값
	 */
	public Mean: number;
	/**
	 * 심박수
	 */
	public Rate: number;
	/**
	 * 데이터 입력 방법
	 */
	public InputMethod: EnumDataInputMethod;
}


