

export class RequestAgencyPushMessage {
	public Title: string;
	public Message: string;
	public PhotoUrl: string;
	public RegDate: Date;
}


