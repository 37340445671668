import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {ResponseList} from '../../../_model/response-list.model';
import {catchError, map} from 'rxjs/operators';
import {ResponseSystemLog} from '../../../_model/response/log/response-system-log.model';

/**
 * 시스템 로그 데이터 프로바이더
 */
@Injectable()
export class SystemLogProvider {
	/**
	 * API URL
	 */
	private API_URL = `${environment.apiUrl}/SystemLogs`;

	/**
	 * 생성자
	 */
	constructor(
		private httpClient: HttpClient
	) {
	}

	/**
	 * 로그 목록을 가져온다.
	 */
	getList(param: any = null): Observable<ResponseList<ResponseSystemLog>> {
		return this.httpClient.get<ResponseList<ResponseSystemLog>>(`${this.API_URL}`, { params: param })
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

}
