


export enum EnumPatientCategory {
	A,
	B,
	C,
}

export namespace EnumPatientCategory {

	export function toDisplayName(value: EnumPatientCategory) {
		switch (value) {
			case EnumPatientCategory.A:
				return 'UL_USER_DATA_PATIENT_CATEGORY_A';
			case EnumPatientCategory.B:
				return 'UL_USER_DATA_PATIENT_CATEGORY_B';
			case EnumPatientCategory.C:
				return 'UL_USER_DATA_PATIENT_CATEGORY_C';
			default:
				return value;
		}
	}
	export function toDisplayDescription(value: EnumPatientCategory) {
		switch (value) {
			case EnumPatientCategory.A:
				return 'UL_USER_DATA_PATIENT_CATEGORY_A';
			case EnumPatientCategory.B:
				return 'UL_USER_DATA_PATIENT_CATEGORY_B';
			case EnumPatientCategory.C:
				return 'UL_USER_DATA_PATIENT_CATEGORY_C';
			default:
				return value;
		}
	}
	export function toDisplayGroupName(value: EnumPatientCategory) {
		return 'UL_USER_DATA_PATIENT_CATEGORY';
	}
	export function toDisplayShortName(value: EnumPatientCategory) {
		switch (value) {
			case EnumPatientCategory.A:
				return 'UL_USER_DATA_PATIENT_CATEGORY_A';
			case EnumPatientCategory.B:
				return 'UL_USER_DATA_PATIENT_CATEGORY_B';
			case EnumPatientCategory.C:
				return 'UL_USER_DATA_PATIENT_CATEGORY_C';
			default:
				return value;
		}
	}
	export function toDisplayPrompt(value: EnumPatientCategory) {
		switch (value) {
			case EnumPatientCategory.A:
				return 'UL_USER_DATA_PATIENT_CATEGORY_A';
			case EnumPatientCategory.B:
				return 'UL_USER_DATA_PATIENT_CATEGORY_B';
			case EnumPatientCategory.C:
				return 'UL_USER_DATA_PATIENT_CATEGORY_C';
			default:
				return value;
		}
	}
}
