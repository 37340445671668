import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AuthService} from '../../shared/services/auth.service';
import {fromEvent, Observable, Subscription} from 'rxjs';
import {ResponseLogin} from '../../_model/response/account/response-login.model';
import {EnumResponseResult} from '../../_model/enums/enum-response-result.model';
import {NavigationEnd, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MessageNotificationService} from '../../shared/services/notification.service';
import {BreadcrumbService} from '../../shared/services/breadcrumb.service';
import {BreadcrumbPath} from '../../shared/classes/breadcrumb-path';
import {RequestChangePassword} from '../../_model/request/account/request-change-password.model';
import {AccountsService} from '../../shared/services/data-providers/accounts.service';
import {finalize} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {PagesToggleService} from '../../shared/services/toggler.service';
import {AgencyProvider} from '../../shared/services/data-providers/agency-provider.service';
import {ResponseAgency} from '../../_model/response/agency/response-agency.model';
import {List} from 'linq-collections';
import {SessionStorage} from 'ngx-store';
import {equalToValidator} from '../../shared/modules/validators-equal-to';

declare var pg: any;

@Component({
	selector: 'app-default-layout',
	templateUrl: './default-layout.component.html',
	styleUrls: ['./default-layout.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {

	// 통신중 플래그
	public inCommunication: boolean = false;

	// Breadcrumb 노출 경로
	paths: BreadcrumbPath[] = [];

	// 로그인 여부 구독 객체
	private isLoginSubscription: Subscription = null;

	// 로그인 사용자 정보
	loginInfo: ResponseLogin = new ResponseLogin();

	// 비밀번호 변경 다이얼로그 오픈 여부
	passwordChangeDialogOpened: boolean = false;

	// 비밀번호 변경 폼
	formChangePassword: FormGroup;

	// 클릭 구독 객체
	clickObservable: Observable<Event> = fromEvent(document,'click');

	// 담당 기관 목록
	agencies: ResponseAgency[] = [];
	// 선택 담당 기관 아이디
	@SessionStorage({key: 'savedAgencyId'}) savedAgencyId: string = null;
	selectedAgencyId: string = null;

	// 메뉴 항목 목록
	menuItems = [
		{
			label: '고객관리',
			routerLink: '/patients',
			show: true,
			claims: [
				'patients.datas',
				'patients.device-datas'
			],
		},
		{
			label: '접근관리',
			routerLink: '/access',
			show: false,
			claims: [
				'access.agencies',
				'common.logs.useractionlogs'
			],
		},
		{
			label: '통계분석',
			routerLink: '/statistics',
			show: false,
			claims: [ 'statistics' ],
		},
	];

	// 페이지 컨테이너 및 컨텐트 클래스
	_pageContainerClass = '';
	_contentClass = '';
	_subscriptions: Array<Subscription> = [];
	_mobileHorizontalMenu: boolean = false;
	layoutState: string;
	_pageTitle: string;
	_layoutOption: string;
	_boxed: boolean = false;
	_mobileSidebar: boolean = false;
	extraLayoutClass: string;
	_footer: boolean = true;

	/**
	 * 생성자
	 */
	constructor(
		private router: Router,
		private toggler: PagesToggleService,
		private breadcrumbService: BreadcrumbService,
		private translateService: TranslateService,
		private formBuilder: FormBuilder,
		private messageService: MessageNotificationService,
		private authService: AuthService,
		private accountService: AccountsService,
		private agencyProvider: AgencyProvider
	) {
		if (this.layoutState) {
			pg.addClass(document.body, this.layoutState);
		}
		// @ts-ignore
		router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				let root = this.router.routerState.snapshot.root;
				while (root) {
					if (root.children && root.children.length) {
						root = root.children[0];
					} else if (root.data) {
						// Custom Route Data here
						this._pageTitle = root.data.title;
						this._layoutOption = root.data.layoutOption;
						this._boxed = root.data.boxed;
						break;
					} else {
						break;
					}
				}
				// Reset Any Extra Layouts added from content pages
				pg.removeClass(document.body, this.extraLayoutClass);
				// Close Sidebar and Horizonta Menu
				if (this._mobileSidebar) {
					this._mobileSidebar = false;
					pg.removeClass(document.body, 'sidebar-open');
					this.toggler.toggleMobileSideBar(this._mobileSidebar);
				}
				this._mobileHorizontalMenu = false;
				this.toggler.toggleMobileHorizontalMenu(this._mobileHorizontalMenu);
				// Scoll Top
				this.scrollToTop();
			}

			this._subscriptions.push(this.toggler.pageContainerClass
				.subscribe(state => {
					this._pageContainerClass = state;
				}));

			this._subscriptions.push(this.toggler.contentClass
				.subscribe(state => {
					this._contentClass = state;
				}));

			this._subscriptions.push(this.toggler.bodyLayoutClass
				.subscribe(state => {
					if (state) {
						this.extraLayoutClass = state;
						pg.addClass(document.body, this.extraLayoutClass);
					}
				}));

			this._subscriptions.push(this.toggler.Applayout
				.subscribe(state => {
					this.changeLayout(state);
				}));

			this._subscriptions.push(this.toggler.Footer
				.subscribe(state => {
					this._footer = state;
				}));

			this._subscriptions.push(this.toggler.mobileHorizontaMenu
				.subscribe(state => {
					this._mobileHorizontalMenu = state;
				}));

		});

		// 경로 변경 시 발생하는 이벤트
		this.breadcrumbService.pathChanged$.subscribe((paths) => {
			// 경로 저장
			this.paths = paths;
			// 경로가 존재하는 경우, 마지막 경로의 링크를 없앤다.
			if (this.paths.length > 0) {
				this.paths[this.paths.length - 1].link = '';
			}
		});

		// 로그인 검사
		this.authService.checkLogin()
			.subscribe(() => {
			});
	}

	/**
	 * 초기화 이벤트
	 */
	async ngOnInit() {

		pg.isHorizontalLayout = true;
		this.changeLayout('horizontal-menu');
		this.changeLayout('horizontal-app-menu');

		// 비밀번호 변경 폼 그룹 생성
		this.formChangePassword = this.formBuilder.group({
			Password: ['', [Validators.required]],
			NewPassword: ['', [Validators.required, Validators.minLength(6)]],
			NewConfirmPassword: ['', [Validators.required, Validators.minLength(6)]]
		},
			{validator: equalToValidator('NewPassword', 'NewConfirmPassword')}  // 신규 비밀번호와 신규 비밀번호 확인이 동일해야 하도록 설정
		);

		// 로그인 여부 구독 설정
		this.isLoginSubscription = this.authService.isLoginSource$
			.subscribe((item) => {
				if (item) {

					if (item.Result === EnumResponseResult.Success) {

						// 로그인 사용자 정보를 가져온다.
						this.authService.getLogin().subscribe(async (response) => {

							if(response.Result === EnumResponseResult.Success) {
								this.loginInfo = response.Data;

								// 최고 관리자인 경우
								if((new List<string>(this.loginInfo.Roles).where(i => i === 'Supervisor').any())) {
									// 미할당이 등록되지 않은 경우
									if(!new List<ResponseAgency>(this.agencies).where(i => i.Id === this.loginInfo.Id).any()) {
										const notAssignAgency: ResponseAgency = new ResponseAgency();
										notAssignAgency.Id = this.loginInfo.Id;
										notAssignAgency.Name = '미할당';
										this.agencies.splice(0, 0, notAssignAgency);

										// 2024.06.10
										// 의료기관 전체 추가
										const allAssignAgency: ResponseAgency = new ResponseAgency();
										allAssignAgency.Id = 'ALL';
										allAssignAgency.Name = '전체';
										this.agencies.splice(0, 0, allAssignAgency);
									}
								}

								// 비밀번호가 존재하는 경우, 현재 비밀번호를 입력해야 하도록 설정
								if(this.loginInfo.HasPassword)
									this.formChangePassword.controls.Password.setValidators([Validators.required]);
								// 비밀번호가 존재하지 않는 경우, 현재 비밀번호는 입력하지 않아도 되도록 설정
								else
									this.formChangePassword.controls.Password.setValidators([]);

								// 메뉴 권한을 검사한다.
								this.checkMenuClaims(this.menuItems);
							}
							else {
								this.router.navigate(['./account/login']);
							}
						});
					} else if (item.Result === EnumResponseResult.Error) {
						this.router.navigate(['./account/login']);
					}
				}
			});

		// // 클릭 이벤트 구독 추가
		// this.clickObservable.subscribe(() => {
		// 	// 마자막 활동 일시 업데이트
		// 	this.authService.updateLastActivityTime()
		// 		.subscribe(() => {
		//
		// 		});
		// });

		// 내 담당 기관 목록을 가져온다.
		this.agencyProvider.getMyList()
			.subscribe((response) => {

				// 결과가 성공인 경우
				if (response.Result === EnumResponseResult.Success) {

					for(const agency of response.Data.Items) {
						this.agencies.push(agency);
					}

					if(this.agencies.length > 0) {
						let selectedAgency: ResponseAgency;

						// 저장된 기관 선택 정보가 존재하는 경우
						if(this.savedAgencyId) {
							// 해당 기관을 선택 기관으로 설정
							selectedAgency = new List<ResponseAgency>(this.agencies).where(i => i.Id === this.savedAgencyId).firstOrDefault();

							// 선택 기관이 유효하지 않은 경우, 첫번째 항목을 선택 기관으로 설정
							if(!selectedAgency)
								selectedAgency = this.agencies[0];
						}
						// 저장된 기관 선택 정보가 존재하지 않는 경우, 첫번째 항목을 선택 기관으로 설정
						else
							selectedAgency = this.agencies[0];

						if(selectedAgency) {
							this.savedAgencyId = this.selectedAgencyId = selectedAgency.Id;
							this.agencyProvider.changeAgency(selectedAgency);
						}
					}
				}
				// 결과가 실패인 경우
				else {
					this.messageService.error('[' + response.Code + '] ' + response.Message);
				}
			});
	}

	// 해제 이벤트
	ngOnDestroy() {

		// 로그인 여부 구독 해제
		if (this.isLoginSubscription !== null)
			this.isLoginSubscription.unsubscribe();

		for (const sub of this._subscriptions) {
			sub.unsubscribe();
		}
	}

	/**
	 * 기관 변경 시 발생하는 이벤트
	 * @param id 기관 아이디
	 */
	onChangeAgency(id: string) {
		// 선택된 기관 아이디가 존재하는 경우, 고객관리 메인으로 이동
		if(id) {

			const agency = new List<ResponseAgency>(this.agencies).where(i => i.Id === id).firstOrDefault();
			if(agency) {
				this.savedAgencyId = agency.Id;

				// 고객관리 URL이고, 목록 및 메인이 아닌 경우, 고객관리로 이동
				if(window.location.pathname.startsWith('/patients') && window.location.pathname !== '/patients' && window.location.pathname !== '/patients/list')
					this.router.navigate(['/patients']);
				// 그 외 기관 변경 알림
				else
					this.agencyProvider.changeAgency(agency);
			}
		}
	}

	changeLayout(type: string) {
		this.layoutState = type;
		if (type) {
			pg.addClass(document.body, type);
		}
	}

	toggleHorizontalMenuMobile() {
		this._mobileHorizontalMenu = (this._mobileHorizontalMenu !== true);
		this.toggler.toggleMobileHorizontalMenu(this._mobileHorizontalMenu);
	}

	scrollToTop() {
		const top = window.pageYOffset;
		if (top === 0) {
			const scroller = document.querySelector('.page-container');
			if (scroller) {
				scroller.scrollTo(0, 0);
			}
		} else {
			window.scrollTo(0, 0);
		}
	}

	/**
	 * 메뉴 권한을 검사하여 노출 여부를 설정한다.
	 * @param menuItems 검사할 메뉴 목록
	 */
	private checkMenuClaims(menuItems: any[]) {
		if(menuItems) {
			menuItems.map(async (value) => {

				if(await this.authService.hasClaimInChacheOneOf(value.claims)) {
					value.show = true;

					if(value.submenu)
						this.checkMenuClaims(value.submenu);
				}
				else
					value.show = false;
			});
		}
	}

	/**
	 * 비밀번호 변경 다이얼로그 표시
	 */
	showPasswordChangeDialog() {
		this.formChangePassword.controls.Password.setValue('');
		this.formChangePassword.controls.NewPassword.setValue('');
		this.formChangePassword.controls.NewConfirmPassword.setValue('');
		this.passwordChangeDialogOpened = true;
	}

	/**
	 * 비밀번호 변경 처리
	 * @param value 비밀번호 변경 요청 객체
	 */
	changePassword(value: RequestChangePassword) {

		// 처리 중 설정
		this.inCommunication = true;

		// 비밀번호 변경
		this.accountService.changePassword(this.formChangePassword.value)
			.pipe(
				finalize(() => {
					this.inCommunication = false;
				})
			)
			.subscribe((response) => {

				// 처리에 성공한 경우
				if (response.Result === EnumResponseResult.Success) {
					if (response.Message)
						this.messageService.success(response.Message);
					else
						this.messageService.success(this.translateService.instant('SM_COMMON_USER_SUCCESS_CHANGE_PASSWORD'));


					this.passwordChangeDialogOpened = false;
				}
				// 처리에 실패한 경우
				else {
					this.messageService.error('[' + response.Code +  '] ' + response.Message);
				}
			});
	}

	logout() {
		this.authService.logout()
			.subscribe((response) => {
				if(response.Result === EnumResponseResult.Success)
					this.router.navigate(['/account/login']);
			});
	}

}
