import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PagesToggleService} from '../../shared/services/toggler.service';

declare let pg: any;

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
	_headerClass = '';
	isHorizontalLayout: false;
	_service: any;
	@Input()
	boxed: boolean = false;

	@Input()
	extraClass: string = '';

	constructor(private toggler: PagesToggleService) {
	}

	ngOnInit() {
		this.isHorizontalLayout = pg.isHorizontalLayout;
		this._service = this.toggler.headerClass
			.subscribe((state: any) => {
				this._headerClass = state;
			});
	}

	ngOnDestroy() {
		this._service.unsubscribe();
	}
}
