import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BlankLayoutComponent, DefaultLayoutComponent} from './layouts';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AuthGuard} from './shared/services/auth.guard';
import {ApplinkComponent} from './applink/applink.component';


const routes: Routes = [
	{
		path: '',
		redirectTo: '/dashboard',
		pathMatch: 'full'
	},
	{
		path: '',
		component: DefaultLayoutComponent,
		data: {
			breadcrumb: 'Home'
		},
		canActivate:[AuthGuard],
		children: [
			{
				path: 'dashboard',
				data: { },
				component: DashboardComponent,
			},
			{
				path: 'auth-account',
				data: { },
				loadChildren: () => import('./auth-account/auth-account.module').then(m => m.AuthAccountModule)
			},
			{
				path: 'patients',
				data: {
					breadcrumb: '고객관리',
					expectedClaims: [
						'patients.datas',
						'patients.device-datas'
					]
				},
				loadChildren: () => import('./patient/patient.module').then(m => m.PatientModule)
			},
			{
				path: 'access',
				data: {
					breadcrumb: '접근관리',
					expectedClaims: [
						'access.agencies',
						'common.logs.useractionlogs'
					]
				},
				loadChildren: () => import('./access/access.module').then(m => m.AccessModule)
			}
		]
	},
	{
		path: 'errors',
		component: BlankLayoutComponent,
		loadChildren: () => import('./errors/error.module').then(m => m.ErrorModule)
	},
	{
		path: 'account',
		component: BlankLayoutComponent,
		loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
	},
	{
		path: 'app',
		component: ApplinkComponent
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
