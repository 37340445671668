import {ResponseCommonData} from './response-common-data.model';
import {EnumDataInputMethod} from '../../enums/enum-data-input-method.model';

/**
 * 사용자 체성분계 데이터 응답 클래스
 */
export class ResponseBodyScaleData extends ResponseCommonData {
	/**
	 * 체질량지수
	 */
	public Bmi: number;
	/**
	 * 기초대사량
	 */
	public Bmr: number;
	/**
	 * 골량
	 */
	public Bone: number;
	/**
	 * 체지방률
	 */
	public Fat: number;
	/**
	 * 골격근
	 */
	public SkeletalMuscle: number;
	/**
	 * 근육량
	 */
	public Muscle: number;
	/**
	 * 내장지방(내장지방)
	 */
	public Visceral: number;
	/**
	 * 체수분
	 */
	public Water: number;
	/**
	 * 몸무게
	 */
	public Weight: number;
	/**
	 * 데이터 입력 방법
	 */
	public InputMethod: EnumDataInputMethod;
}


