import {EnumPatientCategory} from '../../enums/enum-patient-category.model';

export class RequestPatientCategoryAndNo {
	public Category: EnumPatientCategory;
	public No: string;

	constructor(category: EnumPatientCategory, no: string) {
		this.Category = category;
		this.No = no;
	}
}


