/**
 * 시간대 타입
 */
export enum EnumTimeOfDay {
	Breakfast,
	Lunch,
	Dinner,
	Sleep,
}

export namespace EnumTimeOfDay {

	export function toDisplayName(value: EnumTimeOfDay) {
		switch (value) {
			case EnumTimeOfDay.Breakfast:
				return 'UL_USER_DATA_GLUCOSE_METER_TIME_OF_DAY_BREAKFAST';
			case EnumTimeOfDay.Lunch:
				return 'UL_USER_DATA_GLUCOSE_METER_TIME_OF_DAY_LUNCH';
			case EnumTimeOfDay.Dinner:
				return 'UL_USER_DATA_GLUCOSE_METER_TIME_OF_DAY_DINNER';
			case EnumTimeOfDay.Sleep:
				return 'UL_USER_DATA_GLUCOSE_METER_TIME_OF_DAY_SLEEP';
			default:
				return value;
		}
	}
	export function toDisplayDescription(value: EnumTimeOfDay) {
		switch (value) {
			case EnumTimeOfDay.Breakfast:
				return 'UL_USER_DATA_GLUCOSE_METER_TIME_OF_DAY_BREAKFAST';
			case EnumTimeOfDay.Lunch:
				return 'UL_USER_DATA_GLUCOSE_METER_TIME_OF_DAY_LUNCH';
			case EnumTimeOfDay.Dinner:
				return 'UL_USER_DATA_GLUCOSE_METER_TIME_OF_DAY_DINNER';
			case EnumTimeOfDay.Sleep:
				return 'UL_USER_DATA_GLUCOSE_METER_TIME_OF_DAY_SLEEP';
			default:
				return value;
		}
	}
	export function toDisplayGroupName(value: EnumTimeOfDay) {
		return 'UL_USER_DATA_GLUCOSE_METER_TIME_OF_DAY';
	}
	export function toDisplayShortName(value: EnumTimeOfDay) {
		switch (value) {
			case EnumTimeOfDay.Breakfast:
				return 'UL_USER_DATA_GLUCOSE_METER_TIME_OF_DAY_BREAKFAST';
			case EnumTimeOfDay.Lunch:
				return 'UL_USER_DATA_GLUCOSE_METER_TIME_OF_DAY_LUNCH';
			case EnumTimeOfDay.Dinner:
				return 'UL_USER_DATA_GLUCOSE_METER_TIME_OF_DAY_DINNER';
			case EnumTimeOfDay.Sleep:
				return 'UL_USER_DATA_GLUCOSE_METER_TIME_OF_DAY_SLEEP';
			default:
				return value;
		}
	}
	export function toDisplayPrompt(value: EnumTimeOfDay) {
		switch (value) {
			case EnumTimeOfDay.Breakfast:
				return 'UL_USER_DATA_GLUCOSE_METER_TIME_OF_DAY_BREAKFAST';
			case EnumTimeOfDay.Lunch:
				return 'UL_USER_DATA_GLUCOSE_METER_TIME_OF_DAY_LUNCH';
			case EnumTimeOfDay.Dinner:
				return 'UL_USER_DATA_GLUCOSE_METER_TIME_OF_DAY_DINNER';
			case EnumTimeOfDay.Sleep:
				return 'UL_USER_DATA_GLUCOSE_METER_TIME_OF_DAY_SLEEP';
			default:
				return value;
		}
	}
}
