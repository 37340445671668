


export enum EnumSmokeType {
	Never,
	Stop,
	Current,
}

export namespace EnumSmokeType {

	export function toDisplayName(value: EnumSmokeType) {
		switch (value) {
			case EnumSmokeType.Never:
				return 'UL_USER_DATA_SMOKE_TYPE_NEVER_SMOKER';
			case EnumSmokeType.Stop:
				return 'UL_USER_DATA_SMOKE_TYPE_EX_SMOKER';
			case EnumSmokeType.Current:
				return 'UL_USER_DATA_SMOKE_TYPE_CURRENT_SMOKER';
			default:
				return value;
		}
	}
	export function toDisplayDescription(value: EnumSmokeType) {
		switch (value) {
			case EnumSmokeType.Never:
				return 'UL_USER_DATA_SMOKE_TYPE_NEVER_SMOKER';
			case EnumSmokeType.Stop:
				return 'UL_USER_DATA_SMOKE_TYPE_EX_SMOKER';
			case EnumSmokeType.Current:
				return 'UL_USER_DATA_SMOKE_TYPE_CURRENT_SMOKER';
			default:
				return value;
		}
	}
	export function toDisplayGroupName(value: EnumSmokeType) {
		return 'UL_USER_DATA_SMOKE_TYPE';
	}
	export function toDisplayShortName(value: EnumSmokeType) {
		switch (value) {
			case EnumSmokeType.Never:
				return 'UL_USER_DATA_SMOKE_TYPE_NEVER_SMOKER';
			case EnumSmokeType.Stop:
				return 'UL_USER_DATA_SMOKE_TYPE_EX_SMOKER';
			case EnumSmokeType.Current:
				return 'UL_USER_DATA_SMOKE_TYPE_CURRENT_SMOKER';
			default:
				return value;
		}
	}
	export function toDisplayPrompt(value: EnumSmokeType) {
		switch (value) {
			case EnumSmokeType.Never:
				return 'UL_USER_DATA_SMOKE_TYPE_NEVER_SMOKER';
			case EnumSmokeType.Stop:
				return 'UL_USER_DATA_SMOKE_TYPE_EX_SMOKER';
			case EnumSmokeType.Current:
				return 'UL_USER_DATA_SMOKE_TYPE_CURRENT_SMOKER';
			default:
				return value;
		}
	}
}
