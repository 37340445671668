import { Component, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
		selector: 'app-common-authorized',
		templateUrl: 'authorized.component.html',
		styleUrls: ['./authorized.component.scss'],
		encapsulation: ViewEncapsulation.None
})
export class AuthorizedComponent {

	public hasClaims: { [id: string]: boolean; } = {};

	constructor(
		public authService: AuthService,
	) {

	}

	// 권한을 가져온다.
	getClaims(expectedClaims: string[]) {
		this.authService.getHasClaimsInChache(expectedClaims)
			.then(response => {
				this.hasClaims = response;
			});
	}
}
