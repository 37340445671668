import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
	selector: 'app-blank-layout',
	templateUrl: './blank-layout.component.html',
	styleUrls: ['./blank-layout.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class BlankLayoutComponent implements OnInit {

	constructor() {
	}

	ngOnInit() {
	}

}
