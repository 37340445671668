
import { CommonResponseListWithAuth } from 'src/app/_model/commondata/common-response-list-with-auth.model';

export class ResponseList<T> extends CommonResponseListWithAuth<T> {


	constructor() {
		super();
	}
}


