import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PopupModule } from '@progress/kendo-angular-popup';
import { RippleModule } from '@progress/kendo-angular-ripple';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { UploadModule } from '@progress/kendo-angular-upload';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {registerLocaleData} from '@angular/common';
import localeKo from '@angular/common/locales/ko';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppSharedModule} from './shared/app-shared.module';
import {AuthorizedComponent} from './shared/components/authorized/authorized.component';
import {EnumDropDownListModule} from './shared/modules/enum-dropdownlist.module';
import {BlankLayoutComponent, DefaultLayoutComponent} from './layouts';
import {WebStorageModule} from 'ngx-store';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {AuthService} from './shared/services/auth.service';
import {AuthGuard} from './shared/services/auth.guard';
import {LocalCacheService, SessionCacheService} from './shared/services/cache/cache.service';
import {MessageNotificationService} from './shared/services/notification.service';
import {AccountsService} from './shared/services/data-providers/accounts.service';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AgencyProvider} from './shared/services/data-providers/agency-provider.service';
import {MigrationService} from './shared/services/data-providers/migration.service';
import {UserProvider} from './shared/services/data-providers/user-provider.service';
import {UserHealthGoalsProvider} from './shared/services/data-providers/user-health-goals-provider.service';
import {UserHealthProvider} from './shared/services/data-providers/user-health-provider.service';
import {UserDeviceDataProvider} from './shared/services/data-providers/user-device-data-provider.service';
import {UserActionLogProvider} from './shared/services/data-providers/user-action-log-provider.service';
import {SystemLogProvider} from './shared/services/data-providers/system-log-provider.service';
import {BreadcrumbService} from './shared/services/breadcrumb.service';
import {HeaderComponent} from './components/header/header.component';
import {PagesToggleService} from './shared/services/toggler.service';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {HorizontalMenuComponent} from './components/horizontal-menu/horizontal-menu.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {PatientProvider} from './shared/services/data-providers/patient-provider.service';
import * as moment from 'moment';
import 'moment/locale/ko';
import {ApplinkComponent} from './applink/applink.component';
import '@progress/kendo-angular-intl/locales/ko/all';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true
};

registerLocaleData(localeKo, 'ko');

// 번역 json 파일 로드
export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
	declarations: [
		AppComponent,
		ApplinkComponent,
		HeaderComponent,
		HorizontalMenuComponent,
		AuthorizedComponent,
		BlankLayoutComponent,
		DefaultLayoutComponent,
		DashboardComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		AppSharedModule,
		ButtonsModule,
		BrowserAnimationsModule,
		ChartsModule,
		DateInputsModule,
		DropDownsModule,
		ExcelExportModule,
		GridModule,
		InputsModule,
		LabelModule,
		LayoutModule,
		NotificationModule,
		PopupModule,
		RippleModule,
		TooltipModule,
		UploadModule,
		HttpClientModule,
		EnumDropDownListModule,
		WebStorageModule,
		PerfectScrollbarModule,
		BsDropdownModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			}
		}),
	],
	providers: [
		PagesToggleService, {
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},
		LocalCacheService,
		SessionCacheService,
		AuthService,
		AuthGuard,
		BreadcrumbService,
		MessageNotificationService,
		AccountsService,
		UserProvider,
		UserActionLogProvider,
		SystemLogProvider,
		AgencyProvider,
		UserHealthGoalsProvider,
		UserHealthProvider,
		UserDeviceDataProvider,
		MigrationService,
		PatientProvider,
		{ provide: LOCALE_ID, useValue: navigator.language }
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	// 생성자
	constructor(private translateService: TranslateService) {

		// 언어 번역 설정
		this.translateService.addLangs(['en', 'ko']);
		this.translateService.setDefaultLang('ko');

		const browserLang = this.translateService.getBrowserLang();
		this.translateService.use(browserLang.match(/en|ko/) ? browserLang : 'ko');

		moment.locale(this.translateService.getDefaultLang());
	}
}
