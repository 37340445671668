


export enum EnumUserStatus {
	Locked = 0,
	VerifyingPhoneNumber = 1,
	VerifyingEmail = 2,
	Activated = 3
}

export namespace EnumUserStatus {

	export function toDisplayName(value: EnumUserStatus) {
		switch (value) {
			case EnumUserStatus.Locked:
				return 'UL_COMMON_ACCOUNT_USER_STATUS_LOCKED';
			case EnumUserStatus.VerifyingPhoneNumber:
				return 'UL_COMMON_ACCOUNT_USER_STATUS_VERIFYING_PHONE_NUMBER';
			case EnumUserStatus.VerifyingEmail:
				return 'UL_COMMON_ACCOUNT_USER_STATUS_VERIFYING_EMAIL';
			case EnumUserStatus.Activated:
				return 'UL_COMMON_ACCOUNT_USER_STATUS_ACTIVED';
			default:
				return value;
		}
	}
	export function toDisplayDescription(value: EnumUserStatus) {
		switch (value) {
			case EnumUserStatus.Locked:
				return 'UL_COMMON_ACCOUNT_USER_STATUS_LOCKED';
			case EnumUserStatus.VerifyingPhoneNumber:
				return 'UL_COMMON_ACCOUNT_USER_STATUS_VERIFYING_PHONE_NUMBER';
			case EnumUserStatus.VerifyingEmail:
				return 'UL_COMMON_ACCOUNT_USER_STATUS_VERIFYING_EMAIL';
			case EnumUserStatus.Activated:
				return 'UL_COMMON_ACCOUNT_USER_STATUS_ACTIVED';
			default:
				return value;
		}
	}
	export function toDisplayGroupName(value: EnumUserStatus) {
		return 'UL_COMMON_ACCOUNT_USER_STATUS';
	}
	export function toDisplayShortName(value: EnumUserStatus) {
		switch (value) {
			case EnumUserStatus.Locked:
				return 'UL_COMMON_ACCOUNT_USER_STATUS_LOCKED';
			case EnumUserStatus.VerifyingPhoneNumber:
				return 'UL_COMMON_ACCOUNT_USER_STATUS_VERIFYING_PHONE_NUMBER';
			case EnumUserStatus.VerifyingEmail:
				return 'UL_COMMON_ACCOUNT_USER_STATUS_VERIFYING_EMAIL';
			case EnumUserStatus.Activated:
				return 'UL_COMMON_ACCOUNT_USER_STATUS_ACTIVED';
			default:
				return value;
		}
	}
	export function toDisplayPrompt(value: EnumUserStatus) {
		switch (value) {
			case EnumUserStatus.Locked:
				return 'UL_COMMON_ACCOUNT_USER_STATUS_LOCKED';
			case EnumUserStatus.VerifyingPhoneNumber:
				return 'UL_COMMON_ACCOUNT_USER_STATUS_VERIFYING_PHONE_NUMBER';
			case EnumUserStatus.VerifyingEmail:
				return 'UL_COMMON_ACCOUNT_USER_STATUS_VERIFYING_EMAIL';
			case EnumUserStatus.Activated:
				return 'UL_COMMON_ACCOUNT_USER_STATUS_ACTIVED';
			default:
				return value;
		}
	}
}
