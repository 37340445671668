/**
 * 전후 타입
 */
export enum EnumBeforeAndAfter {
	Before,
	After
}

export namespace EnumBeforeAndAfter {

	export function toDisplayName(value: EnumBeforeAndAfter) {
		switch (value) {
			case EnumBeforeAndAfter.Before:
				return 'UL_USER_DATA_GLUCOSE_METER_BEFORE_AND_AFTER_BEFORE';
			case EnumBeforeAndAfter.After:
				return 'UL_USER_DATA_GLUCOSE_METER_BEFORE_AND_AFTER_AFTER';
			default:
				return value;
		}
	}
	export function toDisplayDescription(value: EnumBeforeAndAfter) {
		switch (value) {
			case EnumBeforeAndAfter.Before:
				return 'UL_USER_DATA_GLUCOSE_METER_BEFORE_AND_AFTER_BEFORE';
			case EnumBeforeAndAfter.After:
				return 'UL_USER_DATA_GLUCOSE_METER_BEFORE_AND_AFTER_AFTER';
			default:
				return value;
		}
	}
	export function toDisplayGroupName(value: EnumBeforeAndAfter) {
		return 'UL_USER_DATA_GLUCOSE_METER_BEFORE_AND_AFTER';
	}
	export function toDisplayShortName(value: EnumBeforeAndAfter) {
		switch (value) {
			case EnumBeforeAndAfter.Before:
				return 'UL_USER_DATA_GLUCOSE_METER_BEFORE_AND_AFTER_BEFORE';
			case EnumBeforeAndAfter.After:
				return 'UL_USER_DATA_GLUCOSE_METER_BEFORE_AND_AFTER_AFTER';
			default:
				return value;
		}
	}
	export function toDisplayPrompt(value: EnumBeforeAndAfter) {
		switch (value) {
			case EnumBeforeAndAfter.Before:
				return 'UL_USER_DATA_GLUCOSE_METER_BEFORE_AND_AFTER_BEFORE';
			case EnumBeforeAndAfter.After:
				return 'UL_USER_DATA_GLUCOSE_METER_BEFORE_AND_AFTER_AFTER';
			default:
				return value;
		}
	}
}
