import {ResponseCommonData} from './response-common-data.model';
import {EnumMealType} from '../../enums/enum-meal-type.model';
import {ResponseFoodLensDetailData} from './response-food-lens-detail-data.model';
import {EnumDataInputMethod} from '../../enums/enum-data-input-method.model';

/**
 * 사용자 식단 데이터 응답 클래스
 */
export class ResponseFoodLensData extends ResponseCommonData {
	/**
	 * 식사 타입
	 */
	public MealType: EnumMealType;
	/**
	 * 사진URL
	 */
	public PhotoUrl: string;
	/**
	 * 음식 상세 목록
	 */
	public Foods: ResponseFoodLensDetailData[];
	/**
	 * 음식들의 칼로리 합계
	 */
	public SumCalories: number;
	/**
	 * 데이터 입력 방법
	 */
	public InputMethod: EnumDataInputMethod;
}


