import {ResponseCommonData} from './response-common-data.model';

/**
 * 사용자 활동계 맥박 데이터 응답 클래스
 */
export class ResponsePedometerHeartRateSummary extends ResponseCommonData {
	/**
	 * 최대 맥박수
	 */
	public MaxRate: number = 0;

	/**
	 * 최대 맥박수
	 */
	public MinRate: number = 0;

	/**
	 * 평균 맥박수
	 */
	public AverageRate: number = 0;

	/**
	 * 생성자
	 * @param regDate 데이터일자
	 * @param minRate 최소 맥박수
	 * @param maxRate 최대 맥박수
	 * @param averageRate 평균 맥박수
	 */
	constructor(regDate?: Date, minRate?: number, maxRate?: number, averageRate?: number) {
		super();

		if(regDate)
			this.RegDate = regDate;
		if(minRate)
			this.MaxRate = minRate;
		if(maxRate)
			this.MinRate = maxRate;
		if(averageRate)
			this.AverageRate = averageRate;
	}
}


