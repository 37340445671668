import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { MomentModule } from 'ngx-moment';
import { WINDOW_PROVIDERS } from './services/windows.service';

import './extensions/array.extension';
import './extensions/string.extension';
import './extensions/date.extension';
import './extensions/number.extension';
import './extensions/enum.extension';
import { CoreModule } from './services/core.module';
import { DisableInputDirective } from './directive/disable-input-directive';
import {CommonDialogService} from './services/common-dialog.service';
import {CommonWindowService} from './services/common-window.service';
import {TabContentLoadOnDemandDirective} from './directive/kendo-tab-content-load-on-demand-directive';
import {DisableAnchorDirective} from './directive/disable-anchor-directive';
import {EnumDropDownListModule} from './modules/enum-dropdownlist.module';
import {EnumDropDownList} from './components/enum-dropdownlist/enum-dropdownlist.component';
import {ContainerComponent} from '../components/container/container.component';
import {MenuComponent} from '../components/menu/menu.component';
import {MenuAltComponent} from '../components/menu/menu-alt.component';
import {MenuIconComponent} from '../components/menu/menu-icon.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {SecondarySidebarComponent} from '../components/secondary-sidebar/secondary-sidebar.component';
import {DialogsModule} from '@progress/kendo-angular-dialog';
import {ButtonsModule} from '@progress/kendo-angular-buttons';

@NgModule({
	declarations: [
		DisableInputDirective,
		DisableAnchorDirective,
		TabContentLoadOnDemandDirective,
		ContainerComponent,
		MenuComponent,
		MenuAltComponent,
		MenuIconComponent,
		SecondarySidebarComponent,
	],
	exports: [
		FormsModule,
		CommonModule,
		TranslateModule,
		ReactiveFormsModule,
		DisableInputDirective,
		DisableAnchorDirective,
		TabContentLoadOnDemandDirective,
		ContainerComponent,
		MenuComponent,
		MenuAltComponent,
		MenuIconComponent,
		SecondarySidebarComponent,
		EnumDropDownList,
		DialogsModule,
		ButtonsModule,
	],
	imports: [
		FormsModule,
		CommonModule,
		HttpClientModule,
		RouterModule,
		MomentModule,
		TranslateModule.forChild(),
		ReactiveFormsModule,
		CoreModule,
		EnumDropDownListModule,
		PerfectScrollbarModule,
		DialogsModule,
		ButtonsModule,
	],
	providers: [
		DatePipe,
		WINDOW_PROVIDERS,
		CommonDialogService,
		CommonWindowService
	]
})
export class AppSharedModule {
}
