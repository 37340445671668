import {RequestUserCommonData} from './request-user-common-data.model';

/**
 * 사용자 식단 데이터 요청 클래스
 */
export class RequestFoodLensDetailData extends RequestUserCommonData {
	/**
	 * 음식명
	 */
	public FoodName: string;
	/**
	 * 사진URL
	 */
	public PhotoUrl: string;
	/**
	 * 먹은 양
	 */
	public EatAmount: number;
	/**
	 * 단위
	 */
	public Unit: string;
	/**
	 * 칼슘
	 */
	public Calcium: number;
	/**
	 * 칼로리
	 */
	public Calories: number;
	/**
	 * 탄수화물
	 */
	public Carbohydrate: number;
	/**
	 * 콜레스테롤
	 */
	public Cholesterol: number;
	/**
	 * 식이 섬유
	 */
	public DietaryFiber: number;
	/**
	 * 지방
	 */
	public Fat: number;
	/**
	 * 단백질
	 */
	public Protein: number;
	/**
	 * 포화 지방
	 */
	public SaturatedFat: number;
	/**
	 * 나트륨
	 */
	public Sodium: number;
	/**
	 * 설탕
	 */
	public Sugar: number;
	/**
	 * 총 그램
	 */
	public TotalGram: number;
	/**
	 * 트랜스 지방
	 */
	public TransFat: number;
	/**
	 * 비타민 A
	 */
	public VitaminA: number;
	/**
	 * 비타민 B
	 */
	public VitaminB: number;
	/**
	 * 비타민 C
	 */
	public VitaminC: number;
	/**
	 * 비타민 D
	 */
	public VitaminD: number;
	/**
	 * 비타민 E
	 */
	public VitaminE: number;
}


