import {Injectable} from '@angular/core';
import {GridColumnsConfig} from '../classes/grid/grid-columns-configs.model';
import {Grid} from '../classes/grid/grid.model';

/**
 * 그리드 설정 관리 서비스
 */
@Injectable()
export class GridColumnConfigService {
	private STORAGE_KEY = 'grid-columns-config';

	private getConfigsFromStorage(): GridColumnsConfig[] {
		const configs = localStorage.getItem(this.STORAGE_KEY);
		if (configs) {
			return JSON.parse(configs);
		} else {
			return [];
		}
	}

	private setConfigsToStorage(configs: GridColumnsConfig[]): void {
		const data = JSON.stringify(configs);
		localStorage.setItem(this.STORAGE_KEY, data);
	}


	getConfig(gridPath: string): GridColumnsConfig {
		const configs = this.getConfigsFromStorage();

		let config = configs.find(item => item.gridPath === gridPath);
		if (!config) {
			config = {gridPath, hiddenColumnFields: []};
		}
		return config;
	}

	setConfig(config: GridColumnsConfig): void {
		if (!config) {
			return;
		}

		const configs = this.getConfigsFromStorage();

		const found = configs.find(item => item.gridPath === config.gridPath);
		if (found) {
			found.hiddenColumnFields = config.hiddenColumnFields;
		} else {
			configs.push({...config});
		}

		this.setConfigsToStorage(configs);
	}


	getConfigByGrid(grid: Grid): GridColumnsConfig {
		const gridPath = grid.getGridPath();
		return this.getConfig(gridPath);
	}

	setConfigByGrid(grid: Grid): void {
		const gridPath = grid.getGridPath();
		const hiddenColumnFields = grid.getHiddenColumnFields();
		this.setConfig({gridPath, hiddenColumnFields});
	}
}
