import {EnumResponseResult} from '../enums/enum-response-result.model';

export class CommonResponseData<T = any> {
	constructor(Result?: EnumResponseResult, Code?: string, Message?: string, Data?: T) {
		this.Result = Result;
		this.Code = Code;
		this.Message = Message;
		this.Data = Data;
	}

	public Result: EnumResponseResult;
	public Code: string;
	public Message: string;
	public Data: T;
}
