
import { EnumUserStatus } from 'src/app/_model/enums/enum-user-status.model';
import { CommonRequestData } from 'src/app/_model/commondata/common-request-data.model';

export class RequestUserUpdate extends CommonRequestData {
	public Email: string;
	public Name: string;
	public Roles: string[];
	public Status: EnumUserStatus;
}


