
import { ResponseDataWithCreatedBy } from 'src/app/_model//response-data-with-created-by.model';

export class ResponseDataWithModifiedBy extends ResponseDataWithCreatedBy {
	public ModDate: Date;
	public ModId: string;
	public ModName: string;

	constructor(ModDate?: Date, ModId?: string, ModName?: string) {
		super();
		this.ModDate = ModDate;
		this.ModId = ModId;
		this.ModName = ModName;
	}
}


