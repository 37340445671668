


export enum EnumDiabeteRemedy {
	HabitCorrection,
	OralMedicine,
	Insulin,
}

export namespace EnumDiabeteRemedy {

	export function toDisplayName(value: EnumDiabeteRemedy) {
		switch (value) {
			case EnumDiabeteRemedy.HabitCorrection:
				return 'UL_USER_DATA_HEALTH_DIABETE_REMEDY_HABIT_CORRECTION';
			case EnumDiabeteRemedy.OralMedicine:
				return 'UL_USER_DATA_HEALTH_DIABETE_REMEDY_ORAL_MEDICINE';
			case EnumDiabeteRemedy.Insulin:
				return 'UL_USER_DATA_HEALTH_DIABETE_REMEDY_INSULIN';
			default:
				return value;
		}
	}
	export function toDisplayDescription(value: EnumDiabeteRemedy) {
		switch (value) {
			case EnumDiabeteRemedy.HabitCorrection:
				return 'UL_USER_DATA_HEALTH_DIABETE_REMEDY_HABIT_CORRECTION';
			case EnumDiabeteRemedy.OralMedicine:
				return 'UL_USER_DATA_HEALTH_DIABETE_REMEDY_ORAL_MEDICINE';
			case EnumDiabeteRemedy.Insulin:
				return 'UL_USER_DATA_HEALTH_DIABETE_REMEDY_INSULIN';
			default:
				return value;
		}
	}
	export function toDisplayGroupName(value: EnumDiabeteRemedy) {
		return 'UL_USER_DATA_HEALTH_DIABETE_REMEDY';
	}
	export function toDisplayShortName(value: EnumDiabeteRemedy) {
		switch (value) {
			case EnumDiabeteRemedy.HabitCorrection:
				return 'UL_USER_DATA_HEALTH_DIABETE_REMEDY_HABIT_CORRECTION';
			case EnumDiabeteRemedy.OralMedicine:
				return 'UL_USER_DATA_HEALTH_DIABETE_REMEDY_ORAL_MEDICINE';
			case EnumDiabeteRemedy.Insulin:
				return 'UL_USER_DATA_HEALTH_DIABETE_REMEDY_INSULIN';
			default:
				return value;
		}
	}
	export function toDisplayPrompt(value: EnumDiabeteRemedy) {
		switch (value) {
			case EnumDiabeteRemedy.HabitCorrection:
				return 'UL_USER_DATA_HEALTH_DIABETE_REMEDY_HABIT_CORRECTION';
			case EnumDiabeteRemedy.OralMedicine:
				return 'UL_USER_DATA_HEALTH_DIABETE_REMEDY_ORAL_MEDICINE';
			case EnumDiabeteRemedy.Insulin:
				return 'UL_USER_DATA_HEALTH_DIABETE_REMEDY_INSULIN';
			default:
				return value;
		}
	}
}
