import {Component, Input, OnInit} from '@angular/core';

@Component({
	selector: 'app-menu-icon',
	templateUrl: './menu-icon.component.html',
	styleUrls: ['./menu-icon.component.scss'],
	host: {
		'[class]': '_classMap'
	}
})
export class MenuIconComponent implements OnInit {
	_classMap: string;
	@Input() IconType: string;
	@Input() IconName: string;

	constructor() {
		this._classMap = 'icon-thumbnail ';
	}

	@Input()
	set ExtraClass(value: string) {
		if (value !== undefined) {
			this._classMap = this._classMap + value;
		}
	}

	ngOnInit() {
	}

}
