import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ResponseData} from '../../../_model/response-data.model';
import {RequestUserHealthGoal} from '../../../_model/request/config/request-user-health-goal.model';
import {ResponseUserHealthGoal} from '../../../_model/response/config/response-user-health-goal.model';
import {ResponseList} from '../../../_model/response-list.model';
import {ResponseUserHealthGoalEx} from '../../../_model/response/config/response-user-health-goal-ex.model';

/**
 * 사용자 조절목표 설정 정보 데이터 프로바이더
 */
@Injectable()
export class UserHealthGoalsProvider {
	/**
	 * API URL
	 */
	private API_ACCOUNT_URL = `${environment.apiUrl}/Account`;
	/**
	 * API URL
	 */
	private API_URL = `${environment.apiUrl}/UserHealthGoals`;

	/**
	 * 생성자
	 */
	constructor(
		private httpClient: HttpClient
	) {
	}

	/**
	 * 로그인한 사용자의 조절목표 설정 정보를 수정한다.
	 * @param request 조절목표 설정 정보
	 */
	updateMyGoal(request: RequestUserHealthGoal): Observable<ResponseData<ResponseUserHealthGoal>> {
		return this.httpClient.put<ResponseData<ResponseUserHealthGoal>>(`${this.API_ACCOUNT_URL}/HealthGoal`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 로그인한 사용자의 조절목표 설정 정보를 가져온다.
	 */
	getMyGoal(): Observable<ResponseData<ResponseUserHealthGoal>> {
		return this.httpClient.get<ResponseData<ResponseUserHealthGoal>>(`${this.API_ACCOUNT_URL}/HealthGoal`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 전체 사용자의 조절목표 설정 정보를 가져온다.
	 * @param param 검색 파라미터
	 */
	getList(param: any = null): Observable<ResponseList<ResponseUserHealthGoalEx>> {
		return this.httpClient.get<ResponseList<ResponseUserHealthGoalEx>>(`${this.API_URL}`, { params: param })
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 조절목표 설정 정보를 가져온다.
	 * @param id 사용자 식별자
	 */
	get(id: string): Observable<ResponseData<ResponseUserHealthGoal>> {
		return this.httpClient.get<ResponseData<ResponseUserHealthGoal>>(`${this.API_URL}/${id}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 조절목표 설정 기본값 정보를 저장한다.
	 * @param id 사용자 식별자
	 */
	updateDefault(id: string): Observable<ResponseData<ResponseUserHealthGoal>> {
		return this.httpClient.put<ResponseData<ResponseUserHealthGoal>>(`${this.API_URL}/${id}/Default`, null)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 조절목표 설정 정보를 수정한다.
	 * @param id 사용자 식별자
	 * @param request 조절목표 설정 정보
	 */
	update(id: string, request: RequestUserHealthGoal): Observable<ResponseData<ResponseUserHealthGoal>> {
		return this.httpClient.put<ResponseData<ResponseUserHealthGoal>>(`${this.API_URL}/${id}`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

}
